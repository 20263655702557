import gql from 'graphql-tag'

export const USER_FRAGMENT = gql`
  fragment User on User {
    id,
    name,
    screenName,
    description,
    profileImage {
      width,
      height,
      url
    },
    profileImageId
  }
`

export const FULL_USER_FRAGMENT = gql`
  fragment FullUser on User {
    ...User,
    email,
    emailVerified,
    sex,
    birthdate,
    phoneNumber,
    zipcode,
    address,
    stripeCustomerId,
    businessForm,
    photographer,
    workArea,
    feeRangeStart,
    feeRangeEnd,
    createdAt,
    updatedAt,
    passwordUpdatedAt
  }
`

export const PHOTOGRAPHER_FRAGMENT = gql`
  fragment Photographer on User {
    ...User,
    feeRangeStart,
    feeRangeEnd,
    workArea,
    birthYear,
    workCount,
    photographicCategories {
      name,
      screenName
    },
    equipment {
      name,
      screenName
    }
  }
`

export const PROFILE_FRAGMENT = gql`
  fragment Profile on User {
    ...User,
    feeRangeStart,
    feeRangeEnd,
    workArea,
    birthYear,
    workCount,
    photographer,
    phoneNumber,
    zipcode,
    address,
    email,
    businessForm,
    photographicCategories {
      name
    }
    equipment {
      name
    }
  }
`

export const USER_FRAGMENT_WITH_JOBS = gql`
  fragment UserWithJobs on User {
    ...User,
    jobs {
      ...Job
    }
  }
`

export const JOB_FRAGMENT = gql`
  fragment Job on Job {
    id,
    name,
    fee,
    description,
    deadline,
    address,
    lat,
    lng,
    applicationCount,
    applied,
    assigned,
    own,
    status,
    createdAt,
    updatedAt,
    photographer {
      ...User
    }
    area {
      ...Area
    },
    categories {
      name,
      screenName
    }
  }
`

export const JOB_COMMENT_FLAGMENT = gql`
  fragment JobComment on JobComment {
    id,
    text,
    createdAt,
    updatedAt,
    user {
      ...User
    }
  }
`

export const PHOTOGRAPHER_COMMENT_FRAGMENT = gql`
  fragment PhotographerComment on PhotographerComment {
    id,
    text,
    createdAt,
    updatedAt,
    photographer {
      ...Photographer
    },
    photographerId,
    user {
      ...User
    },
    userId
  }
`

export const JOB_ENTRY_FRAGMENT = gql`
  fragment JobApplication on JobApplication {
    jobId,
    userId,
    createdAt,
    updatedAt
  }
`

export const AREA_FRAGMENT = gql`
  fragment Area on Area {
    code,
    prefecture,
    city
  }
`

export const DELIVERABLE_FRAGMENT = gql`
  fragment Deliverable on Deliverable {
    id,
    originalFileName,
    url,
    size,
    jobId,
    createdAt,
    updatedAt
  }
`

export const DELIVERABLE_PREVIEW_FRAGMENT = gql`
  fragment DeliverablePreview on DeliverablePreview {
    id,
    size,
    width,
    height,
    url,
    createdAt,
    updatedAt
  }
`

export const PAYMENT_METHOD_FRAGMENT = gql`
  fragment PaymentMethod on PaymentMethod {
    id,
    brand,
    expireMonth,
    expireYear,
    last4Digits
  }
`

export const NEWS_POST_FRAGMENT = gql`
  fragment NewsPost on NewsPost {
    id,
    title,
    content,
    createdAt,
    updatedAt
  }
`