import React, { useState } from 'react'
import styled from 'styled-components'
import { GQLCreateInquiryInput, GQLCreateStudioInput, useCreateInquiryMutation, useCreateStudioMutation } from '../../generated/graphql'
import { useErrorModal } from '../../hooks/use-error-modal'
import { InputContainer } from '../molecules/input-container'
import { FormButton } from '../organisms/form-button'
import { FormContainer } from '../organisms/form-container'
import { createForm } from '../organisms/forms'


const Message = styled.div`
  text-align: center;
`

const {
  useForm,
  Form,
  Text,
  Textarea
} = createForm<GQLCreateInquiryInput>()
export const InquiryForm = () => {
  const [values, formProps] = useForm({
    name: '',
    email: '',
    phoneNumber: '',
    text: ''
  })
  const [createInquiryMutation] = useCreateInquiryMutation()
  const {open} = useErrorModal()
  const [sent, setSent] = useState(false)
  return <>
    {
      !sent && <FormContainer>
        <Form {...formProps}>
          <InputContainer label="お名前"><Text name="name" /></InputContainer>
          <InputContainer label="メールアドレス"><Text name="email" /></InputContainer>
          <InputContainer label="電話番号"><Text name="phoneNumber" /></InputContainer>
          <InputContainer label="内容"><Textarea name="text" /></InputContainer>
        </Form>
        <FormButton onClick={async () => {
          try{
            await createInquiryMutation({
              variables: {
                input: values
              }
            })
          } catch(err) {
            await open(err)
            return
          }
          setSent(true)
        }}>送信</FormButton>
      </FormContainer>
    }
    {
      sent &&
      <Message>送信が完了いたしました。</Message>
    }
  </>
}