import React, { Fragment } from 'react'
import { createContext, FunctionComponent, ReactNode, useContext, useState } from 'react'
import {v4 as uuidv4} from 'uuid'
import { isNotNullish } from '../utils/type-check'

type Modal = {
  id: number | string,
  content: ReactNode
}

const ModalContext = createContext<{
  modals: Modal[],
  add: (modal: Modal) => void,
  remove: (id: Modal["id"]) => void
}>({
  modals: [],
  add: () => {},
  remove: () => {}
})

export const ModalProvider: FunctionComponent = ({children}) => {
  const [modals, setModals] = useState<Modal[]>([])
  const add = (modal: Modal) => {
    setModals(modals => [...modals, modal])
  }
  const remove = (id: string | number) => {
    setModals(modals => {
      const index = modals.findIndex(modal => modal.id === id)
      if(isNotNullish(index)){
        return [...modals.slice(0, index), ...modals.slice(index + 1)]
      } else {
        return modals
      }
    })
  }
  return <ModalContext.Provider value={{modals, add, remove}}>
    {children}
    {
      modals.length > 0 &&
      <div>
        {
          modals.map(({id, content}) => <Fragment key={id}>{content}</Fragment>)
        }
      </div>
    }
  </ModalContext.Provider>
}


type ModalRenderArgs<T> = {
  resolve: (value: T) => void
  reject: (reason?: any) => void
}
type ModalRender<T> = (modalRenderArgs: ModalRenderArgs<T>) => ReactNode

export const useModal = () => {
  const {add, remove} = useContext(ModalContext)
  const open = async <T extends any = unknown>(render: ModalRender<T>): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
      const id = uuidv4()
      add({
        id: id,
        content: render({
          resolve: value => {
            remove(id)
            resolve(value)
          },
          reject: reason => {
            remove(id)
            reject(reason)
          }
        })
      })
    })
  }
  return {
    open
  }
}