import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { insertEach } from '../../utils/insert-each';


const BreadcrumbsOuter = styled.div``

const BreadcrumbsList = styled.div`
  display: flex;
  align-items: center;
`

const BreadcrumbsListItem = styled.div`
  padding: 0.5rem;
  font-size: 0.8rem;
`

type BreadcrumbsProps = {
  items: {
    url?: string | null | undefined;
    label: string;
  }[];
};
export const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({ items }) => {
  return <BreadcrumbsOuter>
    <BreadcrumbsList>
      {insertEach(items.map(({ label, url }, i) => {
        if (url) {
          return <Link to={url} key={i}>
            <BreadcrumbsListItem>
              {label}
            </BreadcrumbsListItem>
          </Link>;
        } else {
          return <BreadcrumbsListItem key={i}>
            {label}
          </BreadcrumbsListItem>;
        }
      }), '>')}
    </BreadcrumbsList>
  </BreadcrumbsOuter>;
};

