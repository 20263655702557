import React, { useRef } from 'react'
import { FunctionComponent } from 'react'
import { GQLBusinessForm, GQLPhotographerProfileInput, useAddGalleryImageMutation, useDeleteGalleryImageMutation, useEquipmentListQuery, useGalleryImagesQuery, usePhotographicCategoriesQuery, useUpdatePhotographerProfileMutation } from '../../generated/graphql'
import SvgCameraIcon from '../../generated/svg/CameraIcon'
import { useUserStatus } from '../../hooks/use-user-status'
import { AccountPageTabs } from '../molecules/account-page-tabs'
import { InputContainer, MultipleCheckboxContainer } from '../molecules/input-container'
import { AccountPageOuter, AccountPageTabContainer, AccountPageHead, AccountPageHeadIconContainer, AccountPageHeadLabelContainer, AccountPageHeadLabel, AccountPageHeadLabelEnglish } from './account-page-parts'
import { FormContainer } from './form-container'
import { createForm } from './forms'
import { FormButton } from './form-button'
import { useErrorModal } from '../../hooks/use-error-modal'
import { useMessageModal } from '../../hooks/use-message-modal'
import styled from 'styled-components'

const {
  useForm,
  Form,
  Text,
  Textarea,
  Checkbox,
  MultipleCheckbox,
  NumberText,
  Radio
} = createForm<GQLPhotographerProfileInput>()

type RegisterPhotographerPropsTab = {
}

export const RegisterPhotographerTab: FunctionComponent<RegisterPhotographerPropsTab> = () => {
  const userStatus = useUserStatus()
  const user = userStatus.profile
  const {open: openErrorModal} = useErrorModal()
  const {open: openMessageModal} = useMessageModal()
  const photographicCategoriesQuery = usePhotographicCategoriesQuery()
  const equipmentListQuery = useEquipmentListQuery()
  if(!user) return <>403</>
  const [values, formProps] = useForm({
    screenName: user.screenName,
    workArea: user.workArea,
    feeRangeStart: user.feeRangeStart,
    feeRangeEnd: user.feeRangeEnd,
    photographer: user.photographer,
    description: user.description,
    businessForm: user.businessForm ?? GQLBusinessForm.Individual,
    photographicCategoryNames: user.photographicCategories.map(photographicCategory => photographicCategory.name),
    equipmentNames: user.equipment.map(equipment => equipment.name)
  })
  const [updatePhotographerProfileMutation] = useUpdatePhotographerProfileMutation()
  return <AccountPageOuter>
    <AccountPageTabContainer>
      <AccountPageTabs active="registerPhotographer" />
    </AccountPageTabContainer>
    <AccountPageHead>
      <AccountPageHeadIconContainer>
        <SvgCameraIcon></SvgCameraIcon>
      </AccountPageHeadIconContainer>
      <AccountPageHeadLabelContainer>
        <AccountPageHeadLabel>カメラマンを登録する</AccountPageHeadLabel>
        <AccountPageHeadLabelEnglish>REGISTER CAMERAMAN</AccountPageHeadLabelEnglish>
      </AccountPageHeadLabelContainer>
    </AccountPageHead>
    <FormContainer>
      <Form {...formProps}>
        <InputContainer label="ユーザー名"><Text name="screenName" /></InputContainer>
        <InputContainer label="活動地域"><Text name="workArea" /></InputContainer>
        <InputContainer label="報酬例"><NumberText name="feeRangeStart" />-<NumberText name="feeRangeEnd" /></InputContainer>
        <InputContainer label="プロフィール"><Textarea name="description" /></InputContainer>
        <InputContainer label="形態">
          <label><Radio name="businessForm" value={GQLBusinessForm.Individual} />個人</label>
          <label><Radio name="businessForm" value={GQLBusinessForm.Corporation} />法人</label>
        </InputContainer>
        <InputContainer label="得意ジャンル">
          <MultipleCheckboxContainer>
            {
              photographicCategoriesQuery.data?.photographicCategories.map(photographicCategory => {
                return <label key={photographicCategory.name}>
                  <MultipleCheckbox name="photographicCategoryNames" value={photographicCategory.name} />
                  {photographicCategory.screenName}
                </label>
              })
            }
          </MultipleCheckboxContainer>
        </InputContainer>
        <InputContainer label="使用機材">
          <MultipleCheckboxContainer>
            {
              equipmentListQuery.data?.equipmentList.map(equipment => {
                return <label key={equipment.name}>
                  <MultipleCheckbox name="equipmentNames" value={equipment.name} />
                  {equipment.screenName}
                </label>
              })
            }
          </MultipleCheckboxContainer>
        </InputContainer>
        <InputContainer label=""><label><Checkbox name="photographer" />カメラマンとしてプロフィールを公開する</label></InputContainer>
      </Form>

      <FormButton onClick={async () => {
        try {
          await updatePhotographerProfileMutation({
            variables: {
              photographerProfile: values
            }
          })
        } catch(err) {
          openErrorModal(err)
          return
        }
        openMessageModal('更新完了')
      }}>
        更新する
      </FormButton>
    </FormContainer>
    <AccountPageHead>
      <AccountPageHeadIconContainer>
        <SvgCameraIcon></SvgCameraIcon>
      </AccountPageHeadIconContainer>
      <AccountPageHeadLabelContainer>
        <AccountPageHeadLabel>ギャラリーに画像を追加する</AccountPageHeadLabel>
        <AccountPageHeadLabelEnglish>ADD IMAGES TO GALLERY</AccountPageHeadLabelEnglish>
      </AccountPageHeadLabelContainer>
    </AccountPageHead>
    <AddGalleryForm />
  </AccountPageOuter>
}

const AddGalleryFormOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ImageList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: -0.5em;
`

const ImageItemOuter = styled.div`
padding: 0.5em;
`

const ImageItem = styled.div`
  position: relative;
`

const Img = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
`

const InputOuter = styled.div`
  margin-top: 1rem;
`

const FileInput = styled.input`

`

const ButtonOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DeleteButton = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #fff;
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
  cursor: pointer;

  opacity: 0;
  ${ImageItem}:hover & {
    opacity: 1;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    width: 0.75rem;
    height: 1px;
    background-color: #888;
    margin: -0.5px 0;
  }

  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`

const AddGalleryForm: FunctionComponent = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const {profile} = useUserStatus()
  const galleryImagesQuery = useGalleryImagesQuery({
    variables: {
      input: {
        userName: profile?.name ?? ''
      }
    }
  })
  const [addGalleryImagesMutation] = useAddGalleryImageMutation()
  const {open} = useErrorModal()
  const [deleteGalleryImageMutation] = useDeleteGalleryImageMutation()

  const add = async () => {
    const files = inputRef.current?.files
    if(files){
      for(let file of Array.from(files)){
        console.log(file)
        const res = await addGalleryImagesMutation({
          variables: {
            image: file
          }
        })
        if(res.errors){
          open(res.errors)
        }
        await galleryImagesQuery.refetch()
      }
      if(inputRef.current){
        inputRef.current.value = ""
      }
    }
  }

  return <AddGalleryFormOuter>
    <ImageList>
      {galleryImagesQuery.data?.galleryImages.map(galleryImage => {
        return <ImageItemOuter>
          <ImageItem key={galleryImage.id}>
            <DeleteButton onClick={async () => {
              await deleteGalleryImageMutation({
                variables: {
                  input: {
                    galleryImageId: galleryImage.id
                  }
                }
              })
              await galleryImagesQuery.refetch()
            }}/>
            <Img src={galleryImage.url} width="100" height="100"/>
          </ImageItem>
        </ImageItemOuter>
      })}
    </ImageList>
    <InputOuter>
      <FileInput type="file" ref={inputRef} />
    </InputOuter>
    <InputOuter>
      
    </InputOuter>
    <FormButton onClick={add}>
      追加する
    </FormButton>
  </AddGalleryFormOuter>

  
}