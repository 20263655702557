import styled from 'styled-components';
import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { H1 } from '../atoms/h1';
import { useUpdateProfileMutation, useUpdateProfileImageMutation, useProfileQuery, GQLProfileInput } from '../../generated/graphql';
import { useApolloClient } from '@apollo/react-hooks';
import { ProfileImage } from '../atoms/profile-image';
import { useUserStatus } from '../../hooks/use-user-status'
import { createForm } from '../organisms/forms'
import { InputContainer } from '../molecules/input-container'
import { FormButton } from '../organisms/form-button'
import { useErrorModal } from '../../hooks/use-error-modal'
import SvgCameraIcon from '../../generated/svg/CameraIcon'
import { AccountPageTabs } from '../molecules/account-page-tabs'
import { AccountPageOuter, AccountPageTabContainer, AccountPageHead, AccountPageHeadIconContainer, AccountPageHeadLabelContainer, AccountPageHeadLabel, AccountPageHeadLabelEnglish } from '../organisms/account-page-parts'
import { FormContainer } from '../organisms/form-container'

const {
  useForm,
  Form,
  Text,
  Textarea,
  Checkbox,
  NumberText
} = createForm<GQLProfileInput>()

const EditProfilePageOuter = styled.div``


const ProfileImageContainer = styled.div`
  display: flex;
  align-items: center;
`
const ProfileImageInputOuter = styled.div`
  flex: 1 1 0;
  margin-left: 1.5rem;
`
const ProfileImageInput = styled.input`

`
const ProfileImageOuter = styled.div`
  width: 8rem;
`

const NoteText = styled.div`
  font-size: 0.8rem;
  margin-top: 1rem;
  text-align: center;
`

export const EditProfilePage: FunctionComponent = () => {
  const [updateProfile] = useUpdateProfileMutation()
  const profileQuery = useProfileQuery()
  const [name, setName] = useState(profileQuery.data?.profile?.name ?? '')
  const [screenName, setScreenName] = useState(profileQuery.data?.profile?.screenName ?? '')
  const [phoneNumber, setPhoneNumber] = useState(profileQuery.data?.profile?.phoneNumber ?? '')
  const [zipcode, setZipcode] = useState(profileQuery.data?.profile?.zipcode ?? '')
  const [address, setAddress] = useState(profileQuery.data?.profile?.address ?? '')
  const [profileImageFile, setProfileImageFile] = useState<File>()
  const [updateProfileImage] = useUpdateProfileImageMutation()
  const uploadProfileImage = async () => {
    await updateProfileImage({variables: {
      image: profileImageFile
    }})
    await client.reFetchObservableQueries()
  }
  const client = useApolloClient()
  const profileImage = profileQuery.data?.profile?.profileImage
  const {open: openErrorModal} = useErrorModal()
  const userStatus = useUserStatus()
  const user = userStatus.profile
  if(!user) return <>403</>
  const [values, fromProps] = useForm({
    screenName: user.screenName,
    phoneNumber: user.phoneNumber,
    zipcode: user.zipcode,
    address: user.address
  })
  if(profileQuery.loading){
    return <>Loading</>
  }
  return <AccountPageOuter>
    <AccountPageTabContainer>
      <AccountPageTabs />
    </AccountPageTabContainer>
    <AccountPageHead>
      <AccountPageHeadIconContainer>
        <SvgCameraIcon></SvgCameraIcon>
      </AccountPageHeadIconContainer>
      <AccountPageHeadLabelContainer>
        <AccountPageHeadLabel>登録情報を編集する</AccountPageHeadLabel>
        <AccountPageHeadLabelEnglish>EDIT USER INFORMATION</AccountPageHeadLabelEnglish>
      </AccountPageHeadLabelContainer>
    </AccountPageHead>
    <FormContainer>
      <ProfileImageContainer>
        <ProfileImageOuter>
          <ProfileImage profileImage={profileQuery.data?.profile?.profileImage} />
        </ProfileImageOuter>
        <ProfileImageInputOuter>
          <ProfileImageInput type="file" onChange={e => {
            const file = e.currentTarget.files?.[0]
            if(file){
              setProfileImageFile(file)
            }
          }}/>
        </ProfileImageInputOuter>
      </ProfileImageContainer>
      <NoteText>プロフィール画像は正方形の画像を推奨しております。</NoteText>
      <FormButton onClick={async () => {
        uploadProfileImage()
      }}>プロフィール画像を更新する</FormButton>
    </FormContainer>
    <FormContainer>
      <Form {...fromProps}>
        <InputContainer label="ユーザー名"><Text name="screenName" /></InputContainer>
        <InputContainer label="電話番号"><Text name="phoneNumber" /></InputContainer>
        <InputContainer label="郵便番号"><Text name="zipcode" /></InputContainer>
        <InputContainer label="住所"><Text name="address" /></InputContainer>
      </Form>
      <FormButton onClick={async () => {
        try {
          await updateProfile({
            variables: {
              profile: {
                screenName,
                phoneNumber,
                zipcode,
                address
              }
            }
          })
        } catch(err) {
          openErrorModal
        }
        await client.reFetchObservableQueries()
      }}>更新する</FormButton>
    </FormContainer>
  </AccountPageOuter>
}
