import * as React from "react";

function SvgLoginIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23.079 23.153"
      {...props}
    >
      <path
        d="M8.803.404A11.554 11.554 0 00.648 7.803h12.347zM19.65 3.462A11.514 11.514 0 009.38.182l6.039 10.658zM14.133 14.743h8.513A11.52 11.52 0 0020.26 4.062zM11.54 23.153a11.564 11.564 0 002.06-.187L7.518 12.28l-4.28 7.344a11.505 11.505 0 008.3 3.529zM8.942 8.483H.432a11.53 11.53 0 002.325 10.611zM14.3 22.818a11.559 11.559 0 008.132-7.393h-12.34z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgLoginIcon;
