import React, { ComponentType, FunctionComponent, lazy } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client'
import { ProfileProvider } from './hooks/use-user-status';
import { ModalProvider } from './hooks/use-modal';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { LoadScript } from '@react-google-maps/api'
import { createApolloClient } from './utils/apollo-utils';
import styled, { CSSProperties } from 'styled-components';
import { ErrorBlock } from './components/molecules/error-block';
import { PhotographerPage } from './components/pages/photographer-page';
import { JobPage } from './components/pages/job-page';
import { SearchPhotographers } from './components/pages/search-photographers';
import { SignUpPage } from './components/pages/sign-up-page';
import { LogoutPage } from './components/pages/logout-page';
import { ChangePasswordPage } from './components/pages/change-password-page';
import { EditProfilePage } from './components/pages/edit-profile-page';
import { SearchJobs } from './components/pages/search-jobs';
import { UserPage } from './components/pages/user-page';
import { AccountEntriesPage } from './components/pages/account-entries-page';
import { AccountJobsPage } from './components/pages/account-jobs-page';
import { PasswordResetPage } from './components/pages/password-reset-page';
import { VerifyEmailPage } from './components/pages/verify-email-page';
import { AddPaymentMethodPage } from './components/pages/add-payment-method-page';
import { Header } from './components/atoms/header';
import { TopPage } from './components/pages/top-page';
import { Footer } from './components/organisms/footer';
import { AccountPage } from './components/pages/account-page';
import { RegisterPhotographerTab } from './components/organisms/register-photographer-tab';
import { TransactionsTab } from './components/organisms/transactions-tab';
import { AddJobTab } from './components/molecules/add-job-tab';
import { SigninPage } from './components/pages/signin-page';
import { RequireSignIn } from './components/molecules/require-signin';
import { Terms } from './components/pages/terms'
import { PrivacyPolicy } from './components/pages/privacy-policy'
import { Tokusho } from './components/pages/tokusho'
import { FilteredKeys } from './utils/type-utils';
import { NewsPostPage } from './components/pages/news-post-page';
import { StudioPage } from './components/pages/studio-page';
import { StudioEntryPage } from './components/pages/studio-entry-page';
import { RequestJobPage } from './components/pages/request-job-page';
import { ContactPage } from './components/pages/contact-page';
import { HelpPage } from './components/pages/help-page';
import { PaymentRequestPage } from './components/pages/payment-requst-page';
import { UsagePage } from './components/pages/usage-page';
function load<T>(resolver: Promise<T>, name: FilteredKeys<T, ComponentType<any>>){
  return lazy(async () => {
    const resolved = await resolver
    return ({default: resolved[name]} as any)
  })
}

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`
const ContentContainer = styled.div`
  flex: 1 1 auto;
`

const ErrorBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY!)
export const App: FunctionComponent = () => {
  return <>
    <Elements stripe={stripePromise} options={{locale: 'ja'}}>
      <ApolloProvider client={createApolloClient()}>
        <ProfileProvider>
          <BrowserRouter>
            <ModalProvider>
              <LoadScript googleMapsApiKey="AIzaSyATyfZXJ1yyIt4PfLUHQ5UefwQFJ6HDqFM" language="ja" libraries={['places']}>
                <AppContainer>
                  <Header />
                    <ContentContainer>
                      <Switch>
                        <Route exact path="/jobs" component={SearchJobs} />


                        <Route path="/jobs/:jobId" render={({match}) => {
                          return <JobPage jobId={match.params.jobId} />
                        }} />

                        <Route path="/users/:userName" render={({match}) => {
                          return <UserPage userName={match.params.userName} />
                        }} />

                        <Route exact path="/photographers" component={SearchPhotographers} />

                        <Route exact path="/photographers/:userName" render={({match}) => {
                          return <PhotographerPage userName={match.params.userName} />
                        }} />

                        <Route exact path="/photographers/:userName/request" render={({match}) => {
                          return <RequestJobPage userName={match.params.userName} />
                        }} />

                        <Route exact path="/studios" component={SearchPhotographers} />

                        <Route path="/studios/:studioName" render={({match}) => {
                          return <StudioPage studioName={match.params.studioName} />
                        }} />

                        <Route exact path="/news/:newsPostId" render={({match}) => {
                          return <NewsPostPage newsPostId={match.params.newsPostId} />
                        }} />

                        <Route exact path="/studio-entry" component={StudioEntryPage} />

                        <Route path="/account" render={() => {
                          return <RequireSignIn>
                            <Switch>
                              <Route exact path="/account" render={() => {
                                return <Redirect to="/account/transactions" />
                              }} />
                              <Route exact path="/account/password" component={ChangePasswordPage} />



                              <Route exact path="/account/entries" component={AccountEntriesPage} />

                              <Route exact path="/account/jobs" component={AccountJobsPage} />

                              <Route exact path="/account/add-job" render={() => {
                                return <div style={{"--accent-color": '#70a52e'} as CSSProperties}>
                                  <AccountPage headerText="ADD JOB">
                                    <AddJobTab />
                                  </AccountPage>
                                </div>
                              }}>
                              </Route>

                              <Route exact path="/account/transactions" render={() => {
                                return <div style={{"--accent-color": '#4d4d4d'} as CSSProperties}>
                                  <AccountPage headerText="MEMBER">
                                    <TransactionsTab />
                                  </AccountPage>
                                </div>
                              }}>
                              </Route>

                              <Route exact path="/account/register-photographer" render={() => {
                                return <div style={{"--accent-color": '#005938'} as CSSProperties}>
                                  <AccountPage headerText="PHOTOGRAPHER">
                                    <RegisterPhotographerTab />
                                  </AccountPage>
                                </div>
                              }}>
                              </Route>

                              <Route exact path="/account/profile">
                                <div style={{"--accent-color": '#005938'} as CSSProperties}>
                                  <AccountPage headerText="PHOTOGRAPHER">
                                    <EditProfilePage />
                                  </AccountPage>
                                </div>
                              </Route>

                              <Route exact path="/account/payment-methods" component={AddPaymentMethodPage} />
                              <Route exact path="/account/payment-request" component={PaymentRequestPage} />
                            </Switch>
                          </RequireSignIn>
                        }} />

                        <Route exact path="/password-reset" component={PasswordResetPage} />

                        <Route exact path="/verify-email" component={VerifyEmailPage} />

                        <Route exact path="/signup" component={SignUpPage} />

                        <Route exact path="/signin" component={SigninPage} />
                        
                        <Route exact path="/logout" component={LogoutPage} />

                        <Route exact path="/terms-of-service" component={Terms} />

                        <Route exact path="/privacy-policy" component={PrivacyPolicy} />

                        <Route exact path="/tokusho" component={Tokusho} />

                        <Route exact path="/help" component={HelpPage} />
                        <Route exact path="/contact" component={ContactPage} />
                        <Route exact path="/tokusho" component={Tokusho} />
                        <Route exact path="/usage" component={UsagePage} />

                        <Route exact path="/" component={TopPage} />

                        <Route render={() => {
                          return <ErrorBlockContainer>
                            <ErrorBlock message="Not found" name="404" />
                          </ErrorBlockContainer>
                        }} />
                      </Switch>
                    </ContentContainer>
                  <Footer />
                </AppContainer>
              </LoadScript>
            </ModalProvider>
          </BrowserRouter>
        </ProfileProvider>
      </ApolloProvider>
    </Elements>
  </>
}