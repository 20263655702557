import React, { FunctionComponent } from 'react';
import { LoginIcon } from '../../generated/svg';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Outer = styled(Link)`
  display: block;
`

const IconOuter = styled.div`
  width: 1.5rem;
  margin-right: 0.5rem;
  svg {
    width: 100%
  }
  @media (max-width: 40rem){
    margin-right: 0;
  }
`

const LabelOuter = styled.div`
  color: var(--base-color);
  @media (max-width: 40rem){
    font-size: 0.6rem;
    font-weight: 700;
  }
`

const ButtonOuter = styled.div`
  width: 8rem;
  background-color: var(--accent-color);
  border-radius: 0 0 0.5rem 0.5rem;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  align-items: center;
  @media (max-width: 40rem){
    border-radius: 0;
    flex-direction: column;
    align-items: center;
    width: 4rem;
    padding: 0.75rem 0;
  }
`
type HeaderButtonProps = {
  to: string
}
export const HeaderButton: FunctionComponent<HeaderButtonProps> = ({children, to}) => {
  return <Outer to={to}>
    <ButtonOuter>
      <IconOuter>
        <LoginIcon/>
      </IconOuter>
      <LabelOuter>{children}</LabelOuter>
    </ButtonOuter>
  </Outer>
}