import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import React from 'react'
import { FunctionComponent } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  width: 100%;
  height: 100%;
`

type JobPageMapProps = {
  lat: number,
  lng: number
}
export const JobPageMap: FunctionComponent<JobPageMapProps> = ({lat, lng}) => {
  const position = {lat, lng}
  return <Outer>
    <GoogleMap zoom={14} center={position} mapContainerStyle={{height: '100%'}}>
      <Marker position={position} />
    </GoogleMap>
  </Outer>
}