import * as React from "react";

function SvgMenuClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42.355 42.355"
      {...props}
    >
      <rect
        x={-7.067}
        y={18.21}
        width={56.489}
        height={5.935}
        rx={2.155}
        transform="rotate(45 21.178 21.178)"
        fill="currentcolor"
      />
      <rect
        x={-7.067}
        y={18.21}
        width={56.489}
        height={5.935}
        rx={2.155}
        transform="rotate(-45 21.178 21.178)"
        fill="currentcolor"
      />
    </svg>
  );
}

export default SvgMenuClose;
