import React, { ReactNode, FunctionComponent } from 'react'
import styled from 'styled-components'
import { Modal } from './modal'

type MessageModalProps = {
  messages: ReactNode[],
  onClose: () => void
}

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Message = styled.div`
  font-size: 0.8rem;
  & + & {
    margin-top: 0.5rem;
  }
`


export const MessageModal: FunctionComponent<MessageModalProps> = ({messages, onClose}) => {
  return <Modal onClose={onClose}>
    <MessageContainer>
      {messages.map(message => typeof message === 'string' ? <Message>{message}</Message> : message)}
    </MessageContainer>
  </Modal>
}