import React, { FunctionComponent } from 'react';
import { useUserQuery, useUserWithJobsQuery } from '../../generated/graphql';
import { ErrorBlock } from '../molecules/error-block';
import styled from 'styled-components';
import { User } from '../molecules/user';
import { Job } from '../molecules/job';
import { H1 } from '../atoms/h1';

const UserPageOuter = styled.div``

const JobListOuter = styled.div`
  margin: 1rem 0;
  padding: 1rem;
  border: 1px solid #000;
`

export const UserPage: FunctionComponent<{userName: string}> = ({userName}) => {
  const userQuery = useUserWithJobsQuery({variables: {userName}})
  const user = userQuery.data?.user
  if(userQuery.loading) return <>Loading</>
  if(!user) return <ErrorBlock name="404" message="Not found" />
  return <UserPageOuter>
    <User user={user} />
    <JobListOuter>
      <H1>Jobs</H1>
      {
        user.jobs.map(job => <Job key={job.id} job={job} />)
      }
    </JobListOuter>
  </UserPageOuter>
}