import React, { Fragment, FunctionComponent } from 'react';
import styled from 'styled-components';
import { GQLUser, GQLProfileImage, GQLEquipment, useGalleryImagesQuery } from '../../generated/graphql';
import { ProfileImage } from '../atoms/profile-image';
import { PartialRequired } from '../../utils/type-helper';
import { Breadcrumbs } from './breadcrumbs';
import { ProfileTable } from './profile-table';
import { formatPrice } from '../../utils/format-price';
import { isNullish } from '../../utils/type-check';
import { CameraIcon } from '../../generated/svg';
import { SinglePageInner } from '../atoms/single-page-inner';
import { SinglePageBreadcrumbsContainer } from '../atoms/single-page-breadcrumbs-container';
import { SinglePageOuter } from '../atoms/single-page-outer';
import { SinglePageDetail } from './single-page-detail';
import { SinglePageContentContainer } from '../atoms/single-page-content-container';
import { PhotographerComments } from './photographer-comments'
import { GalleryImages } from './gallery-images';
import { Button } from '../atoms/button';
import { Link } from 'react-router-dom';

const Outer = styled.div`
  --accent-color: #005938;
`

const Inner = styled.div`
  display: flex;
  @media (max-width: 40rem) {
    flex-direction: column;
  }
`

const ImageContainer = styled.div`
  flex: 1 1 0;
  background-color: #666;
  padding: 1rem;
  @media (max-width: 40rem) {
    order: 1;
  }
`

const ProfileConatiner = styled.div`
  flex: 1 1 0;
`
const ProfileHeader = styled.div`
  background-color: #005938;
  color: #fff;
  padding: 1rem;
  display: flex;
  position: relative;
  & > svg {
    position: absolute;
    width: 3rem;
    height: auto;
    top: 0;
    right: 0;
    margin: 1rem;
  }
`

const ProfileImageContainer = styled.div`
  width: 8rem;
  height: 8rem;
  flex: 0 0 auto;
`

const ProfileHeaderInfoContainer = styled.div`
  flex: 1 1 0;
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
`
const Name = styled.div`
  padding-left: 0.5rem;
  flex: 1 1 0;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
`
const Id = styled.div`
  flex: 0 0 auto;
  padding: 0.6rem 0 0.6rem 0.6rem;
  border-top: 1px solid #808080;
  font-size: 0.8rem;
  &::before {
    content: 'ID: '
  }
`
const Detail = styled.div`
  flex: 0 0 auto;
  padding: 0.6rem 0 0.25rem 0.6rem;
  border-top: 1px solid #808080;
  font-size: 0.8rem;
  display: flex;
`
const BirthYear = styled.div``
const Sex = styled.div``
const WorkCount = styled.div`
  margin-left: auto;
`
const WorkCountNumber = styled.span`
  font-weight: 700;
  text-decoration: underline;
`

const ProfileDetailTableContainer = styled.div`
  margin-bottom: 2rem;
`

const CommentContainer = styled.div`
`

const ButtonContainer = styled.div`
  margin-bottom: 2rem;
`


export type PhotographerProps = {
  photographer: PartialRequired<GQLUser, 'name' | 'id' | 'screenName' | 'description'> & {equipment: PartialRequired<GQLEquipment, 'screenName'>[]} & {profileImage?: PartialRequired<GQLProfileImage, 'width' | 'height' | 'url'> | null}
}
export const Photographer: FunctionComponent<PhotographerProps> = ({photographer}) => {

  const galleryImagesQuery = useGalleryImagesQuery({
    variables: {
      input: {
        userName: photographer.name
      }
    }
  })
  return <Outer>
    <SinglePageOuter>
      <SinglePageBreadcrumbsContainer>
        <Breadcrumbs items={[
          {
            label: 'Top',
            url: '/'
          }, {
            label: 'カメラマン',
            url: '/photographers'
          }, {
            label: photographer.workArea || 'エリア無し',
          }
        ]} />
      </SinglePageBreadcrumbsContainer>
      <SinglePageInner>
        <Inner>
          <ImageContainer>
            <GalleryImages images={galleryImagesQuery.data?.galleryImages ?? []} /> 
          </ImageContainer>
          <ProfileConatiner>
            <ProfileHeader>
              <CameraIcon style={{color: '#437243'}}/>
              <ProfileImageContainer>
                <ProfileImage profileImage={photographer.profileImage} square />
              </ProfileImageContainer>
              <ProfileHeaderInfoContainer>
                <Name>{photographer.screenName}</Name>
                <Id>{photographer.name}</Id>
                <Detail>
                  <BirthYear>{photographer.birthYear ?? '非公開'}</BirthYear>
                  <Sex>{photographer.sex}</Sex>
                  <WorkCount>
                    納品件数: 
                    <WorkCountNumber>{photographer.workCount}</WorkCountNumber>
                  </WorkCount>
                </Detail>
              </ProfileHeaderInfoContainer>
            </ProfileHeader>
            <SinglePageContentContainer>
              <ProfileDetailTableContainer>
                <ProfileTable values={new Map([
                  ['活動地域', photographer.workArea ?? 'なし'],
                  ['報酬相場', formatFeeRange(photographer.feeRangeStart, photographer.feeRangeEnd)],
                  ['使用機材', formatEquipment(photographer.equipment)]
                ])} />
              </ProfileDetailTableContainer>
              <ButtonContainer>
                <Button as={Link} to={`/photographers/${photographer.name}/request`}>
                  この人に依頼する
                </Button>
              </ButtonContainer>
              <SinglePageDetail title="PROFILE">
                {photographer.description}
              </SinglePageDetail>
              <SinglePageDetail title="COMMENT/FAQ">
                <PhotographerComments photographerId={photographer.id} />
              </SinglePageDetail>
            </SinglePageContentContainer>
          </ProfileConatiner>
        </Inner>
      </SinglePageInner>
    </SinglePageOuter>
  </Outer>
} 

const formatFeeRange = (start: number | null | undefined, end: number | null | undefined) => {
  if(typeof start === 'number' || typeof end ==='number'){
    return `${isNullish(start) ? '' : formatPrice(start)}〜${isNullish(end) ? '' : formatPrice(end)}`
  } else {
    return '無し'
  }
}

const formatEquipment = (equipment: PartialRequired<GQLEquipment, 'screenName'>[]) => {
  if(equipment.length < 1){
    return '無し'
  } else {
    return equipment.map(equipment => equipment.screenName).join(', ')
  }
}