import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import { useUserWithJobsQuery, usePhotographerQuery } from '../../generated/graphql'
import { ErrorBlock } from '../molecules/error-block'
import { Link } from 'react-router-dom'
import { H1 } from '../atoms/h1'
import { Photographer } from '../molecules/photographer'

const PhotographerPageOuter = styled.div``

export const PhotographerPage: FunctionComponent<{userName: string}> = ({userName}) => {
  const userQuery = usePhotographerQuery({variables: {userName}})
  const user = userQuery.data?.user
  if(userQuery.loading){
    return <div>Loading</div>
  }
  if(!user){
    return <ErrorBlock name="404" message="Not found" />
  }
  return <PhotographerPageOuter>
    <Photographer photographer={user} />
  </PhotographerPageOuter>
}