import styled from 'styled-components';
import React, { FunctionComponent, useEffect } from 'react';
import { useModal } from '../../hooks/use-modal';
import { Modal } from './modal';

const Outer = styled.div`
  padding: 3rem 2rem;
`

const ErrorHeader = styled.h1`
  margin-bottom: 1rem;
  text-align: center;
  font-family: var(--head-font-family);
  font-weight: var(--head-font-weight);
  font-size: 3rem;
`

const ErrorText = styled.p`
  text-align: center;
  & + & {
    margin-top: 1rem;
  }
`
export const ErrorBlock: FunctionComponent<{name: string, message: string}> = ({name, message}) => {
  return <Outer>
    <ErrorHeader>{name}</ErrorHeader>
    <ErrorText>{message}</ErrorText>
  </Outer>
}