import React from 'react'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { CommentCard, CommentData } from './comment-card'

type CommentListProps = {
  comments: CommentData[]
}

const Outer = styled.div`

`

const Inner = styled.div`

`

export const CommentList: FunctionComponent<CommentListProps> = ({comments}) => {
  return <Outer>
    <Inner>
      {
        comments.map(comment => <CommentCard key={comment.id} comment={comment} />)
      }
    </Inner>
  </Outer>
}