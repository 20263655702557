import React, { FunctionComponent, ReactElement, ReactNode } from 'react'
import styled from 'styled-components'

export type LinkBuilderArguments = {page: number, active: boolean}
export type LinkBuilder = (linkBuilderArgemnts: LinkBuilderArguments) => ReactNode

export type SpreadBuilder = () => ReactNode

const PaginateOuter = styled.div`
  display: flex;
  margin: 1rem 0;
`
const padding = 1
const width = padding * 2 + 1

const range = (num: number, start: number = 0) => Array.from(Array(num), (v, i) => i + start)
const defaultSpreadBuilder: SpreadBuilder = () => '...'

export type PaginateProps = {
  pageCount: number,
  currentPage: number,
  linkBuilder: LinkBuilder,
  spreadBuilder?: () => ReactNode,
  firstLinkBuilder?: LinkBuilder,
  lastLinkBuilder?: LinkBuilder
}

export const Paginate: FunctionComponent<PaginateProps> = ({
  currentPage,
  pageCount,
  linkBuilder,
  spreadBuilder = defaultSpreadBuilder,
  firstLinkBuilder = linkBuilder,
  lastLinkBuilder = linkBuilder
}) => {
  return <PaginateOuter>
    {
      pageCount > width && currentPage > padding + 1 &&
      firstLinkBuilder({page: 1, active: currentPage === 1})
    }
    {
      pageCount > width + 1 && currentPage > padding + 2 &&
      spreadBuilder()
    }
    {
      range(Math.min(pageCount, padding * 2 + 1), Math.max(1, Math.min(pageCount + 1 - (padding * 2 + 1), currentPage - padding))).map(i => <React.Fragment key={i}>{linkBuilder({page: i, active: currentPage === i})}</React.Fragment>)
    }
    {
      pageCount > width + 1 && currentPage < pageCount - padding - 1 && 
      spreadBuilder()
    }
    {
      pageCount > width && currentPage < pageCount - padding &&
      lastLinkBuilder({page: pageCount, active: currentPage === pageCount})
    }
  </PaginateOuter>
}