import React, { FunctionComponent, HTMLProps } from 'react'
import styled from 'styled-components'

const InputOuter = styled.div`
`

const Input = styled.input`
  display: block;
  font-size: 1rem;
  border: 0 none;
  padding: 0.25rem 0.25rem 0 0.25rem;
  background-color: transparent;
`

export interface TextInputProps extends Pick<HTMLProps<HTMLInputElement>, 'type' | 'value' | 'onChange' | 'onBlur'> {
}

export const TextInput: FunctionComponent<TextInputProps> = (inputProps) => {
  return <InputOuter>
    <Input {...inputProps} />
  </InputOuter>
}