import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { useJobsQuery, useProfileQuery } from '../../generated/graphql';
import { Paginate } from '../molecules/paginate';
import { SearchParams } from '../../utils/search-params';
import { ParsedUrlQuery } from 'querystring';
import { clip } from '../../utils/util';
import { normarizeStringParam, normarizeQueryStringObject } from '../../utils/normarize';
import querystring from 'querystring'
import { useLocation, Link } from 'react-router-dom';
import { Job } from '../molecules/job';

const AccountJobsPageOuter = styled.div``
const LinkOuter = styled.div`
  a {
    padding: 1rem;
    display: block;
    border: 1px solid #000;
  }
`

class AccountJobsParams extends SearchParams {
  page: number;
  constructor(params: ParsedUrlQuery){
    super(params)
    this.page = clip(Number(normarizeStringParam(params.p) ?? 1), 0, Infinity)
  }
  toString(){
    return querystring.stringify(normarizeQueryStringObject({
      p: this.page
    }))
  }
}

export const AccountJobsPage: FunctionComponent = () => {
  const {search} = useLocation()
  const searchParams = AccountJobsParams.fromQueryString(search)
  const profileQuery = useProfileQuery()
  const jobsQuery = useJobsQuery({
    variables: {
      userNames: [profileQuery.data?.profile?.name!],
      limit: 10,
      offset: (searchParams.page - 1) * 10
    },
    skip: profileQuery.loading
  })
  return <AccountJobsPageOuter>
    <Paginate
      currentPage={searchParams.page}
      pageCount={Math.ceil((jobsQuery.data?.jobs.count ?? 0) / 10)}
      linkBuilder={({page}) => {
        return　<LinkOuter>
          <Link to={{pathname: '/account/jobs', search: searchParams.setValues({page}).toString()}}>{page}</Link>
        </LinkOuter>
      }}
    />
    
    {jobsQuery.data?.jobs?.items.map(job => {
      return <Job key={job.id} job={job} />
    })}
    <Paginate
      currentPage={searchParams.page}
      pageCount={Math.ceil((jobsQuery.data?.jobs.count ?? 0) / 10)}
      linkBuilder={({page}) => {
        return　<LinkOuter>
          <Link to={{pathname: '/account/jobs', search: searchParams.setValues({page}).toString()}}>{page}</Link>
        </LinkOuter>
      }}
    />
  </AccountJobsPageOuter>
}