import gql from 'graphql-tag'

export const AREAS_QUERY = gql`
  query areas($prefecture: String!){
    areas(prefecture: $prefecture){
      ...Area
    }
  }
`

export const AREA_BY_NAME_QUERY = gql`
  query areaByName($prefecture: String!, $city: String!){
    area(prefecture: $prefecture, city: $city){
      ...Area
    }
  }
`

export const AREA_BY_CODE_QUERY = gql`
  query areaByCode($code: String!){
    area(code: $code){
      ...Area
    }
  }
`