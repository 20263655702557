import React from 'react'
import { Modal } from '../components/molecules/modal'
import { Signin } from '../components/organisms/signin'
import { useModal } from './use-modal'

export const useSigninModal = () => {
  const {open} = useModal()
  return {
    open: async () => {
      open(({resolve, reject}) => {
        return <Modal hideCloseButton>
          <Signin onComplete={() => resolve(undefined)} />
        </Modal>
      })
    }
  }
}