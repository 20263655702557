import * as React from "react";

function SvgMail(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 46.838 28.865"
      {...props}
    >
      <path
        fill="currentcolor"
        d="M0 1.477V27.85l14.5-12.476L0 1.477zM46.838 27.664V1.163l-14.57 13.964 14.57 12.537zM23.255 23.765l-7.537-7.224L1.395 28.865h44.264L31.05 16.294l-7.795 7.471z"
      />
      <path
        fill="currentcolor"
        d="M.885 0l14.893 14.274.61.583.609.584 6.258 5.998 6.516-6.245.609-.583.609-.584L45.625 0H.885z"
      />
    </svg>
  );
}

export default SvgMail;
