import styled from 'styled-components'
import { FunctionComponent, ReactNode } from 'react'
import React from 'react'
import {Image} from '../atoms/image'
import { Link } from 'react-router-dom'
import { isNullish } from '../../utils/type-check'

const Outer = styled(Link)`
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  padding: 0.5rem;
  position: relative;
  display: block;
`

const ImageContainer = styled.div``

const Name = styled.div`
  background-color: var(--accent-color, #888);
  color: #fff;
  font-weight: bold;
  padding: 0.5rem;
  font-size: 0.9rem;
`

const Description = styled.div`
  padding: 0.5rem;
  font-size: 0.9em;
  line-height: 1.5;
`

const Price = styled.div`
  padding: 0.5rem;
  border-top: 1px dotted #c8c8c8;
  font-size: 0.9rem;
`

const IconOuter = styled.div`
  position: absolute;
  width: 1rem;
  height: 1rem;
  bottom: 0;
  right: 0;
  margin: 1rem;
`

export interface ImageObject {
  url: string
  width: number
  height: number
}

type CardProps = {
  image?: ImageObject
  name: ReactNode,
  description: ReactNode,
  price?: number | null,
  icon: ReactNode,
  link?: string
}

export const Card: FunctionComponent<CardProps> = ({image, name, description, price, icon, link = ''}) => {
  return <Outer to={link}>
    <ImageContainer>
      {
        <Image src={image?.url} width="400" height="400" style={{backgroundColor: '#f8f8f8'}}/>
      }
    </ImageContainer>
    <Name>{name}</Name>
    <Description>{description}</Description>
    {
      !isNullish(price) &&
      <Price>¥{price.toLocaleString('en-US')}</Price>
    }
    <IconOuter>{icon}</IconOuter>
  </Outer>
}