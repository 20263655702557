import React, { FunctionComponent } from 'react'
import { HeaderButton } from './header-button'
import { HeaderLogo } from './header-logo'
import { Navigator, NavigatorItem } from './navigator'
import styled from 'styled-components'
import { useUserStatus } from '../../hooks/use-user-status'
import { useIsMobile } from '../../hooks/use-is-mobile'
import { MobileMenu } from '../molecules/mobile-menu'
import SvgHorizontalLogo from '../../generated/svg/HorizontalLogo'
import { Link } from 'react-router-dom'

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: min(75rem, calc(100% - 2rem));
  margin: 0 auto;
  @media (max-width: 40rem){
    width: 100%;
    max-width: none;
  }
`
const ButtonOuter = styled.div`
`

const LoginButtonOuter = styled(ButtonOuter)`
  --accent-color: #005938;
`

const LogoutButtonOuter = styled(ButtonOuter)`
  --accent-color: #4d4d4d;
`

const LogoOuter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`

const NavigatorOuter = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0;
`

const MobileOuter = styled.div`

`

const MobileLogoOuter = styled(Link)`
  align-self: center;
  margin-right: auto;
  margin-left: 1rem;
  svg {
    width: 12rem;
  }
`

export const Header: FunctionComponent = () => {
  const {signedIn} = useUserStatus()
  const isMobile = useIsMobile()
  return isMobile ? <>

    <MobileOuter>
      <ButtonContainer>
        <MobileLogoOuter to="/">
          <SvgHorizontalLogo />
        </MobileLogoOuter>
        <MobileMenu />
        {
          signedIn ? 
          <LogoutButtonOuter>
            <HeaderButton to="/account">マイページ</HeaderButton>
          </LogoutButtonOuter>:
          <LoginButtonOuter>
            <HeaderButton to="/signin">ログイン</HeaderButton>
          </LoginButtonOuter>
        }
      </ButtonContainer>
    </MobileOuter>
  </> : <>
    <ButtonContainer>
      {
        signedIn ? 
        <LogoutButtonOuter>
          <HeaderButton to="/account">マイページ</HeaderButton>
        </LogoutButtonOuter>:
        <LoginButtonOuter>
          <HeaderButton to="/signin">ログイン</HeaderButton>
        </LoginButtonOuter>
      }
    </ButtonContainer>
    <LogoOuter>
      <HeaderLogo />
    </LogoOuter>
    <NavigatorOuter>
      <Navigator>
        <NavigatorItem to="/">TOP</NavigatorItem>
        <NavigatorItem to="/usage">このサイトの使い方</NavigatorItem>
        <NavigatorItem to="/help">ヘルプ</NavigatorItem>
        <NavigatorItem to="/terms-of-service">利用規約</NavigatorItem>
        <NavigatorItem to="/contact">お問い合わせ</NavigatorItem>
      </Navigator>
    </NavigatorOuter>
  </>
}
