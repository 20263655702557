import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { FormattedText } from '../molecules/format'

const Outer = styled.div`
  padding: 0 1rem;
  max-width: 50rem;
  margin: 0 auto;
`

export const Terms: FunctionComponent = () => {
  return <Outer>
    <FormattedText>
      <h1>利用規約</h1>

      <h2>第1条（適用）</h2>
      <ul>
        <li>本規約は、本サービスの提供条件および本サービスの利用に関する株式会社クルム（以下、「当社」といいます。）とユーザーとの間の権利義務関係を定めることを目的とし、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。  </li>
        <li>本規約の内容と、本サービスに関連して作成する個別の規約または本サービス上に掲載するガイドライン等（以下、「個別規定」といいます。）とが異なる場合は、個別規定が優先して適用されるものとします。</li>
      </ul>

      <h2>第2条（定義）</h2>
      <ul>
        <li>「本サービス」とは、当社が企画・運営するオンラインイベントをいいます。</li>
        <li>「ユーザー」とは、本サービスを利用するすべての個人、法人、団体のことをいいます。</li>
        <li>「事業者等」とは、当社との間で契約を締結し、本サービスと提携するサービスを提供し、またはその運営を行う事業者のことをいいます。</li>
        <li>「本契約」とは、本規約を契約条件として当社とユーザーの間で締結される本サービスの利用契約をいいます。</li>
        <li>「本サイト」とは、当社が、本サービスを提供するためのウェブサイトをいいます。</li>
      </ul>

      <h2>第3条（ユーザー）</h2>
      <ul>
        <li>ユーザーは、本サービスの利用に際し、本規約の定めに従うことを承諾したものとみなします。</li>
        <li>ユーザーが、本サイト内の申込フォームに所定の事項を記入し、記入データを当社が受信したことをもって、本サービスへの登録を完了し、本契約が成立したものとします。</li>
        <li>ユーザーは、本サービスにおいて登録した情報の内容の真実性、正確性について一切の責任を負います。当社は、ユーザー自身が登録した登録情報を前提として、本サービスを提供いたします。登録情報の内容に虚偽、誤り等があったことによりユーザーに生じた損害について、当社は一切責任を負いません。  </li>
        <li>ユーザーは、登録事項に変更があった場合、当社所定の方法により当該変更事項を遅滞なく当社に通知し、常にユーザーが責任をもって正確な状態に保つものとします。</li>
        <li>当社は、以下の各号のいずれかの事由に該当する場合は、登録を拒否することができるものとします。</li>
        <li>当社に提供された登録情報の全部または一部につき、虚偽または誤りがあった場合</li>
        <li>未成年、成年被後見人、被補佐人または被補助人のいずれかであって、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合</li>
        <li>反社会的勢力等（第15条第1項に定義します。）であり、または資金提供その他を通じて反社会的勢力等の維持、運営または経営に協力または関与する等、反社会的勢力等との何らかの交流または関与を行っていると当社が判断した場合</li>
        <li>その他、当社が登録を適当でないと判断した場合</li>
      </ul>
      <p>6．前項２号について、本サービスを利用した時点で、法定代理人等の同意があったものとみなします。</p>

      <h2>第4条（本コンテンツ）</h2>
      <ul>
        <li>
          1. お客様は、当社に対して、以下の事項を表明し、保証するものとします。<br />
          (1)本コンテンツが、第三者の権利（著作権、著作者人格権、特許権、商標権、意匠権、実用新案権、営業秘密、名誉権、肖像権、プライバシー権、パブリシティー権を含みますが、これらに限りません。）を侵害しないこと<br />
          (2)本コンテンツが公序良俗に反するおそれのある内容（過度に暴力的な内容、露骨な性的内容を含みますが、これらに限りません。）、法令または本規約に違反する内容を含まないこと<br />
          (3)本コンテンツにURLリンクを含める場合、お客様がアクセス状況を集計可能であることを利用者が認識できるURLに限定するか、または利用者がそのように認識するために必要な措置を行うこと
        </li>
        <li>2. 第三者に関する情報を本コンテンツに記載する場合、利用者が当該第三者本人からの情報提供だと誤認しないよう、然るべき記載（例としては、情報の発信元の明示、本人ではないことの明示などがありますが、これらに限りません。）をしなければなりません。</li>
        <li>3. お客様は、本コンテンツの適正な運営のため、当社が本コンテンツの内容を確認する場合があることに同意します。当社は、本コンテンツに不適切な内容が含まれていると判断した場合、当該本コンテンツを配信せずまたは削除することができます。</li>
        <li>4. 当社は、裁判所、捜査機関、その他の公的機関から、本コンテンツを開示・提供するよう請求され、当社がかかる請求が正当であると判断した場合、お客様の承諾を得ることなく、本コンテンツを開示・提供することができるものとします。</li>
        <li>5. 本コンテンツの著作権を含む知的財産権は、お客様に帰属するものとします。ただし当社はこれらお客様の知的財産権を保護する義務は負わないものとします。</li>
        <li>6. 当社は、本コンテンツを当社のサーバに保存できるものとします。なお、当社は本コンテンツの保存義務は一切負わないものとし、お客様は、本コンテンツの保存が必要と考える場合には、自らの費用と責任において本コンテンツの保存を行うものとします。</li>
      </ul>

      <h2>第5条（禁止事項・損害賠償）</h2>
      <ul>
        <li>ユーザーは、本サービスの利用にあたり、自らまたは第三者をして、以下の各行為を行ってはならないものとします。違反した場合、利用停止、損害賠償等、当社が必要と判断した措置を取ることができます。</li>
        <li>当社に対し虚偽の情報を登録、または提供すること</li>
        <li>第三者に成りすます行為</li>
        <li>他人の知的財産権、プライバシーに関する権利、その他の権利または利益を侵害する行為</li>
        <li>コンピューター・ウイルスその他の有害なコンピューター・プログラムを含む情報を送信する行為</li>
        <li>当社のネットワークまたはシステム等に過度な負担をかける行為</li>
        <li>当社のネットワークまたはシステム等への不正アクセス</li>
        <li>当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為</li>
        <li>個人や団体を誹謗中傷する行為</li>
        <li>当社、他のユーザーその他の第三者に不利益、損害、不快感を与える行為</li>
        <li>本サービスの運営を妨げる行為</li>
        <li>本サービスで得た情報を本サービスの利用目的の範囲を超えて第三者に譲渡する行為または営利目的で譲渡する行為</li>
        <li>公序良俗に反する行為</li>
        <li>法令に反する一切の行為または犯罪行為に関連する行為</li>
        <li>当社からユーザーに提供する、一般には公開されていない本サービスの情報について、ユーザーが第三者へ提供や開示をする行為</li>
        <li>前各項に該当するおそれのある行為</li>
        <li>その他当社が不適切と判断する一切の行為ユーザーが前項に違反し、当該ユーザーに損害が発生した場合であっても、当社は何ら責任を負わないものとします。</li>
      </ul>

      <h2>第6条（情報の変更・削除、解除等）</h2>
      <p>当社は、ユーザーが本規約第4条第1項各号の行為を含む本規約に違反する行為をし、または、その行為をする恐れがある場合には、当該ユーザーに何ら事前の通知をすることなく以下の措置を講じることができるものとします。</p>
      <ul>
        <li>ユーザーが本サービスにおいて登録した情報の全部または一部についての変更または削除</li>
        <li>本契約の解除およびそれに伴う本サービス利用の停止、または、本サービスのユーザーとしての登録の抹消</li>
        <li>その他当社が必要と認める措置</li>
      </ul>

      <h2>第7条（提供情報の利用）</h2>
      <ul>
        <li>本サービスにおける個人情報の取り扱いについては、当社の定める「個人情報保護方針」に従うものとします。ユーザーは本サービスを利用する場合には「個人情報保護方針」の各規定に従うことを承認したものとみなします。</li>
        <li>当社は、本サービスを提供する上でユーザーにとって必要な情報を、ユーザーに対し、Eメール、郵便、電話、対面での伝達等によって連絡をすることができるものとします。</li>
      </ul>

      <h2>第8条（ユーザーへのアンケート等）</h2>
      <ul>
        <li>当社は、ユーザーに対し、本サービスおよびその利用に関するアンケートを実施し、体験記の執筆を依頼（以下、あわせて「アンケート等」といいます。）することがあります。</li>
        <li>ユーザーは、前項の場合に、自らの責任に基づいて情報や意見等を提供するものとし、自己の提供する情報や意見等については、当社に対し真実性、正確性を保証するものとします。</li>
        <li>当社は、アンケート等の結果につき、当社が編集発行する情報媒体、または、本サイトを含む当社が運営管理するウェブサイト上に、ユーザーの事前の承諾なくして、個人情報を含まない形で、編集、転載、および削除することができます。この場合、転載したアンケート等の結果に関する著作権（著作権法第２７条および第２８条の権利を含む、）はすべて当社に帰属し、ユーザーは、当社に対し、著作者人格権を行使しないものとします。  </li>
      </ul>

      <h2>第9条（事業者等からの連絡）</h2>
      <ul>
        <li>ユーザーは、本サービスにおいて登録した情報および活動情報にもとづき、事業者等から諸連絡を受け取る場合があることを承諾するものとします。</li>
        <li>当社は、本サービスの運営状況の把握や統計データ等の作成のため、ユーザーと事業者等との間で送受信されるメッセージの送受信履歴およびその内容を閲覧、利用することがあることとし、ユーザーはこれをあらかじめ承諾するものとします。
        </li>
      </ul>

      <h2>第10条（サービス内容の変更・終了）</h2>
      <ul>
        <li>当社は、ユーザーの事前の承諾なしに本サービスの内容の全部もしくは一部を変更または追加することができるものとします。</li>
        <li>当社は、事前に、本サービス上または本サイトを含む当社の運営するウェブサイト上への掲載その他の当社が適当と判断する方法でユーザーに通知することにより、当社の裁量で、本サービスの全部または一部の提供・運営を終了することができるものとします。  </li>
      </ul>

      <h2>第11条（サービスの停止・中断）</h2>
      <ul>
        <li>当社は、以下のいずれかに該当する事由によりユーザーへの事前の通知および承諾を要することなく、本サービスの全部または一部を停止または中断することができます。</li>
        <li>本サービス運営のためのシステム（以下、「本システム」といいます）の保守、点検、更新等を定期的または臨時に行う場合</li>
        <li>ユーザーのセキュリティを確保する必要が生じた場合</li>
        <li>ウイルス被害、火災、停電、天災地変などの不可抗力により、本サービスの提供が困難な場合</li>
        <li>コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合</li>
        <li>第三者の故意または過失による行為によって発生した本システムの不具合について対策を講じる必要がある場合 5.その他、不測の事態により、当社が本サービスの提供が困難と判断した場合</li>
        <li>法令またはこれらに基づく措置により本サービスの運営が不能となった場合</li>
        <li>当社は、本条に基づき当社が行った措置によりユーザーに生じた損害について一切の責任を負いません。</li>
      </ul>

      <h2>第12条（権利の帰属）</h2>
      <ul>
        <li>本サービスにおいて当社が提供するコンテンツまたは第三者コンテンツ等の情報等に関する一切の知的財産権は、当社または当社にライセンスを許諾している者に帰属し、本規約に基づく本サービスのいかなる使用許諾も、当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。  </li>
        <li>ユーザーは、当社の許諾を得ることなく、当社が提供する情報等の翻案、編集または改変等を行い、これを第三者に使用させ、または公開することはできず、いかなる理由によっても当社または当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為をしてはなりません｡  </li>
        <li>本サービス上、商標、ロゴ及びサービスマーク等（以下総称して「商標等」といいます。）が表示される場合がありますが、当社は、ユーザーその他の第三者に対し何ら商標等を譲渡し、またはその使用を許諾するものではありません。</li>
      </ul>

      <h2>第13条（免責）</h2>
      <ul>
        <li>当社は、事業者等の第三者の情報、広告その他第三者により提供される情報、ユーザー等が本サービスに登録し掲載する情報等に関し、内容の正確性、有用性等について何らの保証もしないものとします。</li>
        <li>当社は、ユーザーによる本サービスの利用によって本サービスがユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、継続的に利用できること、および不具合が生じないことについて保証しないものとします。  </li>
        <li>当社は、ユーザーの本サービスへの登録および本サービスの利用から生じる一切の損害に関して、責任を負わないものとします。</li>
        <li>当社は、当社による本サービスの提供の中断、停止、利用不能または変更、ユーザーの情報の削除または消失、ユーザーの登録の抹消、本サービスの利用によるデータの消失または機器の故障若しくは損傷、その他本サービスに関連してユーザーが被った損害につき、一切責任を負わないものとします。  </li>
        <li>前二項の定めは、本契約に消費者契約法の適用がある場合には、適用しないものとします。</li>
        <li>本サイトから他のウェブサイトへのリンクまたは他のウェブサイトから本サイトへのリンクが提供されている場合でも、当社は、本サイト以外のウェブサイトおよびそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。  </li>
        <li>当社が本サービスに関してユーザーに対して損害賠償責任を負うべき場合でも、当社の責任はユーザーに現実に発生した直接かつ通常の損害に限るものとし、ユーザーの逸失利益、間接損害、特別損害等については損害賠償責任を負わないものとします。また、損害賠償の上限は、当該損害発生事由にかかる個別契約に定める委託料相当額であって現実に支払われた額を限度とします。但し、本契約に消費者契約法が適用され、かつ当社に故意または重過失がある場合にはこの限りではありません。  </li>
        <li>ユーザーは、本サービスの利用に関連し、他の会員に損害を与えた場合または第三者との間に紛争を生じた場合、自己の費用と責任において、かかる損害を賠償またはかかる紛争を解決するものとし、当社には一切の迷惑や損害を与えないものとします。
        </li>
        <li>前項のユーザーの行為により、第三者から当社が損害賠償等の請求をされた場合には、会員の費用（弁護士費用）と責任で、これを解決するものとします。</li>
      </ul>

      <h2>第14条（損害賠償請求）</h2>
      <ul>
        <li>ユーザーが当社または第三者に対し損害を与えた場合、当該ユーザーは当社または第三者に対し、損害賠償義務を負うものとします。</li>
        <li>当社は、本サービスに関連してユーザーが被った損害について、一切賠償の責任を負いません。なお、消費者契約法の適用その他の理由により、本項その他当社の損害賠償責任を免責する規定にかかわらず当社がユーザーに対して損害賠償責任を負う場合においても、当社の賠償責任の範囲は、当社の責に帰すべき事由により現実に発生した直接かつ通常の損害に限られるものとします  </li>
      </ul>

      <h2>第15条（反社会的勢力の排除）</h2>
      <ul>
        <li>ユーザーは、現在、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力団等、その他これらに準ずる者（以下、これらを「反社会的勢力等」といいます。）に該当しないこと、および次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。  </li>
        <li>反社会的勢力等が経営を支配していると認められる関係を有すること</li>
        <li>反社会的勢力等が経営を実質的に関与していると認められる関係を有すること</li>
        <li>自己もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に反社会的勢力等を利用していると認められる関係を有すること</li>
        <li>反社会的勢力等に対して資金等を提供し、または便宜を供与するなどの関与をしていると認められる関係を有すること</li>
        <li>役員または経営に実質的に関与している者が反社会的勢力等と社会的に非難されるべき関係を有すること</li>
        <li>ユーザーは、自らまたは第三者を利用して次の各号の一にでも該当する行為を行わないことを確約するものとします。</li>
        <li>暴力的な要求行為</li>
        <li>法的な責任を超えた不当な要求行為</li>
        <li>取引に関して、脅迫的な言動をし、または暴力を用いる行為</li>
        <li>風説を流布し、偽計を用いまたは威力を用いて、当社、他の利用者、その他第三者の信用を毀損し、または、当社、他の利用者、その他第三者の業務を妨害する行為</li>
        <li>その他前各号に準ずる行為</li>
        <li>ユーザーが前二項に違反した場合、当社は何らの催告なしに本契約を解除できるものとします。</li>
      </ul>

      <h2>第16条（有効期間）</h2>
      <p>本契約は、本サービスの提供期間中であって、ユーザーについて第３条に基づく利用登録が完了した日からイベント当日までの間、当社とユーザーとの間で有効に存続するものとします。</p>

      <h2>第17条（規約の変更・追加）</h2>
      <ul>
        <li>当社は、ユーザーの承諾を得ることなく、本規約を必要に応じて変更または追加することがあります。</li>
        <li>当社が前項に基づき本規約の内容の変更または追加を行う場合、変更後の本規約の施行時期および内容を本サイト上での掲示その他の適切な方法により周知し、またはユーザーに通知するものとします。</li>
      </ul>

      <h2>第18条（本規約上の権利義務の譲渡等）</h2>
      <ul>
        <li>ユーザーは、当社の書面による事前の承諾なく、本規約に基づく権利または義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることや、第三者に相続させることはできません。</li>
        <li>当社が本サービスにかかる事業を第三者に譲渡する場合には、ユーザーの承諾を得ることなく、当該事業譲渡に伴い、本規約に基づく権利および義務並びにユーザーの登録情報その他の顧客情報等を含む本契約上の地位を当該事業譲渡の譲受人に譲渡することができるものとします。なお、このことは、事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合においても同様とします。  </li>
      </ul>

      <h2>第19条（分離可能性）</h2>
      <p>
        本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定、およびその一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社およびユーザーは、当該無効若しくは執行不能の条項または部分を適法とし、執行力をもたせるために必要な範囲で修正し、当該無効若しくは執行不能な条項または部分の趣旨並びに法律的および経済的に同等の効果を確保できるように努めるものとします。
      </p>

      <h2>第20条（準拠法および管轄裁判所）</h2>
      <p>本サービスおよび本規約を含む本契約の準拠法は日本法とします。本サービスおよび本規約を含む本契約に関して生じる一切の紛争については、(東京地方裁判所または東京簡易裁判所)を第１審の専属的合意管轄裁判所とします。</p>

      <h2>第21条（付則）</h2>
      <p>本規約は2020年5月12日より実施します。</p>
    </FormattedText>
  </Outer>
}