import React from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { GQLNewsPost } from '../../generated/graphql';
import { formatDate, formatDateTime } from '../../utils/format-date';
import { PartialRequired } from '../../utils/type-helper';

const Outer = styled.div`
  max-width: 40rem;
  box-sizing: border-box;
  padding: 1rem;
  margin: 0 auto;
`

const Title = styled.div`
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 700;
  text-align: justify;
  text-align-last: left;
  margin: calc(((1.5rem * 1.5) - 1.5rem) / 2 * -1) 0;
`

const Content = styled.div`
  margin-top: 2rem;
  line-height: 1.5;
`

const Date = styled.div`
  margin-top: 2rem;
  font-size: 0.8rem;
  color: #888;
  font-weight: 700;
  text-align: right;
`

type NewsProps = {
  newsPost: PartialRequired<GQLNewsPost, 'id' | 'title' | 'content' | 'createdAt'>
}
export const NewsPost: FunctionComponent<NewsProps> = ({newsPost}) => {
  return <Outer>
    <Title>
      {newsPost.title}
    </Title>
    <Content dangerouslySetInnerHTML={{__html: newsPost.content}}>
    </Content>
    <Date>
      {formatDate(newsPost.createdAt)}
    </Date>
  </Outer>
}