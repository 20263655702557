import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { FormattedText } from '../molecules/format'

const Outer = styled.div`
  padding: 0 1rem;
  max-width: 50rem;
  margin: 0 auto;
`

export const Tokusho: FunctionComponent = () => {
  return <Outer>
    <FormattedText>
    <h1>特定商取引法に基づく表記</h1>

    <h2>販売事業者名</h2>
    <p>株式会社クルム</p>

    <h2>代表責任者</h2>
    <p>佐藤祐司</p>

    <h2>所在地</h2>
    <p>〒252-0029 神奈川県座間市入谷西5-53-10-303</p>

    <h2>メールアドレス</h2>
    <p>info@kurum.jp</p>

    <h2>販売価格について</h2>
    <p>販売価格は、購入手続きの際に画面に表示された金額（表示価格/消費税込/送料込）と致します。発送は国内のみとなります。</p>

    ​<h2>販売価格以外に発生する金額</h2>
    <p>当サイトのページ閲覧、コンテンツ購入、ソフトエアのダウンロード等に必要となるインターネット接続料金、通信料金などはお客様負担となります。それぞれの料金は、お客様がご利用のインターネットプロバイダまたは携帯電話会社にお問い合わせください。</p>

    <h2>支払い時期</h2>
    <p>イベント申し込み時</p>
    サブスクリプションの場合、初回申込時および次月以降、申込日より１ヶ月後毎

    <h2>引き渡し時期 </h2>
    <p>イベント開始時</p>
    サブスクリプションの場合、入会申し込み日から3日後迄に入会審査をいたします。

    <h2>お支払方法 </h2>
    <p>クレジットカード</p>

    <h2>返品・交換・キャンセル等 </h2>
    <p>申し込み後の返金は原則不可</p>
    </FormattedText>
  </Outer>
}