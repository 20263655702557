import React from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const Br = styled.br`
  display: none;
  @media (max-width: 40rem) {
    display: inline;
  }
`

export const Mbr: FunctionComponent = () => {
  return <Br />
}