import * as React from "react";

function SvgAccountTabClipBoardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.23 21.021"
      {...props}
    >
      <path
        d="M15.73 21.021H1.5A1.5 1.5 0 010 19.523V1.5A1.5 1.5 0 011.499 0H15.73a1.5 1.5 0 011.499 1.499v18.023a1.5 1.5 0 01-1.5 1.5zM1.5.753a.746.746 0 00-.746.746v18.023a.747.747 0 00.746.746H15.73a.747.747 0 00.746-.746V1.5a.746.746 0 00-.746-.746z"
        fill="currentcolor"
      />
      <path
        d="M13.4 3.36H3.83A1.5 1.5 0 012.33 1.861V1.5A1.5 1.5 0 013.829 0H13.4A1.5 1.5 0 0114.9 1.499v.362a1.5 1.5 0 01-1.5 1.499zM3.83.753a.747.747 0 00-.746.746v.362a.747.747 0 00.746.746H13.4a.746.746 0 00.746-.746V1.5A.746.746 0 0013.4.753zM7.485 14.7a.376.376 0 01-.267-.11l-2.684-2.684a.377.377 0 01.533-.533l2.418 2.418 5.26-5.261a.377.377 0 01.533.533L7.751 14.59a.377.377 0 01-.266.11z"
        fill="currentcolor"
      />
    </svg>
  );
}

export default SvgAccountTabClipBoardIcon;
