import styled from 'styled-components';
import React, { FunctionComponent, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'querystring';
import { useRequestPasswordResetMutation, useResetPasswordMutation } from '../../generated/graphql';
import { LoginButtonOuter, SigninOuter, SigninText, SignupLink } from '../organisms/signin';
import { H1 } from '../atoms/h1';
import { TextInputBlock } from '../molecules/text-Input-block';
import { Button } from '../atoms/button';
import { useErrorModal } from '../../hooks/use-error-modal';

const PasswordResetPageOuter = styled.div``

export const PasswordResetPage: FunctionComponent = () => {
  const location = useLocation()
  const qs = parse(location.search.replace(/^\?/, ''))
  
  return <PasswordResetPageOuter>
    {
      typeof qs.token === 'string' ? 
        <PasswordReset token={qs.token} /> :
        <RequestPasswordReset />
    }
  </PasswordResetPageOuter>
}

const RequestPasswordReset: FunctionComponent = () => {
  const [requestPasswordReset] = useRequestPasswordResetMutation()
  const [email, setEmail] = useState('')
  const [complete, setComplete] = useState(false)

  if(complete) return <SigninOuter>
    <SigninText>{email}へパスワードリセット用のメールを送信いたしました。メールをご確認ください。</SigninText>
  </SigninOuter>

  return <SigninOuter>
    <H1>パスワードリセット</H1>
    <TextInputBlock type="email" value={email} onChange={e => setEmail(e.currentTarget.value)}>Email</TextInputBlock>
    <LoginButtonOuter>
      <Button onClick={async () => {
      await requestPasswordReset({
        variables: {
          email
        }
      })
      setComplete(true)
      }}>パスワード変更用のメールを送信する</Button>
    </LoginButtonOuter>
  </SigninOuter>
}

const PasswordReset: FunctionComponent<{token: string}> = ({token}) => {
  const [resetPassword] = useResetPasswordMutation()
  const [password, setPassword] = useState('')
  const [complete, setComplete] = useState(false)
  const {open} = useErrorModal()

  if(complete) return <SigninOuter>
    <SigninText>パスワードを変更いたしました。<SignupLink to="/signin">こちら</SignupLink>からログインしてください</SigninText>
  </SigninOuter>

  return <SigninOuter>
    <H1>パスワードリセット</H1>
    <TextInputBlock type="password" value={password} onChange={e => setPassword(e.currentTarget.value)} />
    <LoginButtonOuter>
      <Button onClick={async () => {
      try {
        const res = await resetPassword({
          variables: {
            password,
            token
          }
        })
        if(res.errors){
          throw res.errors
        }
      } catch (err) {
        open(err)
        return
      }
      setComplete(true)
    }}>パスワードを変更する</Button>
    </LoginButtonOuter>
  </SigninOuter>
}