import { PaymentIntent } from '@stripe/stripe-js'
import React from 'react'
import { PaymentModal } from '../components/molecules/payment-modal'
import { useModal } from './use-modal'

export const usePaymentModal = () => {
  const {open} = useModal()
  return {
    open: async (options: {paymentIntentClientSecret: string, amount: number}) => {
      return open<PaymentIntent>(({resolve, reject}) => <PaymentModal paymentIntentClientSecret={options.paymentIntentClientSecret} amount={options.amount} onClose={reject} onComplete={resolve} />)
    }
  }
}