import * as React from "react";

function SvgUpArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31.801 30.92"
      {...props}
    >
      <path
        fill="currentcolor"
        d="M9.401 14.985V30.92h13V14.985h9.4L15.901 0 0 14.985h9.401z"
      />
    </svg>
  );
}

export default SvgUpArrow;
