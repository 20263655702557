import React, { useState } from 'react'
import { FunctionComponent } from 'react'
import { useError } from 'react-use'
import styled from 'styled-components'
import { GQLJob, GQLJobStatus, useApplyForJobMutation, useCancelApplyForJobMutation } from '../../generated/graphql'
import { useErrorModal } from '../../hooks/use-error-modal'
import { useMessageModal } from '../../hooks/use-message-modal'
import { PartialRequired } from '../../utils/type-helper'

type ApplyForJobButtonProps = {
  job: PartialRequired<GQLJob, 'status' | 'assigned' | 'id' | 'own'>
}
const Outer = styled.div`
  padding: 1.25rem 2rem;
  font-weight: 700;
  text-align: center;
  border-radius: 0.25rem;
  cursor: pointer;
`

const EnabledOuter = styled(Outer)`
  background: #70a52e;
  color: white;
`

const DisabledOuter = styled(Outer)`
  background-color: #c8c8c8;
  color: #666666;
`
export const ApplyForJobButton: FunctionComponent<ApplyForJobButtonProps> = ({job}) => {
  const [applyForJob] = useApplyForJobMutation()
  const [cancelApplyForJob] = useCancelApplyForJobMutation()
  const [isHover, setIsHover] = useState(false)
  const {open: openMessageModal} = useMessageModal()
  const {open: openErrorModal} = useErrorModal()

  if(job.own) {
    return null
  }

  if(job.status !== GQLJobStatus.Open){
    return <DisabledOuter>
      応募受付を終了しました
    </DisabledOuter>
  } else {
    if(job.applied){
      return <DisabledOuter
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={() => {
          cancelApplyForJob({
            variables: {
              jobId: job.id
            }
          })
        }}
      >
        {isHover ? '応募を取り消す' : '応募済み'}
      </DisabledOuter>
    } else {
      return <EnabledOuter onClick={async () => {
        try {
          await applyForJob({
            variables: {
              jobId: job.id
            }
          })
          await openMessageModal('応募が完了しました')
        } catch(err) {
          openErrorModal(err)
        }
      }}>
        この依頼に応募する
      </EnabledOuter>
    }
  }
}