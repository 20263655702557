import styled, { css } from 'styled-components'
import React, { FunctionComponent } from 'react'
import { Paginate, PaginateProps, LinkBuilderArguments } from './paginate'
import { Link, LinkProps } from 'react-router-dom'
import { PaginatorLeftArray } from '../../generated/svg'

const Outer = styled.div`
  display: flex;
  align-items: center;
`

const PaginateOuter = styled.div`
  margin: 0 2rem;
`

const PageLink = styled(({active, ...props}: {active: boolean} & LinkProps) => <Link {...props} />)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.9rem;
  margin: 0.25rem;
  font-weight: bold;
  color: #959595;
  box-sizing: border-box;
  ${({active}) => active && css`
    border: 1px solid var(--accent-color, #888);
    color: var(--accent-color, #888);
  `}
`

const Spread = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  margin: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`


const NavigateLink = styled(({active, ...props}: {active: boolean} & LinkProps) => <Link {...props} />)<{active: boolean}>`
  display: flex;
  align-items: center;
  ${({active}) => !active && css`
    filter: saturate(0) brightness(1.75);
    pointer-events: none;
  `}
`

const NavigateLinkIconOuter = styled.div`
  width: 1rem;
`

const ReverseNavigateLinkIconOuter = styled(NavigateLinkIconOuter)`
  transform: scaleX(-100%);
`

const NavigateLinkLabel = styled.div`
  margin: 0 1rem;
  font-weight: bold;
  color: var(--accent-color, #888);
  font-size: 0.9rem;
`

export type CardPaginateProps = Pick<PaginateProps, 'pageCount' | 'currentPage'> & {
  urlGenerator: (args: {page: number}) => LinkProps['to']
}
export const CardPaginate: FunctionComponent<CardPaginateProps> = ({urlGenerator, ...props}) => {
  const isActivePrevLink = props.currentPage > 1
  const isActiveNextLink = props.currentPage < props.pageCount
  return <Outer>
    <NavigateLink to={urlGenerator({page: props.currentPage - 1})} active={isActivePrevLink}>
      <NavigateLinkIconOuter>
        <PaginatorLeftArray style={{color: isActivePrevLink ? 'var(--accent-color, #888)' : '#959595'}} />
      </NavigateLinkIconOuter>
      <NavigateLinkLabel>Previous</NavigateLinkLabel>
    </NavigateLink>
    <PaginateOuter>
      <Paginate
        {...props}
        linkBuilder={({active, page}) => <PageLink to={urlGenerator({page})} active={active}>{page}</PageLink>}
        spreadBuilder={() => <Spread>…</Spread>}
      />
    </PaginateOuter>
    <NavigateLink to={urlGenerator({page: props.currentPage + 1})} active={isActiveNextLink}>
      <NavigateLinkLabel>Next</NavigateLinkLabel>
      <ReverseNavigateLinkIconOuter>
        <PaginatorLeftArray style={{color: isActiveNextLink ? 'var(--accent-color, #888)' : '#959595'}} />
      </ReverseNavigateLinkIconOuter>
    </NavigateLink>
  </Outer>
}