import styled from 'styled-components'

export const FormattedText = styled.div`
  line-height: 1.5;
  font-size: 0.9rem;
  h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
    margin-top: 3rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
  }

  ul {
    margin-top: 1rem;
  }

  li {
    margin-left: 1em;
    list-style-type: disc;
  }
  li + li {
    margin-top: 0.5rem;
  }
`