import React from 'react'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import SvgAccountTabCameraIcon from '../../generated/svg/AccountTabCameraIcon'
import SvgAccountTabClipBoardIcon from '../../generated/svg/AccountTabClipBoardIcon'
import SvgAccountTabHandDollerIcon from '../../generated/svg/AccountTabHandDollerIcon'

type TabName = 'transaction' | 'addJob' | 'registerPhotographer'

const Outer = styled.div`
  padding: 0 1rem;
  display: flex;
`

type TabProps = {
  active: boolean
}
const Tab = styled(({active, ...props}) => <Link {...props} />)<TabProps>`
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 0.5rem 0.5rem 0 0;
  --tab-text-color: ${({active}) => active ? 'var(--accent-color)' : '#333333'};
  background-image: ${({active}) => active ? 'none' : 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, 0.1))'};
  & + & {
    margin-left: 0.5rem;
  }
  @media (max-width: 40rem){
    padding: 0.25rem 0.25rem;
    line-height: 1.5;
  }
`
const TabIconContainer = styled.div`
  height: 1.25rem;
  margin-right: 0.5rem;
  font-weight: bold;
  color: var(--tab-text-color);
  svg {
    height: 100%;
    width: auto;
  }
`
const TabLabel = styled.div`
  color: var(--tab-text-color);
  font-size: 0.9rem;
`

type AccountPageTabsProps = {
  active?: TabName
}
export const AccountPageTabs: FunctionComponent<AccountPageTabsProps> = ({active}) => {
  return <Outer>
    <Tab to="/account/transactions" active={active === 'transaction'}>
      <TabIconContainer>
        <SvgAccountTabHandDollerIcon />
      </TabIconContainer>
      <TabLabel>取引・支払い履歴</TabLabel>
    </Tab>
    <Tab to="/account/add-job" active={active === 'addJob'}>
      <TabIconContainer>
        <SvgAccountTabClipBoardIcon />
      </TabIconContainer>
      <TabLabel>依頼を登録する</TabLabel>
    </Tab>
    <Tab to="/account/register-photographer" active={active === 'registerPhotographer'}>
      <TabIconContainer>
        <SvgAccountTabCameraIcon />
      </TabIconContainer>
      <TabLabel>カメラマンを登録する</TabLabel>
    </Tab>
  </Outer>
}
