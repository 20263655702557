import { PartialRequired } from '../../utils/type-helper';
import { GQLJob } from '../../generated/graphql';
import { Card } from './card';
import { parseISO, format } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { Person } from '../../generated/svg';

type JobCardProps = {
  job: PartialRequired<GQLJob, 'id' | 'name' | 'deadline' | 'fee'>
}
export const JobCard: FunctionComponent<JobCardProps> = ({job}) => {
  return <Card
    image={{url: '', width: 0, height: 0}}
    name={job.name}
    description={format(parseISO(job.deadline), 'yyyy年MM月dd日まで')}
    price={job.fee}
    icon={<Person style={{color: '#e2e2e2'}} />}
    link={`/jobs/${job.id}`}
  />
}