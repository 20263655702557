import * as React from "react";

function SvgProfileImageProxy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 70.5 70.5"
      {...props}
    >
      <path
        d="M54.523 49.148c-1.88-1.805-4.939-1.597-7.333-2.804-1.594-.806-3.133-1.775-4.721-2.628a13.378 13.378 0 01-3.111 1.616l-4.216 8.654-4.189-8.654a13.375 13.375 0 01-3.11-1.616c-1.59.853-3.128 1.822-4.722 2.628-2.394 1.207-5.454 1-7.332 2.804-4.015 3.862-8.341 9.964-7.46 21.352h53.654c.88-11.388-3.446-17.49-7.46-21.352z"
        fill="currentcolor"
      />
      <path
        d="M35.156 44.766c7.319 0 13.256-7.142 13.256-15.95s-5.937-15.95-13.256-15.95-13.257 7.141-13.257 15.95 5.938 15.95 13.257 15.95z"
        fill="currentcolor"
      />
    </svg>
  );
}

export default SvgProfileImageProxy;
