import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { useChangePasswordMutation } from '../../generated/graphql';
import { H1 } from '../atoms/h1';

const ChangePasswordPageOuter = styled.div``

export const ChangePasswordPage: FunctionComponent = () => {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [changePassword] = useChangePasswordMutation()
  return <ChangePasswordPageOuter>
    <H1>Change password</H1>
    <div>
      Current password:
      <input type="password" value={currentPassword} onChange={e => setCurrentPassword(e.currentTarget.value)} />
    </div>
    <div>
      New password:
      <input type="password" value={newPassword} onChange={e => setNewPassword(e.currentTarget.value)} />
    </div>
    <div>
      Confirm password:
      <input type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.currentTarget.value)}/>
    </div>
    <button onClick={async () => {
      if(newPassword !== confirmPassword){
        alert('Does not match confirm password.')
        return
      }
      const changePasswordResult = await changePassword({
        variables: {
          currentPassword,
          newPassword
        }
      })
      console.log(changePasswordResult.data)

    }}>Chanege password</button>

  </ChangePasswordPageOuter>
}