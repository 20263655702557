import React from 'react';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { GQLUser } from '../../generated/graphql';
import { Gear } from '../../generated/svg';
import { formatUserId } from '../../utils/format-user-id';
import { PartialRequired } from '../../utils/type-helper';
import { ProfileImage } from '../atoms/profile-image';

const Outer = styled.div`
  padding: 1rem;
  border-radius: 0.25rem;
  background-color: white;
`
const ProfileImageContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  @media (max-width: 40rem) {
    max-width: 12rem;
    margin-left: auto;
    margin-right: auto;
  }
`
const DetailTable = styled.div``
const DetailTableItem = styled.div`
  display: flex;
  padding: 0.75rem;
  align-items: center;
  & + & {
    border-top: 1px solid #c8c8c8;
  }
`

const DetailTableItemLabel = styled.div`
  font-size: 0.75rem;
  margin-right: 0.5rem;
`

const DetailTableItemValue = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
`

const Email = styled.div`
  padding: 0.75rem;
  text-align: center;
  border-top: 1px solid #c8c8c8;
`

const EditButton = styled(Link)`
  margin-top: 1rem;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  background-color: #e2e2e2;
  border-radius: 0.25rem;
  justify-content: center;
  svg {
    display: block;
  }
`

const EditButtonIconContainer = styled.div`
  width: 1.5rem;
  margin-right: 0.5rem;
`

const EditButtonLabel = styled.div`
  color: #4d4d4d;
`
const PaymentButton = styled.div``

type ProfileCardProps = {
  user: PartialRequired<GQLUser, 'id' | 'name' | 'screenName' | 'email'>
}

export const ProfileCard: FunctionComponent<ProfileCardProps> = ({user: user}) => {
  return <Outer>
    <ProfileImageContainer>
      <ProfileImage profileImage={user.profileImage} square/>
    </ProfileImageContainer>
    <DetailTable>
      <DetailTableItem>
        <DetailTableItemLabel>
          管理ID:
        </DetailTableItemLabel>
        <DetailTableItemValue>
          {formatUserId(user.id)}
        </DetailTableItemValue>
      </DetailTableItem>
      <DetailTableItem>
        <DetailTableItemLabel>
          ユーザーID:
        </DetailTableItemLabel>
        <DetailTableItemValue>
          {user.name}
        </DetailTableItemValue>
      </DetailTableItem>
      <DetailTableItem>
        <DetailTableItemLabel>
          ユーザー名:
        </DetailTableItemLabel>
        <DetailTableItemValue>
          {user.screenName}
        </DetailTableItemValue>
      </DetailTableItem>
    </DetailTable>
    <Email>{user.email}</Email>
    <EditButton to='/account/profile'>
      <EditButtonIconContainer>
        <Gear />
      </EditButtonIconContainer>
      <EditButtonLabel>
        登録情報を編集
      </EditButtonLabel>
    </EditButton>
    <EditButton to='/account/payment-request'>
      <EditButtonLabel>
        振り込み申請
      </EditButtonLabel>
    </EditButton>
    <EditButton to='/logout'>
      <EditButtonLabel>
        ログアウト
      </EditButtonLabel>
    </EditButton>
  </Outer>
}