import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { GQLJobStatus, useJobApplicationsByUserNameQuery, useJobsQuery } from '../../generated/graphql'
import SvgCameraIcon from '../../generated/svg/CameraIcon'
import SvgPerson from '../../generated/svg/Person'
import { useUserStatus } from '../../hooks/use-user-status'
import { isNullish } from '../../utils/type-check'
import { AccountPageTabs } from '../molecules/account-page-tabs'
import { AccountPageOuter, AccountPageTabContainer, AccountPageHead, AccountPageHeadIconContainer, AccountPageHeadLabelContainer, AccountPageHeadLabel, AccountPageHeadLabelEnglish } from './account-page-parts'
import { FormContainer } from './form-container'

const TransactionContainer = styled.div`
  & + & {
    margin-top: 4rem;
  }
`

const TransactionHead = styled.div`
  font-weight: 700;
  margin-bottom: 0.5rem;
`

const TransactionDetail = styled.div`
  color: #888;
  font-size: 0.85em;
  margin-bottom: 1rem;
`

const TransactionList = styled.div`
  margin-left: 1rem;
`

const TransactionItem = styled(Link)`
  display: block;
  padding: 0.5rem 0;
  & + & {
    border-top: 1px solid #ccc;
  }
`

const NoJobs = styled.div`
  color: #ccc;
`



export const TransactionsTab: FunctionComponent = () => {
  const {profile, signedIn} = useUserStatus()
  if(isNullish(profile)) return null
  const jobsQuery = useJobsQuery({
    variables: {
      userNames: [profile.name]
    }
  })
  const myJobsQuery = useJobsQuery({
    variables: {
      photographerNames: [profile.name]
    }
  })
  const jobApplicationsQuery = useJobApplicationsByUserNameQuery({
    variables: {
      userName: profile.name
    }
  })
  const jobs = jobsQuery.data?.jobs.items ?? []
  const myJobs = myJobsQuery.data?.jobs.items ?? []
  const jobApplications = jobApplicationsQuery.data?.jobApplications.filter(jobApplication => jobApplication.job.status === GQLJobStatus.Open) ?? []
  return <AccountPageOuter>
    <AccountPageTabContainer>
      <AccountPageTabs active="transaction" />
    </AccountPageTabContainer>
    <AccountPageHead>
      <AccountPageHeadIconContainer>
        <SvgPerson />
      </AccountPageHeadIconContainer>
      <AccountPageHeadLabelContainer>
        <AccountPageHeadLabel>取引一覧</AccountPageHeadLabel>
        <AccountPageHeadLabelEnglish>TRANSACTION LIST</AccountPageHeadLabelEnglish>
      </AccountPageHeadLabelContainer>
    </AccountPageHead>
    <FormContainer>
      <TransactionContainer>
        <TransactionHead>依頼</TransactionHead>
        <TransactionDetail>あなたが作成した依頼の詳細が確認できます</TransactionDetail>
        <TransactionList>
          {
            jobs.length > 0 ?
              jobs.map(job => {
                return <TransactionItem key={job.id} to={`/jobs/${job.id}`}>
                    <div>{job.name}</div>
                </TransactionItem>
              }):
              <NoJobs>依頼はありません</NoJobs>
          }
        </TransactionList>
      </TransactionContainer>
      <TransactionContainer>
        <TransactionHead>カメラマン</TransactionHead>
        <TransactionDetail>あなたがカメラマンとして担当している依頼の詳細が確認できます</TransactionDetail>
        <TransactionList>
          {
            myJobs.length > 0 ?
              myJobs.map(job => {
                return <TransactionItem key={job.id}  to={`/jobs/${job.id}`}>
                  <div>{job.name}</div>
                </TransactionItem>
              }):
              <NoJobs>依頼はありません</NoJobs>
          }
        </TransactionList>
      </TransactionContainer>
      <TransactionContainer>
        <TransactionHead>応募済みの依頼</TransactionHead>
        <TransactionDetail>あなたが現在応募している依頼の詳細を確認できます</TransactionDetail>
        <TransactionList>
          {
            jobApplications.length > 0 ?
              jobApplications.map(jobApplication => {
                return <TransactionItem key={jobApplication.jobId}  to={`/jobs/${jobApplication.job.id}`}>
                  <div>{jobApplication.job.name}</div>
                </TransactionItem>
              }):
              <NoJobs>依頼はありません</NoJobs>
          }
        </TransactionList>
      </TransactionContainer>
    </FormContainer>
  </AccountPageOuter>
}