import React from 'react'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { GQLDeliverable, useDeliverablesQuery } from '../../generated/graphql'
import { PartialRequired } from '../../utils/type-helper'
import { DeliverableItem } from './deliverable-item'

const Outer = styled.div`

`


const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  --item-margin: 0.75rem;
  margin: calc(var(--item-margin) * -1);
`

const ItemOuter = styled.div`
  flex: 0 0  25%;
  padding: var(--item-margin);
  box-sizing: border-box;
`
const ItemInner = styled.div`
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
`

type DeliverableListProps = {
  jobId: string
}

export const DeliverableList: FunctionComponent<DeliverableListProps> = ({jobId}) => {
  const {data} = useDeliverablesQuery({
    variables: {
      jobId
    }
  })
  return <Outer>
    <ItemContainer>
      {data?.deliverables.map(deliverable => {
        return <ItemOuter key={deliverable.id}>
          <ItemInner>
            <DeliverableItem deliverable={deliverable} />
          </ItemInner>
        </ItemOuter>
      })}
    </ItemContainer>
  </Outer>
}


