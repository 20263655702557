import { FunctionComponent } from 'react'
import React from 'react'
import styled from 'styled-components'
import { HorizontalLogo } from '../../generated/svg'
import { Navigator, NavigatorItem } from '../atoms/navigator'

const Outer = styled.div`
  display: flex;
  max-width: 60rem;
  margin: 2rem auto 0;
  align-items: center;
  flex-direction: column;
  @media (max-width: 40rem){
    flex-direction: column;
  }
`

const LogoOuter = styled.div`
  /* flex: 0 0 16rem; */
  max-width: 16rem;
  margin-bottom: 2rem;
  @media (max-width: 40rem){
    flex: 0 0 auto;
    max-width: 20rem;
  }
`

const NavigatorOuter = styled.div`
  flex: 1 1 0;
  display: flex;
  justify-content: flex-end;
`

const CopyrightOuter = styled.div`
  flex: 0 0 100%;
  box-sizing: border-box;
`

const Copyright = styled.div`
  text-align: center;
  font-size: 0.8rem;
  padding: 1rem;
  border-top: 1px solid #b1b1b1;
  margin-top: 2rem;
`

export const Footer: FunctionComponent = () => {
  return <Outer>
    <LogoOuter>
      <HorizontalLogo />
    </LogoOuter>　　　
    <NavigatorOuter>
      <Navigator>
        <NavigatorItem to="/tokusho">特定商取引法に基づく表記</NavigatorItem>
        <NavigatorItem to="/terms-of-service">利用規約</NavigatorItem>
        <NavigatorItem to="/privacy-policy">プライバシーポリシー</NavigatorItem>
        <NavigatorItem to="/studio-entry">スタジオ掲載のお問い合わせ</NavigatorItem>
      </Navigator>
    </NavigatorOuter>
    <CopyrightOuter>
      <Copyright>2021©︎THIRD WHEEL</Copyright>
    </CopyrightOuter>
  </Outer>
}
