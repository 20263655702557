import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { H1 } from '../atoms/h1';
import { useJobApplicationsByUserNameQuery, useProfileQuery } from '../../generated/graphql';
import { Job } from '../molecules/job';

const AccountEntriesPageOuter = styled.div``

export const AccountEntriesPage: FunctionComponent = () => {
  const profileQuery = useProfileQuery()
  const jobApplicationsQuery = useJobApplicationsByUserNameQuery({
    variables: {
      userName: profileQuery.data?.profile?.name!
    },
    skip: profileQuery.loading
  })
  return <AccountEntriesPageOuter>
    <H1>My Entries</H1>
    {
      jobApplicationsQuery.data?.jobApplications
        .filter(jobApplication => jobApplication.job.assigned)
        .map(jobApplication => <Job key={jobApplication.job.id} job={jobApplication.job}></Job>)
    }
  </AccountEntriesPageOuter>
}