import * as React from "react";

function SvgCameraIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 24.131"
      {...props}
    >
      <path
        d="M19.014 14.575a4.014 4.014 0 11-4.015-4.014 4.015 4.015 0 014.015 4.014z"
        fill="currentcolor"
      />
      <path
        d="M25.624 4.011H21.76l-.88-2.007A3.354 3.354 0 0017.814 0h-5.628a3.356 3.356 0 00-3.067 2.004l-.88 2.007H4.376A4.376 4.376 0 000 8.387v11.368a4.376 4.376 0 004.376 4.376h21.248A4.377 4.377 0 0030 19.755V8.387a4.377 4.377 0 00-4.376-4.376zM14.999 20.825a6.249 6.249 0 116.248-6.25A6.249 6.249 0 0115 20.825z"
        fill="currentcolor"
      />
    </svg>
  );
}

export default SvgCameraIcon;
