import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

export const Button = styled.div`
  padding: 1rem 2rem;
  border-radius: 0.25rem;
  background-color: var(--accent-color);
  text-align: center;
  color: #fff;
  cursor: pointer;
  display: block;
`