import * as React from "react";

function SvgSmallMagnifier(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.579 18.574"
      {...props}
    >
      <path
        d="M18.08 16.375l-1.699 1.699-4.36-4.36 1.726-1.671 4.333 4.333zM7.723.5A7.224 7.224 0 11.5 7.724 7.224 7.224 0 017.724.5zm-.067 2.047a5.228 5.228 0 015.228 5.228"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgSmallMagnifier;
