import React, { FunctionComponent } from 'react';
import { GQLProfileImage } from '../../generated/graphql';
import styled from 'styled-components';
import { DeepPartial } from '../../utils/type-utils';
import SvgProfileImageProxy from '../../generated/svg/ProfileImageProxy';

const ProfileImageOuter = styled.div<{square?: boolean}>`
  width: 100%;
  background-color: #eee;
  border-radius: ${({square}) => square ? '0' : '100%'};
  overflow: hidden;
  display: flex;
  color: #ccc;
  align-items: center;
  justify-content: center;
  position: relative;
`
const ProfileImageImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
const ProfileImageProxy = styled.canvas`
  width: 100%;
  height: auto;
`

const ProfileImagePlaceHolder = styled.div`
  color: #fff;
  font-size: 0.6rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface Image {
  url?: string,
  width?: number,
  height?: number
}

interface ProfileImageProps {
  profileImage?: Image | null
  square?: boolean
}
export const ProfileImage: FunctionComponent<ProfileImageProps> = ({profileImage, square}) => {
  if(profileImage){
    return <ProfileImageOuter square={square}>
      <ProfileImageImg src={profileImage.url} width="300" height="300" />
    </ProfileImageOuter>
  } else {
    return <ProfileImageOuter square={square}>
      <SvgProfileImageProxy />
    </ProfileImageOuter>
  }
}