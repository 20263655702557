import React from 'react'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Mbr } from '../atoms/mbr'

const Outer = styled.div`
  a {
    text-decoration: underline;
  }
`

const HeaderOuter = styled.div`
  background-color: #1b2f31;
  padding: 2rem;
  margin-bottom: 3rem;
`

const HeaderText = styled.div`
  line-height: 1.75;
  font-weight: 700;
  color: white;
  font-size: 1.5rem;
  text-align: center;
  @media (max-width: 40rem) {
    font-size: 1.25rem;
  }
`

const Title = styled.div`
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.5;
`

const Text = styled.div`
  line-height: 1.5;
  text-align: center;
  margin-top: 2rem;
`

const UsageContainer = styled.div`
  max-width: 60rem;
  margin: 0 auto;
  margin-top: 4rem;
`

const UsageOuter = styled.div`
padding: 0 1rem;
  & + & {
    margin-top: 6rem;
  }
`

const UsageHead = styled.div`
  margin-bottom: 3rem;
  display: flex;
  @media (max-width: 40rem) {
    flex-direction: column;
    align-items: center;
  }
`

const UsageTitle = styled.div`
  flex: 0 0 auto;
  @media (max-width: 40rem) {
    text-align: center;
  }
`

const UsageTitleLabel = styled.div`
  font-weight: 700;
  padding: 0.5rem 0;
  border-bottom: 1px solid #ccc;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
`

const UsageTitleDetail = styled.div`
  color: #1e753b;
  font-size: 0.9rem;
`

const UsageHeadButtonContainer = styled.div`
  flex: 1 1 0;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 40rem) {
    margin-top: 1rem;
    width: 100%;
  }
`

const UsageHeadButton = styled.div`
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  background-color: #1e753b;
  padding: 1rem;
  color: white;
  align-self: center;
  text-decoration: none !important;
  transition: opacity 0.4s;
  & + & {
    margin-left: 1rem;
  }
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 40rem) {
    flex-direction: column;
    text-align: center;
    flex: 1 1 0;
    padding: 0.75rem;
  }
`

const UsageButtonLabel = styled.div`
  font-size: 1.2rem;
  font-weight: 900;
  @media (max-width: 40rem) {
    font-size: 1rem;
  }
`

const UsageButtonSubLabel = styled.div`
  font-size: 0.8rem;
  margin-left: 1rem;
  @media (max-width: 40rem) {
    margin-top: 0.5rem;
    margin-left: 0;
  }
`

const ItemContainer = styled.div`
`

const Item = styled.div`
  display: flex;
  & + & {
    margin-top: 2rem;
  }
`

const ItemNumber = styled.div`
  background-color: #1e753b;
  color: white;
  padding: 0.5rem;
  border-radius: 0.25rem;
`

const ItemDetailContainer = styled.div`
  margin-left: 1rem;
  padding: 0.25rem;
`

const ItemTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  * + & {
    margin-top: 2rem;
  }
`

const ItemDetail = styled.div`
  margin-top: 1rem;
  font-size: 0.9rem;
  line-height: 1.5;
`

export const UsagePage: FunctionComponent = () => {
  return <Outer>
    <HeaderOuter>
      <HeaderText>
        「撮ってほしい！」と<Mbr />「撮りたい！」をつなげる<br />
        カメラマン／<Mbr />撮影スタジオの検索マッチング
      </HeaderText>
    </HeaderOuter>
    <Title>
      このサイトでできること・<Mbr />お取引の流れ
    </Title>
    <Text>
      ご利用にはアカウント登録が必要です。<br />
      <Link to="/login">ログインページ</Link>より新規登録へお進みいただき、会員登録が完了するとマイページが使用できます。
    </Text>
    <UsageContainer>
      <UsageOuter>
        <UsageHead>
          <UsageTitle>
            <UsageTitleLabel>撮ってほしい！</UsageTitleLabel>
            <UsageTitleDetail>依頼者の方のご利用方法</UsageTitleDetail>
          </UsageTitle>
          <UsageHeadButtonContainer>
            <UsageHeadButton as={Link} to="/jobs">
              <UsageButtonLabel>WHO</UsageButtonLabel>
              <UsageButtonSubLabel>カメラマンを探す</UsageButtonSubLabel>
            </UsageHeadButton>
            <UsageHeadButton as={Link} to="/studios">
              <UsageButtonLabel>STUDIO</UsageButtonLabel>
              <UsageButtonSubLabel>撮影場所を探す</UsageButtonSubLabel>
            </UsageHeadButton>
          </UsageHeadButtonContainer>
        </UsageHead>
        <ItemContainer>
          <Item>
            <ItemNumber>
              1
            </ItemNumber>
            <ItemDetailContainer>
              <ItemTitle>
                STYLEに登録
              </ItemTitle>
              <ItemDetail>
                <Link to="/account">マイページ</Link>の「依頼を登録する」より情報登録をお願いします。<br />
                登録情報はいつでも修正可能です。
              </ItemDetail>
              <ItemTitle>
                WHOで検索
              </ItemTitle>
              <ItemDetail>
                <Link to="/photographers">カメラマンを探す</Link>から依頼したいカメラマンを検索できます。<br />
                サンプル写真や自己紹介、地域、料金の目安などでご希望に合った人を探し、ご依頼フォームに進んでください。<br />
                COMMENT/FAQではオープンチャットが使用できます
              </ItemDetail>
            </ItemDetailContainer>
          </Item>
          <Item>
            <ItemNumber>
              2
            </ItemNumber>
            <ItemDetailContainer>
              <ItemTitle>
                依頼先の選択
              </ItemTitle>
              <ItemDetail>
                登録した依頼内容に応募があった場合メールで通知されます。<br />
                締め切り期限までに依頼者を選択してください。<br />
                （選択しないとお取引が開始されません）<br />
                COMMENT/FAQではオープンチャットが、メッセージではクローズチャットが使用できます。
              </ItemDetail>
            </ItemDetailContainer>
          </Item>
          <Item>
            <ItemNumber>
              3
            </ItemNumber>
            <ItemDetailContainer>
              <ItemTitle>
                受け取りとお支払い
              </ItemTitle>
              <ItemDetail>
                写真が納品（アップロード）されると　マイページ ＞ 取引・支払い履歴 ＞ 依頼 から納品データをご確認いただけます。<br />
                データをダウンロードし受け取ったらお支払いいにお進みください（クレジットカード決済のみ）<br />
                COMMENT/FAQではオープンチャットが使用できます。
              </ItemDetail>
            </ItemDetailContainer>
          </Item>
        </ItemContainer>
      </UsageOuter>
      <UsageOuter>
        <UsageHead>
          <UsageTitle>
            <UsageTitleLabel>撮りたい！</UsageTitleLabel>
            <UsageTitleDetail>受注者の方のご利用方法</UsageTitleDetail>
          </UsageTitle>
          <UsageHeadButtonContainer>
            <UsageHeadButton as={Link} to="/jobs">
              <UsageButtonLabel>STYLE</UsageButtonLabel>
              <UsageButtonSubLabel>依頼を探す</UsageButtonSubLabel>
            </UsageHeadButton>
            <UsageHeadButton as={Link} to="/studios">
              <UsageButtonLabel>STUDIO</UsageButtonLabel>
              <UsageButtonSubLabel>撮影場所を探す</UsageButtonSubLabel>
            </UsageHeadButton>
          </UsageHeadButtonContainer>
        </UsageHead>
        <ItemContainer>
          <Item>
            <ItemNumber>
              1
            </ItemNumber>
            <ItemDetailContainer>
              <ItemTitle>
                WHOに登録
              </ItemTitle>
              <ItemDetail>
                <Link to="/account">マイページ</Link>の「カメラマン登録」より情報登録をお願いします。<br />
                登録情報はいつでも修正可能です。
              </ItemDetail>
              <ItemTitle>
              STYLEから検索
              </ItemTitle>
              <ItemDetail>
                <Link to="/jobs">撮影スタイルから探す</Link>から受注したい案件を検索できます。<br />
                日時期限、地域、報酬などでご希望に合った案件を探してください。<br />
                COMMENT/FAQではオープンチャットが、メッセージではクローズチャットが使用できます。
              </ItemDetail>
            </ItemDetailContainer>
          </Item>
          <Item>
            <ItemNumber>
              2
            </ItemNumber>
            <ItemDetailContainer>
              <ItemTitle>
                依頼に応募
              </ItemTitle>
              <ItemDetail>
                公開されている依頼に応募してください。<br />
                発注者が依頼先を選択するとお取引が開始となります。<br />
                （依頼者に選択されると「応募済みの依頼」から「カメラマン」の項目に案件名が移動します）
              </ItemDetail>
            </ItemDetailContainer>
          </Item>
          <Item>
            <ItemNumber>
              3
            </ItemNumber>
            <ItemDetailContainer>
              <ItemTitle>
                納品
              </ItemTitle>
              <ItemDetail>
                撮影完了後は速やかにデータをアップロードしてください。<br />
                <Link to="/account">マイページ</Link> ＞ 取引・支払い履歴 ＞ カメラマン から納品データをアップロードできます。<br />
                COMMENT/FAQではオープンチャットが、メッセージではクローズチャットが使用できます。
              </ItemDetail>
            </ItemDetailContainer>
          </Item>
          <Item>
            <ItemNumber>
              4
            </ItemNumber>
            <ItemDetailContainer>
              <ItemTitle>
                お支払い
              </ItemTitle>
              <ItemDetail>
                納品完了後、依頼者が支払いを済ませると報酬の請求が可能になります。<br />
                マイページの「請求」フォームより情報登録ををお願いします。<br />
                ・支払いサイトは月末締め翌月末払いとなります<br />
                ・お取引手数料25%・お振込み時に一律250円の振り込み手数料がかかります
              </ItemDetail>
            </ItemDetailContainer>
          </Item>
        </ItemContainer>
      </UsageOuter>
      <UsageOuter>
        <UsageHead>
          <UsageTitle>
            <UsageTitleLabel>スタジオを使って欲しい！</UsageTitleLabel>
            <UsageTitleDetail>スタジオ事業者の方のご利用方法</UsageTitleDetail>
          </UsageTitle>
          <UsageHeadButtonContainer>
            <UsageHeadButton as={Link} to="/studio-entry">
              <UsageButtonLabel>お申込フォーム</UsageButtonLabel>
            </UsageHeadButton>
          </UsageHeadButtonContainer>
        </UsageHead>
        <ItemContainer>
          <Item>
            <ItemNumber>
              1
            </ItemNumber>
            <ItemDetailContainer>
              <ItemTitle>
              STUDIOに登録
              </ItemTitle>
              <ItemDetail>
                スタジオ掲載のお問い合わせよりお申し込みください。<br />
                担当者から順次ご連絡させていただきます。
              </ItemDetail>
            </ItemDetailContainer>
          </Item>
          <Item>
            <ItemNumber>
              2
            </ItemNumber>
            <ItemDetailContainer>
              <ItemTitle>
                掲載・予約受付
              </ItemTitle>
              <ItemDetail>
                STUDIOページに掲載後、各スタジオ様のサイトにお申し込みやお問い合わせがあった場合は個別にご対応をお願い致します。<br />
                ・現在登録料は無料となっております。<br />
                ・当サイト内での予約受付、顧客管理等はしておりません。<br />
                ・その他詳細はお問い合わせフォームよりお問い合わせください
              </ItemDetail>
            </ItemDetailContainer>
          </Item>
        </ItemContainer>
      </UsageOuter>
    </UsageContainer>
  </Outer>
}