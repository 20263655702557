import styled from 'styled-components'
import { FunctionComponent } from 'react'
import React from 'react'
import { Magnifier } from '../../generated/svg'
import { Search } from 'history'


const Outer = styled.div`
  max-width: 30rem;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #c8c8c8;
  border-radius: 0.25rem;
  background-color: var(--base-color);
  display: flex;
`

const Input = styled.input`
  flex: 1 1 0;
  border: 0 none;
  
`

const Button = styled.div`
  flex: 0 0 auto;
  border-radius: 0.25rem;
  padding: 0.5rem;
  background-color: var(--accent-color, #888);
  display: flex;
  color: var(--base-color);
  font-size: 0.9rem;
  font-weight: 700;
  align-items: center;
  cursor: pointer;
`

const ButtonIconOuter = styled.div`
  width: 1rem;
  margin-right: 0.25rem;
`
type SearchBarProps = {
  value: string,
  onChange: (value: string) => void,
  onSearch: (value: string) => void
}
export const SearchBar: FunctionComponent<SearchBarProps> = ({value, onChange, onSearch}) => {
  return <Outer>
    <Input type="text" placeholder="キーワードで検索できます" value={value} onChange={e => onChange(e.currentTarget.value)}/>
    <Button onClick={() => onSearch(value)}>
      <ButtonIconOuter>
        <Magnifier style={{color: '#fff'}} />
      </ButtonIconOuter>
      検索
    </Button>
  </Outer> 
}