import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'

type CommentInputBoxProps = {
  value: string,
  onChange: (value: string) => void
  onSend: (value: string) => void
}

const Outer = styled.div`
  display: flex;
  border: 1px solid var(--accent-color);
  border-radius: 0.25rem;
  overflow: hidden;
`

const InputContainer = styled.div`
  flex: 1 1 0;
`

const Form = styled.form`

`

const Input = styled(TextareaAutosize)`
  padding: 0.5rem;
  border: 0 none;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  background-color: transparent;
  display: block;
  resize: none;
  line-height: 1.5;
`

const Button = styled.div`
  flex: 0 0 auto;
  padding: 0.5rem 1rem;
  font-weight: 700;
  color: white;
  background-color: var(--accent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
`

export const CommentInputBox: FunctionComponent<CommentInputBoxProps> = ({value, onChange, onSend}) => {
  return <Outer>
    <InputContainer>
      <Input value={value} onChange={e => onChange(e.currentTarget.value)} />
    </InputContainer>
    <Button onClick={() => {
      if(value.length < 1) return
      onSend(value)
    }}>
      送信
    </Button>
  </Outer>
}