import React from 'react'
import { FunctionComponent, useEffect } from 'react'
import { useSigninModal } from '../../hooks/use-sign-in-modal'
import { useUserStatus } from '../../hooks/use-user-status'

type RequireUserSignInProps = {

}
export const RequireSignIn: FunctionComponent<RequireUserSignInProps> = ({children}) => {
  const {signedIn} = useUserStatus()
  const {open: openSigninModal} = useSigninModal()
  useEffect(() => {
    (async () => {
      if(!signedIn){
        await openSigninModal()
      }
    })()
  }, [signedIn])
  if(!signedIn) return null
  return <>{children}</>
}