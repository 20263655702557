import querystring, { ParsedUrlQuery } from 'querystring'
export class SearchParams {
  constructor(params: ParsedUrlQuery){
  }
  clone(): this {
    return Object.create(Object.getPrototypeOf(this), Object.getOwnPropertyDescriptors(this))
  }
  setValues(values: Partial<this>){
    return Object.assign<this, Partial<this>>(this.clone(), values) as this
  }
  equals<T extends SearchParams>(this: T, str: string){
    return normalizeQueryString(str) === this.toString()
  }
  static fromQueryString<T = SearchParams>(this: { new(params: ParsedUrlQuery): T }, str: string){
    return new this(querystring.parse(normalizeQueryString(str)))
  }
}

const normalizeQueryString = (str: string): string => {
  return str.replace(/^\?/, '')
}