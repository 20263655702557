import * as React from "react";

function SvgAccountTabCameraIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.188 19.104"
      {...props}
    >
      <path
        d="M12.094 17.074a6.382 6.382 0 116.382-6.382 6.389 6.389 0 01-6.382 6.382zm0-12.01a5.629 5.629 0 105.629 5.629 5.635 5.635 0 00-5.629-5.63z"
        fill="currentcolor"
      />
      <path
        d="M16.258 11.069a.377.377 0 01-.377-.377 3.792 3.792 0 00-3.787-3.787.377.377 0 010-.753 4.546 4.546 0 014.54 4.54.377.377 0 01-.376.377z"
        fill="currentcolor"
      />
      <path
        d="M22.336 19.104H1.852A1.854 1.854 0 010 17.252V4.132a1.854 1.854 0 011.852-1.851H5.42L7.823.098A.377.377 0 018.077 0h8.035a.377.377 0 01.253.098l2.403 2.183h3.568a1.854 1.854 0 011.852 1.852v13.119a1.854 1.854 0 01-1.852 1.852zM1.852 3.034A1.1 1.1 0 00.753 4.133v13.119a1.1 1.1 0 001.099 1.098h20.484a1.1 1.1 0 001.099-1.099V4.133a1.1 1.1 0 00-1.099-1.099h-3.714a.377.377 0 01-.253-.098L15.966.753H8.222L5.82 2.936a.377.377 0 01-.253.098z"
        fill="currentcolor"
      />
      <path
        d="M21.993 4.786h-.031a.377.377 0 010-.753h.03a.377.377 0 010 .753zM4.098 3.034H2.492a.377.377 0 01-.377-.377V1.052a.377.377 0 01.377-.377h1.606a.377.377 0 01.376.376v1.606a.377.377 0 01-.376.377zm-1.23-.753h.853v-.853h-.853zM12.094 17.074a6.382 6.382 0 116.382-6.382 6.389 6.389 0 01-6.382 6.382zm0-12.01a5.629 5.629 0 105.629 5.629 5.635 5.635 0 00-5.629-5.63z"
        fill="currentcolor"
      />
    </svg>
  );
}

export default SvgAccountTabCameraIcon;
