import React, { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import { GQLProfileFragment, useProfileQuery } from '../generated/graphql';

type ProfileContextValue = {
  profile: GQLProfileFragment,
  signedIn: true
} | {
  profile?: undefined
  signedIn: false
}

export const ProfileContext = createContext<ProfileContextValue>({signedIn: false})

export const ProfileProvider: FunctionComponent = ({children}) => {
  const profileQuery = useProfileQuery()
  const profile = profileQuery.data?.profile

  if(profileQuery.loading) return <></>

  
  return <ProfileContext.Provider value={profile ? {
    profile: profile,
    signedIn: true
  } : {
    signedIn: false
  }}>
    {children}
  </ProfileContext.Provider>
}

export const useUserStatus = () => {
  return useContext(ProfileContext)
}