import React, { FunctionComponent, useState, HTMLProps } from 'react';
import styled from 'styled-components';
import { Label } from '../atoms/label';
import { TextInput, TextInputProps } from '../atoms/text-input';
import { Required } from './input-container';

const Block = styled.div`
  padding: 0.5rem;
  background-color: #fff;
  & + & {
    margin-top: 1rem;
  }

  input {
    width: 100%
  }
`

export const TextInputBlock: FunctionComponent<TextInputProps> = ({children, ...props}) => {
  return <Block>
    <Label>
      {children}
      <Required>必須</Required>
    </Label>
    <TextInput {...props} ></TextInput>
  </Block>
}