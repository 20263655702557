import styled from 'styled-components';
import React, { ReactNode, ReactElement } from 'react';
import { SearchConditionOuter } from '../atoms/search-condition-outer';
import { SearchConditionLabel } from '../atoms/search-condition-label';

const Outer = styled.div``

const CheckboxContainer = styled.div`

`

const Label = styled.label`
  display: flex;
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;
`

const Input = styled.input`
  display: block;
  margin-right: 0.25rem;
`

export const SearchConditionCheckbox: <T>(props: {
  children?: ReactNode,
  options: Map<T, string>,
  values: T[],
  onChange: (values: T[], detail: {type: 'added' | 'removed', value: T}) => void
}) => ReactElement = ({children, options, values, onChange}) => {
  return <SearchConditionOuter>
    <SearchConditionLabel>{children}</SearchConditionLabel>
    <CheckboxContainer>
      {
        Array.from(options.entries()).map(([value, label], i) => {
          return <Label key={i}>
            <Input
              type="checkbox"
              checked={values.includes(value)}
              onChange={e => {
                if(e.currentTarget.checked){
                  onChange(unique([...values, value]), {type: 'added', value})
                } else {
                  onChange(remove(values, value), {type: 'removed', value})
                }
              }}
            />
            {label}
          </Label>
        })
      }
    </CheckboxContainer>
  </SearchConditionOuter>
}

function unique<T>(arr: T[]): T[]{
  return Array.from(new Set(arr))
}

function remove<T>(arr: T[], value: T): T[]{
  return arr.filter(v => v !== value)
}