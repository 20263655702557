import styled from 'styled-components'
import React, { FunctionComponent, useState } from 'react'
import { FrontPageCamera, Magnifier } from '../../generated/svg'
import SvgWhiteTextVerticalLogo from '../../generated/svg/WhiteTextVerticalLogo'
import { useHistory } from 'react-router-dom'
import { Mbr } from './mbr'


const Outer = styled.div`
  width: 40rem;
  position: relative;
  @media (max-width: 40rem){
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
  }
`

const InputOuter = styled.div`
  position: absolute;
  display: flex;
  background-color: var(--base-color);
  bottom: 11.5%;
  right: 6%;
  width: 52%;
  height: 10%;
  border-radius: 0.25rem;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 40rem){
    max-width: 40rem;
    position: static;
    height: auto;
    padding: 0.25rem;
    width: 100%;
    max-width: 70%;
  }
`

const Input = styled.input`
  flex: 1 1 0;
  height: 100%;
  background-color: transparent;
  box-sizing: border-box;
  border: 0 none;
  z-index: 1;
  padding: 0.5rem;
`

const Button = styled.div`
  flex: 0 0 auto;
  border: 0.175rem solid #5db700;
  background: linear-gradient(to bottom, #70a52e, #3a4c22);
  height: 2.5rem;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  @media (max-width: 40rem){
    margin-right: 0;
  }
`

const ButtonLabel = styled.div`
  font-weight: bold;
  color: var(--base-color);
`

const CameraOuter = styled.div`
  z-index: -1;
  @media (max-width: 40rem){
    z-index: 0;
    display: none;
  }
`

const LogoOuter = styled.div`
  width: 10rem;
  position: absolute;
  bottom: 14rem;
  @media (max-width: 40rem){
    position: static;
    bottom: auto;
    margin-bottom: 2rem;
  }
`

const CopyContainer = styled.div`
  position: absolute;
  bottom: 14rem;
  color: var(--base-color);
  line-height: 2rem;
  text-align: center;
  right: -2rem;
  font-size: 1.25em;
  @media (max-width: 40rem){
    position: static;
    bottom: auto;
    margin-bottom: 2rem;
  }
`

const Copy = styled.div`
  b {
    font-size: 1.5rem;
  }
`

const SubCopy = styled.div`
`
export const CameraShoulderSearchBox: FunctionComponent = () => {
  const [value, setValue] = useState('')
  const history = useHistory()
  return <Outer>
    <CameraOuter>
      <FrontPageCamera />
    </CameraOuter>

    <LogoOuter>
      <SvgWhiteTextVerticalLogo />
    </LogoOuter>

    <CopyContainer>
      <Copy><b>「撮ってほしい」</b>と<Mbr /><b>「撮りたい！」</b>をつなげる、</Copy>
      <SubCopy>カメラマン／撮影スタジオの<Mbr />検索マッチング。</SubCopy>
    </CopyContainer>
    <InputOuter>
      <Input type="text" placeholder="" value={value} onChange={e => setValue(e.currentTarget.value)}/>
      <Button onClick={() => history.push(`/photographers?q=${value}`)}>
        <Magnifier style={{height: '1.25rem', marginRight: '0.25rem', color: '#fff'}}/>
        <ButtonLabel>
          検索
        </ButtonLabel>
      </Button>
    </InputOuter>
  </Outer>
}