import styled from 'styled-components'
import { Link } from 'react-router-dom'
import React, { FunctionComponent } from 'react'

const NavigatorOuter = styled.div`
  display: flex;
  @media (max-width: 40rem) {
    flex-direction: column;
    align-items: stretch;
  }
`

export const NavigatorItem = styled(Link)`
  display: flex;
  color: #595959;
  font-weight: 700;
  padding: 0.25rem 1.25rem;
  font-size: 0.9rem;
  position: relative;
  & + &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 1px;
    height: 1em;
    background-color: #595959;
    @media (max-width: 40rem) {
      width: 1rem;
      height: 1px;
      left: auto;
      position: absolute;
      top: 0;
    }
  }
  @media (max-width: 40rem) {
    flex-direction: column;
    align-items: center;
    padding: 1.25rem;
  }
`

export const Navigator: FunctionComponent = ({children}) => <NavigatorOuter>
  {children}
</NavigatorOuter>