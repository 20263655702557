import React from 'react'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SinglePageOuter } from '../atoms/single-page-outer'

const SinglePageDetailOuter = styled.div`
  & + & {
      margin-top: 2rem;
  }
`

const SinglePageDetailHead = styled.div`
  margin-bottom: 0.5em;
  font-weight: var(--head-font-weight);
  font-family: var(--head-font-family);
  font-size: 1.25rem;
`
const SinglePageDetailContent = styled.div`
  line-height: 1.5;
`

type SinglePageDetailProps = {
  title: string
}
export const SinglePageDetail: FunctionComponent<SinglePageDetailProps> = ({title, children}) => {
  return <SinglePageDetailOuter>
    <SinglePageDetailHead>
      {title}
    </SinglePageDetailHead>
    <SinglePageDetailContent>
      {children}
    </SinglePageDetailContent>
  </SinglePageDetailOuter>
}