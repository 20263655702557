import React from 'react';
import styled from 'styled-components';

type FormButtonProps = {
  disabled?: boolean
}
export const FormButton = styled(({disabled, ...props}) => <div {...props} />)<FormButtonProps>`
  padding: 1rem 2.5rem;
  font-weight: 700;
  color: white;
  background-color: ${({disabled}) => disabled ? '#ccc' : 'var(--accent-color)}'};
  border-radius: 0.25rem;
  margin-top: 1rem;
  align-self: center;
  cursor: pointer;
  user-select: none;
  text-align: center;
`;
