import React, { Fragment, FunctionComponent } from 'react'
import styled from 'styled-components'

const ProfileTableOuter = styled.div`
  display: grid;
  grid-template-columns: max-content 1rem auto;
  margin: -0.5rem;
  line-height: 1.5;
`
const Cell = styled.div`
  padding: 0.5rem 0;
  font-size: 0.9rem;
  &:nth-child(n + 4){
    border-top: 1px solid #ccc;
  }
`
const LabelCell = styled(Cell)`
  padding-left: 0.5rem;
`
const SeparatorCell = styled(Cell)`
  text-align: center;
`
const ValueCell = styled(Cell)`
  padding-right: 0.5rem;
`

type ProfileTableProps = {
  values: Map<string, string>
}
export const ProfileTable: FunctionComponent<ProfileTableProps> = ({values}) => {
  return <ProfileTableOuter>
    {Array.from(values).map(([label, value], i) => <Fragment key={i}>
        <LabelCell>{label}</LabelCell>
        <SeparatorCell>:</SeparatorCell>
        <ValueCell>{value}</ValueCell>
    </Fragment>)}
  </ProfileTableOuter>
}