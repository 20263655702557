import React, { Fragment, FunctionComponent } from 'react';
import styled from 'styled-components';
import { GQLUser, GQLProfileImage, GQLEquipment, GQLStudio, GQLStudioImage } from '../../generated/graphql';
import { ProfileImage } from '../atoms/profile-image';
import { PartialRequired } from '../../utils/type-helper';
import { Breadcrumbs } from './breadcrumbs';
import { ProfileTable } from './profile-table';
import { formatPrice } from '../../utils/format-price';
import { isNullish } from '../../utils/type-check';
import { CameraIcon } from '../../generated/svg';
import { SinglePageInner } from '../atoms/single-page-inner';
import { SinglePageBreadcrumbsContainer } from '../atoms/single-page-breadcrumbs-container';
import { SinglePageOuter } from '../atoms/single-page-outer';
import { SinglePageDetail } from './single-page-detail';
import { SinglePageContentContainer } from '../atoms/single-page-content-container';
import { GalleryImages } from './gallery-images';

const Outer = styled.div`
  --accent-color: #005f8b;
`

const Inner = styled.div`
  display: flex;
`

const ImageContainer = styled.div`
  flex: 1 1 0;
  background-color: #666;
  padding: 1rem;
`

const ProfileConatiner = styled.div`
  flex: 1 1 0;
`
const ProfileHeader = styled.div`
  background-color: #003d59;
  color: #fff;
  padding: 1rem;
  display: flex;
  position: relative;
  & > svg {
    position: absolute;
    width: 3rem;
    height: auto;
    top: 0;
    right: 0;
    margin: 1rem;
  }
`

const ProfileImageContainer = styled.div`
  width: 8rem;
  height: 8rem;
  flex: 0 0 auto;
`

const ProfileHeaderInfoContainer = styled.div`
  flex: 1 1 0;
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
`
const Name = styled.div`
  padding-left: 0.5rem;
  flex: 1 1 0;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
`
const Id = styled.div`
  flex: 0 0 auto;
  padding: 0.6rem 0 0.6rem 0.6rem;
  border-top: 1px solid #808080;
  font-size: 0.8rem;
  &::before {
    content: 'ID: '
  }
`
const Detail = styled.div`
  flex: 0 0 auto;
  padding: 0.6rem 0 0.25rem 0.6rem;
  border-top: 1px solid #808080;
  font-size: 0.8rem;
  display: flex;
`
const BirthYear = styled.div``
const Sex = styled.div``
const WorkCount = styled.div`
  margin-left: auto;
`
const WorkCountNumber = styled.span`
  font-weight: 700;
  text-decoration: underline;
`

const ProfileDetailTableContainer = styled.div`
  margin-bottom: 2rem;
`

const CommentContainer = styled.div`
`

export type StudioProps = {
  studio: PartialRequired<GQLStudio, 'name' | 'id' | 'screenName' | 'description' | 'zipcode' | 'address'> & {images: PartialRequired<GQLStudioImage, 'url' | 'width' | 'height'>[]}
}

export const Studio: FunctionComponent<StudioProps> = ({studio}) => {
  return <Outer>
    <SinglePageOuter>
      <SinglePageBreadcrumbsContainer>
        <Breadcrumbs items={[
          {
            label: 'Top',
            url: '/'
          }, {
            label: 'スタジオ',
            url: '/studios'
          }, {
            label: studio.address || 'エリア無し',
          }
        ]} />
      </SinglePageBreadcrumbsContainer>
      <SinglePageInner>
        <Inner>
          <ImageContainer>
            <GalleryImages images={studio.images} /> 
          </ImageContainer>
          <ProfileConatiner>
            <ProfileHeader>
              <CameraIcon style={{color: '#435972'}}/>
              <ProfileImageContainer>
                <ProfileImage profileImage={studio.images?.[0] ?? null} square />
              </ProfileImageContainer>
              <ProfileHeaderInfoContainer>
                <Name>{studio.screenName}</Name>
                <Id>{studio.name}</Id>
                <Detail>
                  <BirthYear></BirthYear>
                  <Sex></Sex>
                  <WorkCount>
                  </WorkCount>
                </Detail>
              </ProfileHeaderInfoContainer>
            </ProfileHeader>
            <SinglePageContentContainer>
              <ProfileDetailTableContainer>
                <ProfileTable values={new Map([
                  ['住所', `${studio.zipcode} ${studio.address}`]
                ])} />
              </ProfileDetailTableContainer>
              <SinglePageDetail title="PROFILE">
                {studio.description}
              </SinglePageDetail>
            </SinglePageContentContainer>
          </ProfileConatiner>
        </Inner>
      </SinglePageInner>
    </SinglePageOuter>
  </Outer>
} 

const formatFeeRange = (start: number | null | undefined, end: number | null | undefined) => {
  if(typeof start === 'number' || typeof end ==='number'){
    return `${isNullish(start) ? '' : formatPrice(start)}〜${isNullish(end) ? '' : formatPrice(end)}`
  } else {
    return '無し'
  }
}

const formatEquipment = (equipment: PartialRequired<GQLEquipment, 'screenName'>[]) => {
  if(equipment.length < 1){
    return '無し'
  } else {
    return equipment.map(equipment => equipment.screenName).join(', ')
  }
}