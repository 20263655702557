import React, { useRef, useState } from 'react'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useAddDeliverableMutation, useDeliverablesQuery } from '../../generated/graphql'
import { DndProvider, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const Outer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  @media (max-width: 40rem) {
    flex-direction: column;
  }
`

const Input = styled.input`

`
const DropArea = styled.div`

`
const UploadButton = styled.div`
  padding: 0.75rem 2rem;
  background-color: var(--accent-color);
  color: white;
  border-radius: 0.25rem;
  margin-left: 1rem;
  cursor: pointer;
  @media (max-width: 40rem) {
    margin-left: 0;
    margin-top: 1rem;
  }
`


type UploadDeliverableProps  = {
  jobId: string
}

export const UploadDeliverable: FunctionComponent<UploadDeliverableProps> = ({jobId}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const {data} = useDeliverablesQuery({
    variables: {
      jobId
    }
  })
  const [addDeliverableMutation] = useAddDeliverableMutation()
  const delivarableQuery = useDeliverablesQuery({
    variables: {
      jobId
    }
  })
  const [files, setFiles] = useState<File[]>([])
  return <Outer>
    <Input type="file" onChange={e => setFiles(Array.from(e.currentTarget.files ?? []))} multiple ref={fileInputRef}/>
    <UploadButton onClick={async () => {
      for(let file of files){
        await addDeliverableMutation({
          variables: {
            data: file,
            jobId
          }
        })
        if(fileInputRef.current){
          fileInputRef.current.value = ''
        }
        await delivarableQuery.refetch()
      }
    }}>アップロード</UploadButton>
  </Outer>
}