import * as React from "react";

function SvgPerson(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 53.113 58.859"
      {...props}
    >
      <path
        d="M45.725 36.283c-1.88-1.805-4.939-1.597-7.333-2.804-1.594-.807-3.133-1.776-4.721-2.629a13.365 13.365 0 01-3.111 1.616l-4.19 8.654-4.186-8.654a13.434 13.434 0 01-2.997-1.536c-1.625.879-3.192 1.878-4.81 2.724-2.168 1.134-5.022.788-6.76 2.413A23.991 23.991 0 000 51.322c6.676 4.64 16.101 7.537 26.555 7.537s19.884-2.897 26.558-7.537a23.968 23.968 0 00-7.388-15.04z"
        fill="currentcolor"
      />
      <path
        d="M39.614 15.95c0 8.809-5.937 15.95-13.256 15.95-7.322 0-13.257-7.141-13.257-15.95S19.036 0 26.358 0c7.319 0 13.256 7.142 13.256 15.95z"
        fill="currentcolor"
      />
    </svg>
  );
}

export default SvgPerson;
