import React, { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import { SigninOuter, SigninText, SignupLink } from '../organisms/signin';
import { H1 } from '../atoms/h1';

export const LogoutPage: FunctionComponent = () => {
  const history = useHistory()
  const client = useApolloClient()
  useEffect(() => {
    if(localStorage.getItem('token') !== null){
      localStorage.removeItem('token')
      client.clearStore().then(() => {
        client.reFetchObservableQueries().finally(() => {
          location.reload()
        })
      })
    }
  }, [])
  return <SigninOuter>
    <SigninText>ログアウトしました</SigninText>
    <SigninText><SignupLink to="/">トップページへ</SignupLink></SigninText>
  </SigninOuter>
}