import React from 'react'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Modal } from '../molecules/modal'

type ErrorModalProps = {
  messages: string[],
  onClose: () => void
}
const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Message = styled.div`
  font-size: 0.8rem;
  & + & {
    margin-top: 0.5rem;
  }
`
export const ErrorModal: FunctionComponent<ErrorModalProps> = ({messages, onClose}) => {
  return <Modal onClose={onClose}>
    <MessageContainer>
      {
        messages.map((message, i) => <Message key={i}>{message}</Message>)
      }
    </MessageContainer>
  </Modal>
}
