import { PartialRequired } from '../../utils/type-helper'
import { GQLUser, GQLProfileImage, GQLPhotographicCategory } from '../../generated/graphql'
import { FunctionComponent } from 'react'
import { Card } from './card'
import React from 'react'
import { Person } from '../../generated/svg'

type PhotographerCardProps = {
  photographer: PartialRequired<GQLUser, 'name' | 'feeRangeStart'> & {profileImage?: PartialRequired<GQLProfileImage, 'url' | 'width' | 'height'> | null | undefined} & {photographicCategories: PartialRequired<GQLPhotographicCategory, 'name' | 'screenName'>[]}
}
export const PhotographerCard: FunctionComponent<PhotographerCardProps> = ({photographer}) => {
  return <Card
    image={photographer.profileImage ?? undefined}
    name={photographer.name}
    description={photographer.photographicCategories.map(({screenName}) => screenName).join(', ')}
    price={photographer.feeRangeStart}
    icon={<Person style={{color: '#e2e2e2'}} />}
    link={`/photographers/${photographer.name}`}
  />
}