import styled from 'styled-components';

export const AccountPageOuter = styled.div`
  position: relative;
  padding: 2rem;
`

export const AccountPageHead = styled.div`
  margin: 2rem 0;
  display: flex;
  &:first-child {
    margin-top: 0;
  }
`

export const AccountPageHeadIconContainer = styled.div`
  flex: 0 0 auto;
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  color: #808080;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AccountPageHeadLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const AccountPageHeadLabel = styled.div`
  color: var(--accent-color);
  font-weight: 700;
  margin-bottom: 0.25rem;
`

export const AccountPageHeadLabelEnglish = styled.div` 
  font-size: 0.8rem;
  font-weight: var(--head-font-weight);
  font-family: var(--head-font-family);
`

export const AccountPageTabContainer = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
`