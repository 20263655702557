import React from 'react';
import { FunctionComponent, FunctionComponentFactory } from 'react';
import styled from 'styled-components';
import { GQLGalleryImage, GQLGalleryImageFragment, useGalleryImagesQuery } from '../../generated/graphql';
import { PartialRequired } from '../../utils/type-helper';

const Outer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
`

const GalleryImageOuter = styled.div`
  &:first-child {
    grid-column: 1 / 4;
    grid-row: 1 / 4;
  }
`

const NoneMessage = styled.div`
  grid-column: 1 / 4;
  color: #fff;
  text-align: center;
`

interface Image {
  url: string;
  width: number;
  height: number;
}

type GalleryImagesProps = {
  images: Image[]
}
export const GalleryImages: FunctionComponent<GalleryImagesProps> = ({images}) => {
  return <Outer>
    {images.map((images, i) => {
      return <GalleryImageOuter key={i}>
        <GalleryImage image={images} />
      </GalleryImageOuter>
    })}
    {
      images.length < 1 &&
      <NoneMessage>ギャラリーに何も登録されていません</NoneMessage>
    }
  </Outer>
}


const ImageOuter = styled.div``

const Img = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
`

type GalleryImageProps = {
  image: PartialRequired<GQLGalleryImage, 'width' | 'height' | 'url'>
}

export const GalleryImage: FunctionComponent<GalleryImageProps> = ({image}) => {
  return <ImageOuter>
    <Img src={image.url} width="100" height="100" />
  </ImageOuter>
}