import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { GQLUser } from '../../generated/graphql';
import { H1 } from '../atoms/h1';
import { Link } from 'react-router-dom';
import { ProfileImage } from '../atoms/profile-image';
import { DeepPartial } from '../../utils/type-utils';

const UserOuter = styled.div`
  margin: 1rem 0;
  padding: 1rem;
  border: 1px solid #000;
`

export const User: FunctionComponent<{user: DeepPartial<GQLUser>}> = ({user}) => {
  return <UserOuter>
    <H1>User</H1>
    <ProfileImage profileImage={user.profileImage} />
    id: <Link to={`/users/${user.name}`}>{user.id}<br /></Link>
    user name: <Link to={`/users/${user.name}`}>{user.name}</Link><br />
    screen name:{user.screenName}<br />
    description: {user.description?.slice(0, 100)}<br />
  </UserOuter>
}