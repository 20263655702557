import React from 'react'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { GQLDeliverable } from '../../generated/graphql'
import { PartialRequired } from '../../utils/type-helper'

const Outer = styled.a`

`

const Img = styled.img`
  object-fit: cover;
  width: 100%;
`

type PreviewProps = {
  deliverable: PartialRequired<GQLDeliverable, 'url'>
}

export const DeliverableItem: FunctionComponent<PreviewProps> = ({deliverable}) => {
  return <Outer href={deliverable.url}>
    <Img src={deliverable.url} width="200" height="200" />
  </Outer>
}