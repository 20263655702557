import { useState, useEffect } from 'react'

export const useClientDimension = () => {
  const [width, setWidth] = useState(document.documentElement.clientWidth)
  const [height, setHeight] = useState(document.documentElement.clientHeight)
  useEffect(() => {
    const listener = () => {
      setWidth(document.documentElement.clientWidth)
      setHeight(document.documentElement.clientHeight)
    }
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])
  return {
    width,
    height
  }
}

export const useClientWidth = () => {
  const {width} = useClientDimension()
  return width
}

export const useClientHeight = () => {
  const {height} = useClientDimension()
  return height
}