import { GQLJobStatus } from '../generated/graphql';

export const compareJobStatus = (a: GQLJobStatus, b: GQLJobStatus): number => {
  return jobStatusToNumber(a) - jobStatusToNumber(b)
}

const jobStatusToNumber = (value: GQLJobStatus): number => {
  switch(value){
    case GQLJobStatus.Open:
      return 0
    case GQLJobStatus.Closed:
      return 1
    case GQLJobStatus.Preview:
      return 2
    case GQLJobStatus.PreviewAccepted:
      return 3
    case GQLJobStatus.Deliverd:
      return 4
    case GQLJobStatus.Completed:
      return 5
    case GQLJobStatus.WatingForPaymentConfirm:
      return 99
  }
}