import * as React from "react";

function SvgMagnifier(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27.369 27.36"
      {...props}
    >
      <path
        d="M26.87 24.313l-2.548 2.547-6.54-6.54 2.588-2.506 6.499 6.5zM11.336.5A10.837 10.837 0 11.5 11.337 10.837 10.837 0 0111.337.5zm-.101 3.07a7.842 7.842 0 017.842 7.842"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgMagnifier;
