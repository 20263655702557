import { useApolloClient } from '@apollo/client';
import { ParsedUrlQuery, stringify } from 'querystring';
import React from 'react';
import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { normarizeStringParam, normarizeQueryStringObject } from '../../utils/normarize';
import { SearchParams } from '../../utils/search-params';
import { isNullish } from '../../utils/type-check';
import { Signin } from '../organisms/signin';

const normalizeRedirectUrl = (url: string): string | null => {
  console.log(url)
  let parsedUrl: URL
  try {
    parsedUrl = new URL(url)
  } catch(err) {
    return null
  }
  
  if(parsedUrl.origin === location.origin){
    return parsedUrl.toString()
  } else {
    return null
  }
}

class SignInSearchParams extends SearchParams {
  redirect: string | null
  constructor(params: ParsedUrlQuery){
    super(params)

    this.redirect = normalizeRedirectUrl(normarizeStringParam(params.redirect))
  }

  toString(){
    return stringify(normarizeQueryStringObject({
      redirect: this.redirect
    }))
  }
}

export const SigninPage: FunctionComponent = () => {
  const searchParams = SignInSearchParams.fromQueryString(location.search)
  const history = useHistory()
  const client = useApolloClient()
  return <Signin onComplete={async () => {
    await client.clearStore()
    try {
      await client.reFetchObservableQueries()
    } catch(err) {

    }
    if(isNullish(searchParams.redirect)){
      history.push('/')
    } else {
      history.push(searchParams.redirect)
    }
  }} />
}