import React, { FunctionComponent } from 'react';
import { NearJobMap } from '../organisms/near-job-map';
import { CameraShoulderSearchBox } from '../atoms/camera-shoulder-search-box';
import styled from 'styled-components';
import { NewsPostList } from '../molecules/news-post-list';
import { useNewsPostsQuery, usePhotographersQuery, useStudiosQuery } from '../../generated/graphql';
import { TopArticleListContainer } from '../organisms/top-article-list';
import { Mbr } from '../atoms/mbr';

const MapOuter = styled.div`
  z-index: 1;
  position: relative;
`

const BoxOuter = styled.div`
  position: relative;
  background-color: #1b2f31;
  box-sizing: border-box;
  height: 20rem;
  padding: 0 1rem;
  @media (max-width: 40rem){
    height: auto;
  }
`
const CameraOuter = styled.div`
  position: absolute;
  top: -10rem;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 1rem;
  box-sizing: border-box;
  @media (max-width: 40rem) {
    top: 0;
    position: relative;
  }
`

const NewsOuter = styled.div`
  background-image: url('/images/gray-noise2.jpg');
  padding: 6rem 1rem 1rem;
`

const ListOuter = styled.div`
  margin-top: 6rem;
  padding: 0 1rem;
`

export const TopPage: FunctionComponent = () => {
  const newsQuery = useNewsPostsQuery({variables: {
    offset: 0,
    limit: 5
  }})

  const newsPosts = newsQuery.data?.newsPosts.items ?? []

  const photograhpreQuery = usePhotographersQuery({variables: {
    offset: 0,
    limit: 5
  }})

  const studiosQuery = useStudiosQuery({variables: {
    input: {
      limit: 5,
      offset: 0
    }
  }})

  const photographers = photograhpreQuery.data?.photographers.items ?? []
  const studios = studiosQuery.data?.studios.items ?? []
  return <>
    <MapOuter>
      <NearJobMap />
    </MapOuter>
    <BoxOuter>
      <CameraOuter>
        <CameraShoulderSearchBox />
      </CameraOuter>
    </BoxOuter>
    <NewsOuter>
      <NewsPostList newsPosts={newsPosts} />
    </NewsOuter>
    <ListOuter>
      <TopArticleListContainer
        title="STYLE"
        subtitle="撮影スタイルから探す"
        description={<>大切な家族の記念日、ウェブサイトで販売する商品撮影、<Mbr />施工写真やウェディングなど。<br />あなたが得意とする撮影案件を検索できます。</>}
        items={[
          {
            name: '東京オリンピック',
            imageUrl: '/assets/images/jns.jpg',
            link: '/jobs?cat=tokyo2020'
          },
          {
            name: '家族写真',
            imageUrl: '/assets/images/family.jpg',
            link: '/jobs?cat=family'
          },
          {
            name: '商品撮影',
            imageUrl: '/assets/images/product.jpg',
            link: '/jobs?cat=product'
          },
          {
            name: '施工写真',
            imageUrl: '/assets/images/architecture.jpg',
            link: '/jobs?cat=architecture'
          },
          {
            name: 'ウェディング',
            imageUrl: '/assets/images/wedding.jpg',
            link: '/jobs?cat=wedding'
          },
          {
            name: 'テーブルフォト',
            imageUrl: '/assets/images/table.jpg',
            link: '/jobs?cat=table'
          }
        ]}
        moreLink="/jobs"
      />
    </ListOuter>
    <ListOuter>
      <TopArticleListContainer
        title="WHO"
        subtitle="カメラマンを探す"
        description={<>自然なポートレート写真からきっちりとした商品撮影、<Mbr />美味しそうな料理写真<br />それぞれの撮影オーダーを満たす<Mbr />個性豊かなカメラマンを検索できます。</>}
        items={photographers.map(photographer => ({name: photographer.screenName, imageUrl: photographer.profileImage?.url, link: `/photographers/${photographer.name}`}))}
        moreLink="/photographers"
      />
    </ListOuter>
    <ListOuter>
      <TopArticleListContainer
        title="STUDIO"
        subtitle="スタジオを探す"
        description={<>多種多様な撮影内容や<Mbr />シチュエーションに対応する撮影スタジオ。<br />ホリゾント、ライトボックス、<Mbr />更衣室など施設情報から検索できます。</>}
        items={studios.map(studio => ({name: studio.name, imageUrl: studio.images[0]?.url ?? '', link: `/studios/${studio.name}`}))}
        moreLink="/"
      />
    </ListOuter>
  </>
}