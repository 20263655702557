import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { VerticalLogo } from '../../generated/svg';
import { Link } from 'react-router-dom';

const Outer = styled(Link)`
  display: block;
`

const LogoOuter = styled.div`
  width: 10rem;
`

export const HeaderLogo: FunctionComponent = () => {
  return <Outer to="/">
    <LogoOuter>
      <VerticalLogo />
    </LogoOuter>
  </Outer>
}