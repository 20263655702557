import * as React from "react";

function SvgMenuHamburger(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 57.156 70.229"
      {...props}
    >
      <rect x={0.667} width={56.489} height={5.935} rx={2.155} fill="#5d8a30" />
      <rect
        x={0.667}
        y={17.289}
        width={56.489}
        height={5.935}
        rx={2.155}
        fill="#1e753b"
      />
      <rect
        x={0.667}
        y={34.578}
        width={56.489}
        height={5.935}
        rx={2.155}
        fill="#00432b"
      />
      <path
        d="M0 57.59a1.643 1.643 0 011.68-1.826c.915 0 1.413.498 1.89 1.577l3.01 6.807h.081L9.61 57.34c.457-1.08.955-1.577 1.867-1.577a1.628 1.628 0 011.66 1.826v11.06a1.331 1.331 0 01-1.39 1.474 1.332 1.332 0 01-1.39-1.473v-6.807h-.104L8.073 66.91c-.436.996-.789 1.285-1.494 1.285s-1.058-.29-1.494-1.285l-2.2-5.065h-.104v6.807a1.337 1.337 0 01-1.37 1.473A1.347 1.347 0 010 68.651zM17.362 57.237a1.247 1.247 0 011.328-1.348h6.849a1.32 1.32 0 110 2.635h-5.001a.11.11 0 00-.125.125v2.801a.11.11 0 00.125.125h4.005a1.31 1.31 0 110 2.615h-4.005a.11.11 0 00-.125.124v2.927a.11.11 0 00.125.123h5.001a1.321 1.321 0 110 2.637H18.69a1.248 1.248 0 01-1.328-1.35zM30.473 57.59a1.648 1.648 0 011.702-1.826c.87 0 1.452.52 2.013 1.494l4.316 7.7h.083v-7.721a1.393 1.393 0 112.781 0v11.061a1.649 1.649 0 01-1.702 1.826c-.872 0-1.452-.518-2.013-1.494l-4.316-7.678h-.083v7.699a1.393 1.393 0 11-2.781 0zM45.519 64.791v-7.408a1.528 1.528 0 113.05 0v7.512a2.347 2.347 0 002.366 2.635c1.412 0 2.345-.953 2.345-2.635v-7.512a1.528 1.528 0 113.051 0v7.408c0 3.528-2.22 5.438-5.396 5.438-3.3 0-5.416-1.91-5.416-5.438z"
        fill="#646465"
      />
    </svg>
  );
}

export default SvgMenuHamburger;
