import React, { useState } from 'react'
import { FunctionComponent } from 'react'
import { useError } from 'react-use'
import styled from 'styled-components'
import { GQLCreatePaymentRequestInput, GQLCreateStudioInput, useCreatePaymentRequestMutation, useCreateStudioMutation } from '../../generated/graphql'
import { useErrorModal } from '../../hooks/use-error-modal'
import { InputContainer } from '../molecules/input-container'
import { FormButton } from '../organisms/form-button'
import { FormContainer } from '../organisms/form-container'
import { createForm } from '../organisms/forms'


const Title = styled.div`
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 2rem;
  text-align: center;
`
const Message = styled.div`
  text-align: center;
`
const Outer = styled.div`
  margin: 0 auto;
  margin-top: 2rem;
  max-width: 40rem;
  --accent-color: #088;
`

const {
  useForm,
  Form,
  Text,
  Select,
  Textarea
} = createForm<GQLCreatePaymentRequestInput>()
export const PaymentRequestPage: FunctionComponent = () => {
  const [values, formProps] = useForm({
    bankName: '',
    branchName: '',
    bankAccountType: '普通',
    bankAccountNumber: '',
    bankAccountName: ''
  })
  const [createPaymentRequestMutation] = useCreatePaymentRequestMutation()
  const {open} = useErrorModal()
  const [sent, setSent] = useState(false)
  return <Outer>
    <Title>振り込み申請</Title>
    {
      !sent && <FormContainer>
        <Form {...formProps}>
          <InputContainer label="銀行名"><Text name="bankName" /></InputContainer>
          <InputContainer label="支店名"><Text name="branchName" /></InputContainer>
          <InputContainer label="口座種別"><Select name="bankAccountType" options={new Map([['普通', '普通'], ['当座', '当座']])} /></InputContainer>
          <InputContainer label="口座番号"><Text name="bankAccountNumber" /></InputContainer>
          <InputContainer label="口座名義(カタカナ)"><Text name="bankAccountName" /></InputContainer>
        </Form>
        <FormButton onClick={async () => {
          try{
            await createPaymentRequestMutation({
              variables: {
                input: values
              }
            })
          } catch(err) {
            await open(err)
            return
          }
          setSent(true)
        }}>送信</FormButton>
      </FormContainer>
    }
    {
      sent &&
      <Message>送信が完了いたしました。</Message>
    }
  </Outer>
}