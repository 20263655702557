import * as React from "react";

function SvgAccountTabHandDollerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.715 24.517"
      {...props}
    >
      <path
        d="M4.122 24.517H.41a.41.41 0 01-.409-.41V13.719a.41.41 0 01.41-.409h3.712a.41.41 0 01.41.41v10.389a.409.409 0 01-.41.409zm-3.304-.818h2.895v-9.572H.818z"
        fill="currentcolor"
      />
      <path
        d="M10.177 18.566a.409.409 0 01-.121-.8l2.66-.825a.407.407 0 01.179-.015l4.977.7a.576.576 0 00.638-.44l.043-.188a.575.575 0 00-.427-.685l-5.24-1.237a1.614 1.614 0 00-.67-.017l-8.02 1.482a.409.409 0 11-.148-.804l8.019-1.482a2.426 2.426 0 011.007.025l5.24 1.237a1.396 1.396 0 011.037 1.663l-.043.188a1.4 1.4 0 01-1.55 1.07l-4.887-.688-2.573.798a.41.41 0 01-.121.018z"
        fill="currentcolor"
      />
      <path
        d="M9.833 22.48a49.253 49.253 0 01-5.759-.338.409.409 0 11.096-.813 48.018 48.018 0 0018.294-1.36l1.01-.276a.574.574 0 00.122-1.058.577.577 0 00-.272-.069l-5.519-.125a.41.41 0 01-.4-.419.421.421 0 01.419-.4l5.509.126a1.392 1.392 0 01.356 2.734l-1.01.275a48.836 48.836 0 01-12.846 1.724zM12.317 9.547A2.478 2.478 0 0110.29 8.52a.41.41 0 11.65-.498 1.81 1.81 0 002.266.502.434.434 0 01.035-.016.803.803 0 00.437-.844 1.024 1.024 0 00-.7-.857l-1.478-.44a1.556 1.556 0 01-1.257-1.597 1.498 1.498 0 01.506-1.145 2.406 2.406 0 011.663-.487 2.137 2.137 0 012.082 1.445.41.41 0 01-.785.23 1.3 1.3 0 00-1.306-.857 1.665 1.665 0 00-1.114.284.694.694 0 00-.228.539c-.003.313.076.628.67.804l1.476.439a1.847 1.847 0 011.285 1.568 1.63 1.63 0 01-.924 1.668 2.834 2.834 0 01-1.251.29z"
        fill="currentcolor"
      />
      <path
        d="M12.408 3.955a.41.41 0 01-.41-.409V2.381a.41.41 0 01.819 0v1.165a.41.41 0 01-.41.41zM12.408 10.786a.41.41 0 01-.41-.409V9.212a.41.41 0 11.819 0v1.165a.41.41 0 01-.41.41z"
        fill="currentcolor"
      />
      <path
        d="M12.358 13.066a6.533 6.533 0 116.533-6.533 6.54 6.54 0 01-6.533 6.533zm0-12.248a5.715 5.715 0 105.714 5.715A5.721 5.721 0 0012.358.818z"
        fill="currentcolor"
      />
    </svg>
  );
}

export default SvgAccountTabHandDollerIcon;
