import React, { useState } from 'react'
import styled from 'styled-components';
import { FunctionComponent } from 'react';
import { usePhotographersQuery, useEquipmentListQuery, usePhotographicCategoriesQuery } from '../../generated/graphql';
import querystring, { ParsedUrlQuery } from 'querystring'
import { normarizeArrayParam, normarizeQueryStringObject, normarizeStringParam } from '../../utils/normarize';
import { SearchParams } from '../../utils/search-params';
import { clip } from '../../utils/util';
import { SearchBar } from '../molecules/search-bar';
import { SearchCondition } from '../molecules/search-condition';
import { SearchConditionCheckbox } from '../molecules/search-condition-checkbox';
import { CardContainer } from '../organisms/card-container';
import { PhotographerCard } from '../molecules/photographer-card';
import { CardPaginate } from '../molecules/card-paginate';
import { useHistory, useLocation } from 'react-router-dom';

const SearchPhotographersOuter = styled.div``

class SearchPhotograhperParams extends SearchParams {
  page: number;
  query: string | null;
  equipment: string[];
  photographicCategories: string[];

  constructor(params: ParsedUrlQuery){
    super(params)
    this.page = clip(Number(normarizeStringParam(params.p) ?? 1), 1, Infinity)
    this.query = normarizeStringParam(params.q) || null
    this.equipment = normarizeArrayParam(params.eq ?? '')
    this.photographicCategories = normarizeArrayParam(params.pc ?? '')
  }

  toString(){
    return querystring.stringify(normarizeQueryStringObject({
      p: this.page,
      q: this.query,
      eq: this.equipment,
      pc: this.photographicCategories
    }))
  }
}

const Outer = styled.div`
  --accent-color: #005938;
`

const HeaderOuter = styled.div`
  background-image: url('/images/photographer.jpg');
  background-size: cover;
  background-position: center 20%;
  height: 16rem;
  display: flex;
  align-items: center;
`

const HeaderLabel = styled.div`
  font-family: var(--head-font-family);
  font-weight: var(--head-font-weight);
  font-size: 2rem;
  color: var(--base-color);
  margin: 0 8rem 0 auto;
`

const SearchContainer = styled.div`
  background-color: #4d4d4d;
  padding: 0.75rem;
  display: flex;

  justify-content: center;
`

const ContentOuter = styled.div`
  display: flex;
  background-color: #e2e2e2;
  flex-direction: column;
  align-items: center;
`

const ContentInner = styled.div`
  display: flex;
  width: 100%;
  max-width: 80rem;
  @media (max-width: 40rem) {
    flex-direction: column;
  }
`

const SearchConditionOuter = styled.div`
  flex: 0 0 12rem;
  padding: 1rem;
`

const CardContainerOuter = styled.div`
  flex: 1 1 0;
  background-color: var(--base-color);
  padding: 2rem;
`

const PaginateOuter = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`



export const SearchPhotographers: FunctionComponent = ({}) => {
  const {search} = useLocation()
  const history = useHistory()
  const searchParams = SearchPhotograhperParams.fromQueryString(search)
  const [query, setQuery] = useState(searchParams.query ?? '')
  const equipmentQuery = useEquipmentListQuery()
  const photographicCategoriesQuery = usePhotographicCategoriesQuery()
  const photographersQuery = usePhotographersQuery({variables: {
    limit: 12,
    offset: (searchParams.page - 1) * 12,
    equipment: searchParams.equipment,
    photographicCategories: searchParams.photographicCategories,
    query: searchParams.query
  }})

  const photographers = photographersQuery.data?.photographers.items ?? []
  return <Outer>
    <HeaderOuter>
      <HeaderLabel>PHOTOGRAPHER</HeaderLabel>
    </HeaderOuter>
    <SearchContainer>
      <SearchBar value={query} onChange={setQuery} onSearch={() => history.push({search: searchParams.setValues({query: query || null, page: 1}).toString()})}/>
    </SearchContainer>
    <ContentOuter>
      <ContentInner>
        <SearchConditionOuter>
          <SearchCondition>
            <SearchConditionCheckbox
              options={new Map(equipmentQuery.data?.equipmentList.map(({name, screenName}) => [name, screenName]))}
              values={searchParams.equipment}
              onChange={values => {
                history.push({search: searchParams.setValues({equipment: values, page: 1}).toString()})
              }}
            >
              使用機材
            </SearchConditionCheckbox>
            <SearchConditionCheckbox
              options={new Map(photographicCategoriesQuery.data?.photographicCategories.map(({name, screenName}) => [name, screenName]))}
              values={searchParams.photographicCategories}
              onChange={values => {
                history.push({search: searchParams.setValues({photographicCategories: values, page: 1}).toString()})
              }}
            >
              得意ジャンル
            </SearchConditionCheckbox>
          </SearchCondition>
        </SearchConditionOuter>
        <CardContainerOuter>
          <CardContainer>
            {
              photographers?.map(photographer => <PhotographerCard key={photographer.id} photographer={photographer} />)
            }
          </CardContainer>
          <PaginateOuter>
            <CardPaginate
              currentPage={searchParams.page}
              pageCount={Math.ceil((photographersQuery.data?.photographers.count ?? 0) / 12)}
              urlGenerator={({page}) => ({pathname: '/photographers', search: searchParams.setValues({page}).toString()})}
            />
          </PaginateOuter>
        </CardContainerOuter>

      </ContentInner>
    </ContentOuter>

  </Outer>
}