import React, { FunctionComponent, useEffect } from 'react';
import { GQLJobInput, useCreateJobMutation, usePhotographerQuery, usePhotographicCategoriesQuery } from '../../generated/graphql';
import { FormContainer } from '../organisms/form-container';
import { createForm } from '../organisms/forms';
import { FormButton } from '../organisms/form-button';
import { isNotIncludeUndefined, isNotNullish } from '../../utils/type-check';
import { useHistory } from 'react-router-dom';
import { useErrorModal } from '../../hooks/use-error-modal';
import { InputContainer, MultipleCheckboxContainer } from '../molecules/input-container';
import styled from 'styled-components';
import SvgPerson from '../../generated/svg/Person';
import { AccountPageHead, AccountPageHeadIconContainer, AccountPageHeadLabelContainer, AccountPageHeadLabel, AccountPageHeadLabelEnglish } from '../organisms/account-page-parts';
import { ErrorBlock } from '../molecules/error-block';

const {
  useForm,
  Form,
  Text,
  Textarea,
  Checkbox,
  NumberText,
  DateInput,
  LocationInput,
  MultipleCheckbox
} = createForm<Partial<GQLJobInput>>()

const Outer = styled.div`
  background-color: #eee;
  padding: 2rem 1rem;
`

const Inner = styled.div`
  max-width: 60rem;
  margin: 0 auto;
  padding: 2rem;
  --accent-color: #005938;
  background-color: #fff;
  border-radius: 0.5rem;
`

type RequestJobPageProps = {
  userName: string
}
export const RequestJobPage: FunctionComponent<RequestJobPageProps> = ({userName}) => {
  const history = useHistory()
  const {open: openErrorModal} = useErrorModal()
  const [createJobMutation] = useCreateJobMutation()

  const userQuery = usePhotographerQuery({variables: {userName}})
  const user = userQuery.data?.user
  const photographicCategoriesQuery = usePhotographicCategoriesQuery()
  
  const [values, formProps] = useForm({
    address: '',
    areaCode: undefined,
    categoryNames: [],
    deadline: undefined,
    description: '',
    fee: undefined,
    lat: undefined,
    lng: undefined,
    name: '',
    photographerId: user?.id
  })

  useEffect(() => {
    formProps.setValue(values => ({...values, photographerId: user?.id}))
  }, [user?.id])

  if(!user){
    return <ErrorBlock name="404" message="Not found" />
  }


  return <Outer>
    <Inner>
      <AccountPageHead>
        <AccountPageHeadIconContainer>
          <SvgPerson /> 
        </AccountPageHeadIconContainer>
        <AccountPageHeadLabelContainer>
          <AccountPageHeadLabel>{user.screenName}に撮影を依頼する</AccountPageHeadLabel>
          <AccountPageHeadLabelEnglish>REQUEST JOB</AccountPageHeadLabelEnglish>
        </AccountPageHeadLabelContainer>
      </AccountPageHead>
      <FormContainer>
        <Form {...formProps}>
          <InputContainer label="案件名"><Text name="name" /></InputContainer>
          <InputContainer label="撮影場所">
          <LocationInput addressName="address" latName="lat" lngName="lng" areaCodeName="areaCode" style={{width: '100%', height: '20rem'}} />
          </InputContainer>
          <InputContainer label="撮影希望日時"><DateInput name="deadline"/></InputContainer>
          <InputContainer label="報酬"><NumberText name="fee" /></InputContainer>
          <InputContainer label="依頼概要"><Textarea name="description" /></InputContainer>
          <InputContainer label="カテゴリー">
            <MultipleCheckboxContainer>
              {
                photographicCategoriesQuery.data?.photographicCategories.map(photographicCategory => {
                  return <label key={photographicCategory.name}>
                    <MultipleCheckbox name="categoryNames" value={photographicCategory.name} />
                    {photographicCategory.screenName}
                  </label>
                })
              }
            </MultipleCheckboxContainer>
          </InputContainer>
        </Form>
        <FormButton disabled={!isNotIncludeUndefined(values)} onClick={async () => {
          if(isNotIncludeUndefined(values)){
            try {
              const res = await createJobMutation({
                variables: {
                  job: values
                }
              })
              const id = res.data?.createJob.id
              if(isNotNullish(id)){
                history.replace(`/jobs/${id}`)
              }
            } catch(err) {
              openErrorModal(err)
            }
          }
        }}>依頼を登録する</FormButton>
      </FormContainer>
    </Inner>
  </Outer>
}