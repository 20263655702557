import React, { useEffect } from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { MenuClose } from '../../generated/svg';

const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  --padding: 2rem;
  padding: var(--padding);
  width: calc(100vw - (var(--padding) * 2));
  height: calc(100vh - (var(--padding) * 2));
  top: 0;
  left: 0;
`

const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
`

const ModalWindow = styled.div`
  background-color: var(--modal-window-background, white);
  border-radius: 0.25rem;
  position: relative;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`

const ModalContentContainer = styled.div`
  --padding: 2rem;
  padding: var(--padding) var(--padding) 0;
  overflow: scroll;
  flex: 1 1 auto;
  &::after {
    content: '';
    display: block;
    margin-bottom: var(--padding);
  }
`

const ModalButtonContainer = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const ModalCloseButtonContainer = styled.div`
  width: 0.75rem;
  padding: 0.5rem;
  cursor: pointer;
  & > svg {
    display: block;
  }
`

type ModalProps = {
  onClose?: () => void;
  hideCloseButton?: boolean;
}

export const Modal: FunctionComponent<ModalProps> = ({ children, onClose, hideCloseButton }) => {
  // useEffect(() => {
  //   const listener = (e: KeyboardEvent) => {
  //     if(e.key === 'Escape'){
  //       e.stopPropagation()
  //       onClose()
  //     }
  //   }
  //   window.addEventListener('keypress', listener)
  //   return () => {
  //     window.removeEventListener('keypress', listener)
  //   }
  // })
  return <ModalContainer>
    <ModalBackground onClick={onClose}/>
    <ModalWindow>
      { !hideCloseButton &&
        <ModalButtonContainer>
          <ModalCloseButtonContainer onClick={onClose}>
            <MenuClose />
          </ModalCloseButtonContainer>
        </ModalButtonContainer>
      }
      <ModalContentContainer>
        {children}
      </ModalContentContainer>
    </ModalWindow>
  </ModalContainer>
}
