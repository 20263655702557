import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { NewsObject, NewsPostListItem } from '../atoms/news-post-list-item';

const Outer = styled.div`
  max-width: 40rem;
  background-color: var(--base-color);
  border-radius: 0.5rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 1rem 0;
`

const List = styled.ul`
  flex: 1 1 0;
  padding: 0 1rem;
  border-left: 1px solid #ccc;
`

const ListItem = styled.li`
`

const Item = styled.div`
`


const Label = styled.div`
  flex: 0 0 auto;
  padding: 0 1.5rem;
  font-family: var(--head-font-family);
  font-weight: var(--head-font-weight);
  letter-spacing: 0.125rem;
  color: var(--head-text-color);
  font-size: 1.25em;
`

type NewsListProps = {
  newsPosts: NewsObject[],
}
export const NewsPostList: FunctionComponent<NewsListProps> = ({newsPosts}) => {
  return <Outer>
    <Label>
      NEWS
    </Label>
    <List>
      {
        newsPosts.map(newsPost => <ListItem key={newsPost.id}><NewsPostListItem newsPost={newsPost} /></ListItem>)
      }
    </List>
  </Outer>
}