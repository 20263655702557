export function isEmptyArray(value: any): value is [] {
  return Array.isArray(value) && value.length < 1
}

export function isNullish(value: any): value is null | undefined {
  return value === null || typeof value === 'undefined'
}

export function isNotNullish<T>(value: T): value is Exclude<T, null | undefined> {
  return !isNullish(value)
}

export function isNotIncludeUndefined<T extends {[key: string]: any}>(object: T): object is Required<T>{
  return Object.values(object).every(value => typeof value !== 'undefined')
}