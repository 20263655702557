import React from 'react'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { GQLJobApplication, GQLUser, useAcceptJobApplicationMutation, useJobApplicationsQuery } from '../../generated/graphql'
import { useErrorModal } from '../../hooks/use-error-modal'
import { formatDateTime } from '../../utils/format-date'
import { PartialRequired } from '../../utils/type-helper'
import { ProfileImage } from '../atoms/profile-image'


const Outer = styled.div`
  padding: 3rem 0;
`

const PhotographerContainer = styled.div`
  display: flex;
  margin: -1rem -0.75rem;
  flex-wrap: wrap;
`

const PhotographerWrapper = styled.div`
  padding: 1rem 0.75rem;
  flex: 0 0 25%;
  box-sizing: border-box;
  @media (max-width: 40rem) {
    flex: 0 0 100%;
  }
`

const NoPhotographer = styled.div`
  margin: 0 auto;
`

type SelectContractorProps = {
  jobId: string
}

export const SelectContractor: FunctionComponent<SelectContractorProps> = ({jobId}) => {
  const {data} = useJobApplicationsQuery({
    variables: {
      jobId: jobId
    }
  })
  const [acceptJobApplicationMutation] = useAcceptJobApplicationMutation()
  const {open: openErrorModal} = useErrorModal()
  if(!data) return null
  return <PhotographerContainer>
      {
        data.jobApplications.length > 0 ?
          data.jobApplications.map(jobApplication => {
            return <PhotographerWrapper key={jobApplication.userId}>
              <Photographer jobApplication={jobApplication} onAccept={async userId => {
                try {
                  await acceptJobApplicationMutation({
                    variables: {
                      jobId: jobApplication.jobId,
                      userId: jobApplication.userId
                    }
                  })
                } catch(err) {
                  openErrorModal(err)
                }
              }} />
            </PhotographerWrapper>
          }):
          <NoPhotographer>まだ応募がありません</NoPhotographer>
      }
    </PhotographerContainer>
}


const PhotographerOuter = styled.div`
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  padding: 0.5rem;
  border-radius: 0.25rem;
`
const PhotographerInner = styled(Link)`

`

const PhotographerProfileImageContainer = styled.div`
  width: 100%;
`

const PhotographerName = styled.div`
  font-weight: bold;
  color: white;
  background-color: var(--accent-color);
  padding: 0.5rem;
  font-size: 0.9rem;
`

const ApplicationDate = styled.div`
  font-size: 0.75rem;
  color: #959595;
  margin: 0.75rem 0;
`

const ButtonConatiner = styled.div`
  display: flex;
`

const Button = styled.div`

`

const AcceptButton = styled.div`
  flex: 1 1 0;
  padding: 0.6rem;
  font-weight: 700;
  color: white;
  background-color: var(--accent-color);
  text-align: center;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  user-select: none;
  cursor: pointer;
`



type PhotographerProps = {
  jobApplication: PartialRequired<GQLJobApplication, 'createdAt' | 'userId'> & {user: PartialRequired<GQLUser, 'id' | 'name'>},
  onAccept: (userId: string) => void
}
const Photographer: FunctionComponent<PhotographerProps> = ({jobApplication, onAccept}) => {
  const photographer = jobApplication.user
  return <PhotographerOuter>
    <PhotographerInner to={`/photographers/${photographer.name}`}>
      <PhotographerProfileImageContainer>
        <ProfileImage profileImage={photographer.profileImage} square />
      </PhotographerProfileImageContainer>
      <PhotographerName>
        {photographer.name}
      </PhotographerName>
    </PhotographerInner>
    <ApplicationDate>申込日時:{formatDateTime(jobApplication.createdAt)}</ApplicationDate>
    <ButtonConatiner>
      <AcceptButton onClick={() => {
        onAccept(jobApplication.userId)
      }}>依頼する</AcceptButton>
    </ButtonConatiner>
</PhotographerOuter>
}