import React, { FunctionComponent, useState } from 'react';
import { GQLBusinessForm, useJobCategoriesQuery, useJobsQuery, usePhotographicCategoriesQuery } from '../../generated/graphql';
import { clip } from '../../utils/util';
import { normarizeArrayParam, normarizeStringParam, normarizeIntParam, normarizeQueryStringObject } from '../../utils/normarize';
import { SearchParams } from '../../utils/search-params';
import { useLocation, useHistory, Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import querystring, { ParsedUrlQueryInput, ParsedUrlQuery } from 'querystring'
import { H1 } from '../atoms/h1';
import { CheckboxList } from '../molecules/checkbox-list';
import { Paginate, LinkBuilder } from '../molecules/paginate';
import { Job } from '../molecules/job';
import { isNullish } from '../../utils/type-check';
import { LoadingIndicator } from '../atoms/loading-indicator';
import { CardContainer } from '../organisms/card-container';
import { JobCard } from '../molecules/job-card';
import { SearchBar } from '../molecules/search-bar';
import { SearchCondition } from '../molecules/search-condition';
import { SearchConditionCheckbox } from '../molecules/search-condition-checkbox';
import { PhotographerCard } from '../molecules/photographer-card';
import { CardPaginate } from '../molecules/card-paginate';

function isValidBusinessForm(value: string): value is keyof typeof GQLBusinessForm {
  return value in GQLBusinessForm
}

function normarizeBusinessForm(param?: string | string[]): GQLBusinessForm | undefined {
  if(isNullish(param)) return
  const value = Array.isArray(param) ? param[0] : param
  if(isValidBusinessForm(value)){
    return GQLBusinessForm[value]
  }
}

class SearchJobParams extends SearchParams {
  page: number;
  query?: string;
  deadlineRange?: number;
  categories: string[];
  businessForm?: GQLBusinessForm;
  maxFee?: number;
  constructor(params: ParsedUrlQuery){
    super(params)
    this.page = clip(Number(normarizeStringParam(params.p)), 1, Infinity)
    this.deadlineRange = normarizeIntParam(params.dr)
    this.categories = normarizeArrayParam(params.cat ?? '')
    this.businessForm = normarizeBusinessForm(params.bf)
    this.maxFee = normarizeIntParam(params.mf)
    this.query = normarizeStringParam(params.q) || undefined
  }
  toString(){
    return querystring.stringify(normarizeQueryStringObject({
      p: this.page,
      q: this.query,
      dr: this.deadlineRange,
      cat: this.categories,
      bf: this.businessForm,
      mf: this.maxFee?.toString()
    }))
  }
}

const Outer = styled.div`
  --accent-color: #669933;
`

const HeaderOuter = styled.div`
  background-image: url('/images/photographer.jpg');
  background-size: cover;
  background-position: center 20%;
  height: 16rem;
  display: flex;
  align-items: center;
`

const HeaderLabel = styled.div`
  font-family: var(--head-font-family);
  font-weight: var(--head-font-weight);
  font-size: 2rem;
  color: var(--base-color);
  margin: 0 8rem 0 auto;
`

const SearchContainer = styled.div`
  background-color: #4d4d4d;
  padding: 0.75rem;
  display: flex;

  justify-content: center;
`

const ContentOuter = styled.div`
  display: flex;
  background-color: #e2e2e2;
  flex-direction: column;
  align-items: center;
`

const ContentInner = styled.div`
  display: flex;
  width: 100%;
  max-width: 80rem;
  @media (max-width: 40rem) {
    flex-direction: column;
  }
`

const SearchConditionOuter = styled.div`
  flex: 0 0 12rem;
  padding: 1rem;
`

const CardContainerOuter = styled.div`
  flex: 1 1 0;
  background-color: var(--base-color);
  padding: 2rem;
`

const PaginateOuter = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`

export const SearchJobs: FunctionComponent = ({}) => {
  const {search} = useLocation()
  const history = useHistory()
  const searchParams = SearchJobParams.fromQueryString(search)
  const [query, setQuery] = useState(searchParams.query ?? '')
  const photographicCategoriesQuery = usePhotographicCategoriesQuery()
  const jobsQuery = useJobsQuery({variables: {
    limit: 12,
    offset: (searchParams.page - 1) * 12,
    query: searchParams.query,
    deadlineRange: searchParams.deadlineRange,
    categoryNames: searchParams.categories,
    businessForm: searchParams.businessForm,
    maxFee: searchParams.maxFee
  }})

  return <Outer>
    <HeaderOuter>
      <HeaderLabel>JOBS</HeaderLabel>
    </HeaderOuter>
    <SearchContainer>
      <SearchBar value={query} onChange={setQuery} onSearch={() => history.push({search: searchParams.setValues({query: query || undefined, page: 1}).toString()})}/>
    </SearchContainer>
    <ContentOuter>
      <ContentInner>
        <SearchConditionOuter>
          <SearchCondition>
            <SearchConditionCheckbox
              options={new Map([
                [1, '〜1日(特急)'],
                [7, '1週間以内'],
                [30, '1ヶ月以内'],
                [undefined, '指定なし']
              ])}
              values={[searchParams.deadlineRange]}
              onChange={(values, detail) => {
                if(detail.type === 'added'){
                  history.push({search: searchParams.setValues({deadlineRange: detail.value, page: 1}).toString()})
                } else {
                  history.push({search: searchParams.setValues({deadlineRange: undefined, page: 1}).toString()})
                }
              }}
            >
              納期
            </SearchConditionCheckbox>
            <SearchConditionCheckbox
              options={new Map(photographicCategoriesQuery.data?.photographicCategories.map(({name, screenName}) => [name, screenName]))}
              values={searchParams.categories}
              onChange={values => {
                history.push({search: searchParams.setValues({categories: values, page: 1}).toString()})
              }}
            >
              得意ジャンル
            </SearchConditionCheckbox>
          </SearchCondition>
        </SearchConditionOuter>
        <CardContainerOuter>
          <CardContainer>
            {
              jobsQuery.data?.jobs.items.map(job => <JobCard key={job.id} job={job} />)
            }
          </CardContainer>
          <PaginateOuter>
            <CardPaginate
              currentPage={searchParams.page}
              pageCount={Math.ceil((jobsQuery.data?.jobs.count ?? 0) / 12)}
              urlGenerator={({page}) => ({pathname: '/jobs', search: searchParams.setValues({page}).toString()})}
            />
          </PaginateOuter>
        </CardContainerOuter>
      </ContentInner>
    </ContentOuter>
  </Outer>
}
