import { useApolloClient } from '@apollo/client'
import { Autocomplete, GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import gql from 'graphql-tag'
import React, { useState, FunctionComponent, useContext, ReactNode,  useEffect, createContext, ReactElement, CSSProperties } from 'react'
import ReactDatePicker from 'react-datepicker'
import styled from 'styled-components'
import { AREA_BY_NAME_QUERY } from '../../documents/area'
import { AREA_FRAGMENT } from '../../documents/fragments'
import { GQLAreaByNameQuery, GQLAreaByNameQueryVariables, useAreaByNameLazyQuery, useAreaByNameQuery } from '../../generated/graphql'
import { useUserPosition } from '../../hooks/use-user-position'
import { toHarfString } from '../../utils/to-harf-string'
import { isNotNullish, isNullish } from '../../utils/type-check'
import { FilteredKeys } from '../../utils/type-utils'
import { isPrefectureName, Prefecture } from '../atoms/prefecture-select'
import { LatLng } from './near-job-map'
import { DropdownDate, DropdownComponent } from 'react-dropdown-date' 


const useLatLng = (latLng: LatLng): [LatLng, (latLng: LatLng) => void] => {
  const [lat, setLat] = useState(latLng.lat)
  const [lng, setLng] = useState(latLng.lng)
  return [{lat, lng}, ({lat, lng}) => {
    setLat(lat)
    setLng(lng)
  }]
}

const formatGeocorderResults = (res: google.maps.GeocoderResult[]): {
  prefecture: string | null,
  city: string | null,
  address: string
} => {
  const geocode = res.find(geocorderResult => !['establishment', 'route', 'postal_code'].some(type => geocorderResult.types.includes(type)))
  const addressComponents = res.find(geocorderResult => ['locality', 'political'].every(type => geocorderResult.types.includes(type)))?.address_components
  const prefecture = addressComponents?.find(addressComponent => addressComponent.types.includes('administrative_area_level_1'))?.long_name ?? null
  const city = addressComponents?.find(addressComponent => addressComponent.types.includes('locality'))?.long_name ?? null
  return {
    prefecture,
    city,
    address: geocode ? geocode.formatted_address.replace(/(?:日本、)?(?:〒\d{3}-\d{4}\s?)?/, '') : ''
  }
}

export const createForm = <T, >() => {
  

  type FormProps = {
    value: T,
    setValue: React.Dispatch<React.SetStateAction<T>>
  }

  function useForm(defaultValue: T): [T, FormProps]{
    const [value, setValue] = useState(defaultValue)
    return [value, {value, setValue}]
  }
  type SetValue = <K extends keyof T>(name: K, value: T[K]) => void
  
  const FormContext = createContext<[T, SetValue] | null>(null)


  const Form: FunctionComponent<FormProps> = ({children, value, setValue}) => {
    const set: SetValue = (name, newValue) => {
      setValue(value => ({...value, [name]: newValue}))
    }
  return <FormContext.Provider value={[value, set]}>
      {children}
    </FormContext.Provider>
  }

  type TextProps = {
    className?: string,
    name: keyof T,
    placeholder?: string
  }
  
  const Text: FunctionComponent<TextProps> = ({children, className, name, placeholder = ''}) => {
    const [values, setValue] = useContext(FormContext) || []
    if(!values || !setValue) return null
    return <input type="text" value={isNullish(values[name]) ? '' : values[name] as any} onChange={e => setValue(name, e.currentTarget.value as any)} placeholder={placeholder} />
  }

  const Password: FunctionComponent<TextProps> = ({children, className, name, placeholder = ''}) => {
    const [values, setValue] = useContext(FormContext) || []
    if(!values || !setValue) return null
    return <input type="password" value={isNullish(values[name]) ? '' : values[name] as any} onChange={e => setValue(name, e.currentTarget.value as any)} placeholder={placeholder} />
  }

  const NumberText: FunctionComponent<TextProps> = ({children, className, name, placeholder = ''}) => {
    const [values, setValue] = useContext(FormContext) || []
    if(!values || !setValue) return null
    const [tempValue, setTempValue] = useState((values[name] as any ?? '').toString() as string)
    return <input type="text" value={tempValue} onChange={e => setTempValue(e.currentTarget.value)} onBlur={e => {
      const num = Number(toHarfString(tempValue.replace(/[^0-9]/g, '').replace(/\..*/, '')))
      if(Number.isFinite(num)) {
        setValue(name, num as any)
        setTempValue(num.toString())
      } else {
        setValue(name, null as any)
        setTempValue('')
      }
    }} />
  }

  type TextareaProps = {
    className?: string,
    name: keyof T,
    placeholder?: string
  }
  const Textarea: FunctionComponent<TextareaProps> = ({children, className, name, placeholder = ''}) => {
    const [values, setValue] = useContext(FormContext) || []
    if(!values || !setValue) return null
    return <textarea value={isNullish(values[name]) ? '' : values[name] as any} onChange={e => setValue(name, e.target.value as any)} placeholder={placeholder} />
  }

  type CheckboxProps = {
    className?: string,
    name: keyof T
  }
  const Checkbox: FunctionComponent<CheckboxProps> = ({children, className, name}) => {
    const [values, setValue] = useContext(FormContext) || []
    if(!values || !setValue) return null
    return <input type="checkbox" checked={values[name] as any} onChange={e => setValue(name, e.target.checked as any)} />
  }

  function MultipleCheckbox<
    K extends FilteredKeys<T, any[] | undefined>,
    V extends T[K] & any[]
  >({name, value}: {
    name: K,
    value: V[number]
  }): ReactElement | null {
    const [values, setValue] = useContext(FormContext) || []
    if(!values || !setValue) return null
    const valueList = values[name] as any

    return <input type="checkbox" checked={valueList.includes(value)} onChange={e => {
      if(e.currentTarget.checked){
        if(!valueList.includes(value)){
          setValue(name, [...valueList, value] as any)
        }
      } else {
        setValue(name, valueList.filter((v: any) => v !== value) as any)
      }
    }} />
  }

  function Radio<
    K extends keyof T,
    V extends T[K]
  >({name, value}: {
    name: K,
    value: V
  }): ReactElement | null {
    const [values, setValue] = useContext(FormContext) || []
    if(!values || !setValue) return null
    const currentValue = values[name]
    
    return <input type="radio" checked={currentValue === value} onChange={e => {
      if(e.currentTarget.checked){
        setValue(name, value)
      }
    }} />
  }



  function Select<K extends keyof T>({children, options, name, nullable = false}: {
    options: Map<T[K], string>,
    name: K,
    children?: ReactNode,
    nullable?: boolean
  }): ReactElement | null {
    const [values, setValue] = useContext(FormContext) || []
    if(!values || !setValue) return null

    return <select value={Array.from(options.keys()).indexOf(values[name])} onChange={e => {
      setValue(name, Array.from(options.keys())[Number(e.currentTarget.value)])
    }}>
      {
        Array.from(options.entries()).map(([value, label], i) => {
        return <option key={i} value={i}>{label}</option>
        })
      }
    </select>
  }

  type DateInputProps = {
    name: keyof T
  }
  function DateInput<K extends FilteredKeys<T, string | undefined>>({children, name}: {
    name: K,
    children?: ReactNode
  }): ReactElement | null {
    const [values, setValue] = useContext(FormContext) || []
    if((!values || !setValue)) return null
    return <>
      <ReactDatePicker
        selected={isNotNullish(values[name]) ? new Date(values[name] as any) : null}
        onChange={newDate => {
          if(newDate instanceof Date){
            setValue(name, newDate.toISOString() as any)
          }
        }}
      />
    </>
  }
  function DropdownDateInput<K extends FilteredKeys<T, string | undefined>>({name}: {
    name: K,
    children?: ReactNode
  }): ReactElement | null {
    const [values, setValue] = useContext(FormContext) || []

    const current = new Date()
    const currentYear = current.getFullYear()
    const currentMonth = current.getMonth()
    const currentDate = current.getDate()

    const [year, setYear] = useState<number | null>(null)
    const [month, setMonth] = useState<number | null>(null)
    const [date, setDate] = useState<number | null>(null)

    useEffect(() => {
      if(isNotNullish(year) && isNotNullish(month) && isNotNullish(date)){
        const d = new Date(year, month, date)
        if(Number.isNaN(d.getTime())) return
        if(!setValue) return
        setValue(name, d.toISOString() as any)
      }
    }, [year, month, date])

    if((!values || !setValue)) return null
    return <>
      <select value={year ?? ''} onChange={e => {
        const value = e.currentTarget.value.length < 1 ? null : Number(e.currentTarget.value)
        setYear(value)
      }}>
        <option value=""></option>
        {Array.from(Array(100), (v, i) => {
          const value = currentYear - i
          return <option key={value} value={value}>{value}</option>
        })}
      </select>
      <select value={month ?? ''} onChange={e => {
        const value = e.currentTarget.value.length < 1 ? null : Number(e.currentTarget.value)
        setMonth(value)
      }}>
        <option value=""></option>
        {Array.from(Array(12), (v, i) => {
          return <option key={i} value={i}>{i + 1}</option>
        })}
      </select>
      <select value={date ?? ''} onChange={e => {
        const value = e.currentTarget.value.length < 1 ? null : Number(e.currentTarget.value)
        setDate(value)
      }}>
        <option value=""></option>
        {Array.from(Array(31), (v, i) => {
          return <option key={i + 1} value={i + 1}>{i + 1}</option>
        })}
      </select>
    </>
  }

  const MapOuter = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  `

  const AutoCompleteInputOuter = styled.div`
    margin-top: 1rem;
  `

  const AutoCompleteInput = styled.input`
    box-sizing: border-box;
    padding: 0.5rem;
    width: 100%;

  `

  function LocationInput<
    KAddress extends FilteredKeys<T, string | undefined>,
    KLat extends FilteredKeys<T, number | undefined>,
    KLng extends FilteredKeys<T, number | undefined>,
    KAreaCode extends FilteredKeys<T, string | undefined>
  >({addressName, latName, lngName, areaCodeName, style}: {
    addressName: KAddress,
    latName: KLat,
    lngName: KLng,
    areaCodeName: KAreaCode,
    children?: ReactNode,
    style?: CSSProperties
  }): ReactElement | null {
    const [values, setValue] = useContext(FormContext) || []
    const [markerPosition, setMarkerPosition] = useState<google.maps.LatLng | google.maps.LatLngLiteral | null>({ lat: 35.6812362, lng: 139.7671248 })
    const client = useApolloClient()
    const {position, loaded, error} = useUserPosition()
    const [center, setCenter] = useLatLng(position ?? { lat: 35.6812362, lng: 139.7671248 })

    const [autoComplete, setAutoComplete] = useState<google.maps.places.Autocomplete | null>(null)
    const [map, setMap] = useState<google.maps.Map>()

    if(!values || !setValue) return null

    const setAddress = (value: string) => {
      setValue(addressName, value as any)
    }
    const setAreaCode = (value: string | null) => {
      setValue(areaCodeName, value as any)
    }

    const getAreaCode = async ({prefecture, city}: {prefecture: string, city: string}) => {
      const areaQueryResult = await client.query<GQLAreaByNameQuery, GQLAreaByNameQueryVariables>({
        query: gql `${AREA_BY_NAME_QUERY}${AREA_FRAGMENT}`,
        variables: {
          prefecture,
          city
        }
      })
      const area = areaQueryResult.data.area
      return area?.code ?? null
    }

    const setGeocorder = async (location: {lat: number, lng: number}) => {
      const geocorder = new google.maps.Geocoder()
      setMarkerPosition(location)
      setValue(latName, location.lat as any)
      setValue(lngName, location.lng as any)
      geocorder.geocode({
        location: {
          lat: location.lat,
          lng: location.lng
        }
      }, async (res, status) => {
        if(res){
          const {prefecture, city, address} = formatGeocorderResults(res)
          if(isPrefectureName(prefecture) && !isNullish(city)){
            const areaCode = await getAreaCode({prefecture, city})
            setAreaCode(areaCode)
            setAddress(address)
            setMarkerPosition(location)
          }
        }
      })
    }

    return <MapOuter>
      <GoogleMap
          mapContainerStyle={style}
          zoom={15}
          center={center}
          onCenterChanged={() => {
            const center = map?.getCenter()
            if(center){
              setCenter({lat: center.lat(), lng: center.lng()})
            }
          }}
          onClick={async e => {
            if(e.latLng){
              setGeocorder({lat: e.latLng.lat(), lng: e.latLng.lng()})
            }
            
          }}
          onLoad={e => setMap(e)}
        >
          {
            markerPosition && 
            <Marker
              position={markerPosition}
              draggable={true}
            />
          }
        </GoogleMap>
          <Autocomplete
            onLoad={(autoComplete) => setAutoComplete(autoComplete)}
            restrictions={{country: 'JP'}}
            onPlaceChanged={() => {
              const location = autoComplete?.getPlace().geometry?.location
              if(location){
                setGeocorder({lat: location.lat(), lng: location.lng()})
                setCenter({lat: location.lat(), lng: location.lng()})
              }
              
            }}
          >
            <AutoCompleteInputOuter>
              <AutoCompleteInput
                type="text"
                placeholder="住所を検索"
              />
            </AutoCompleteInputOuter>
          </Autocomplete>
    </MapOuter>
  }

  return {
    useForm,
    Form,
    Text,
    Textarea,
    Checkbox,
    MultipleCheckbox,
    Select,
    DateInput,
    DropdownDateInput,
    NumberText,
    LocationInput,
    Password,
    Radio
  }
}

const formatDate = (date: Date) => {
  const y = date.getFullYear()
  const m = (date.getMonth() + 1).toString().padStart(2, '0')
  const d = date.getDate().toString().padStart(2, '0')
  return `${y}-${m}-${d}`
}