import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      ...(token ? {Authorization: token} : {})
    }
  }
})

export function createApolloClient(){
  return new ApolloClient({
    link: ApolloLink.from([
      authLink,
      onError(({graphQLErrors, networkError, operation, forward}) => {
        if(graphQLErrors){
          for(let err of graphQLErrors){
            switch(err.extensions?.code){
              case 'UNAUTHENTICATED':
                forward(operation)
            }
          }
        }
        if(networkError){
          console.log(networkError)
        }
      }),
      createUploadLink({
        uri: '/graphql',
        credentials: 'same-origin',
      }),
    ]),
    cache: new InMemoryCache(),
    resolvers: {
      Date: {
        parseValue: value => new Date(value),
        serialize: (value: Date) => value.toISOString()
      }
    }
  })
}