import React from 'react'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { GQLJob } from '../../generated/graphql'
import { Person } from '../../generated/svg'
import { formatDate } from '../../utils/format-date'
import { formatPrice } from '../../utils/format-price'
import { PartialRequired } from '../../utils/type-helper'

const Outer = styled.div`
  --outer-padding: 1.5rem;
  background-color: #669933;
  color: white;
  padding: var(--outer-padding);
  position: relative;
`
const Name = styled.div`
  font-weight: 700;
  margin-bottom: 0.75rem;
  line-height: 1.5;
  min-height: 3rem;
  margin-right: 4rem;
`
const ListItem = styled.div`
  padding: 0.75rem;
  border-top: 1px solid #c8c8c8;
  margin: 0 -0.75rem;
  font-size: 0.8rem;
  font-weight: 700;
`
const Deadline = styled(ListItem)``
const Fee = styled(ListItem)``

const FeeNote = styled.div`
  margin: 0.5rem 1rem;
  opacity: 0.5;
  font-size: 0.7rem;
`
const ApplicationCount = styled.div`
  position: absolute;
  margin: var(--outer-padding);
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  font-size: 0.8rem;
`
const ApplicationCountNumber = styled.div`
  font-size: 1.25rem;
  font-weight: 900;
  color: #005938;
  background-color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
`

const IconContainer = styled.div`
  width: 3rem;
  position: absolute;
  padding: var(--outer-padding);
  margin-top: -0.5rem;
  top: 0;
  right: 0;
  color: #437243;
`

type JobPageDetailHeadProps = {
  job: PartialRequired<GQLJob, 'name' | 'fee' | 'deadline' | 'applicationCount'>
}
export const JobPageDetailHead: FunctionComponent<JobPageDetailHeadProps> = ({job}) => {
  return <>
    <Outer>
      <IconContainer>
        <Person />
      </IconContainer>
      <Name>{job.name}</Name>
      <Deadline>期限:  {formatDate(job.deadline)}</Deadline>
      <Fee>
        報酬金額: {formatPrice(job.fee)}
      </Fee>
      <ApplicationCount>
        現在の応募件数：
        <ApplicationCountNumber>
          {job.applicationCount}
        </ApplicationCountNumber>
      </ApplicationCount>
    </Outer>
    <FeeNote>
      取引手数料25%、お振込み時に一律250円の振り込み手数料が差し引かれます
    </FeeNote>
  </>
}