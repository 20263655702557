import React from 'react'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useStudioQuery } from '../../generated/graphql'
import { Studio } from '../molecules/studio'

const Outer = styled.div`

`

type StudioPageProps = {
  studioName: string;
}
export const StudioPage: FunctionComponent<StudioPageProps> = ({studioName}) => {
  const studioQuery = useStudioQuery({variables: {
    input: {
      studioName
    }
  }})
  if(!studioQuery.data?.studio) return null
  return <Outer>
    <Studio studio={studioQuery.data.studio} />
  </Outer>
}