import React from 'react'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { GQLUser } from '../../generated/graphql'
import { formatDate, formatDateTime } from '../../utils/format-date'
import { PartialRequired } from '../../utils/type-helper'
import { ProfileImage } from '../atoms/profile-image'

const Outer = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  border: 1px solid #666666;
  border-radius: 0.5rem;
  & + & {
    margin-top: 0.5rem;
  }
`

const UserInfoContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 4rem;
  max-width: 4rem;
`

const RightConatiner = styled.div`
  flex: 1 1 0;
  position: relative;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ThumbnailContainer = styled.div`

`

const UserName = styled.div`
  font-size: 0.75rem;
  color: var(--link-color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
`

const Text = styled.div`
  font-size: 0.9rem;
  white-space: pre-wrap;
`

const CommentDate = styled.div`
  font-size: 0.75rem;
  color: #959595;
  text-align: right;
  margin-top: auto;
  padding-top: 0.25rem;
`


export interface CommentData {
  id: string | number,
  user: PartialRequired<GQLUser, 'name' | 'screenName'>,
  text: string,
  createdAt: string
}

type CommentCardProps = {
  comment: CommentData
}
export const CommentCard: FunctionComponent<CommentCardProps> = ({comment}) => {
  return <Outer>
    <UserInfoContainer to={`/photographers/${comment.user.name}`} >
      <ThumbnailContainer>
        <ProfileImage profileImage={comment.user.profileImage} />
      </ThumbnailContainer>
      <UserName>{comment.user.screenName}</UserName>
    </UserInfoContainer>
    <RightConatiner>
      <Text>{comment.text}</Text>
      <CommentDate>{formatDateTime(comment.createdAt)}</CommentDate>
    </RightConatiner>
  </Outer>
}