import { FunctionComponent } from 'react';
import { GQLNewsPost } from '../../generated/graphql';
import { PartialRequired } from '../../utils/type-helper';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import React from 'react';
import { format } from 'date-fns';

export type NewsObject = PartialRequired<GQLNewsPost, 'id' | 'title' | 'createdAt'>

const Outer = styled(Link)`
  display: flex;
  align-items: flex-end;
  padding: 0.5rem 0;
`

const PostDate = styled.div`
  color: #666666;
  font-weight: 700;
  font-size: 0.8rem;
  margin-right: 1rem;
`

const Title = styled.div` 
  color: var(--link-color);
  text-decoration: underline;
`


export type NewsPostListItemProps = {
  newsPost: NewsObject
}

export const NewsPostListItem: FunctionComponent<NewsPostListItemProps> = ({newsPost}) => {
  return <Outer to={`/news/${newsPost.id}`}>
    <PostDate>{format(new Date(newsPost.createdAt), 'yyyy.MM.dd')}</PostDate>
    <Title>{newsPost.title}</Title>
  </Outer>
} 