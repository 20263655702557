export const formatDate = (input: Date | string): string => {
  const d = normalizeDate(input)
  if(isInvalidDate(d)) return '-'
  const year = d.getFullYear()
  const month = d.getMonth() + 1
  const date = d.getDate()
  return `${year}年${month}月${date}日`
}

export const formatTime = (input: Date | string): string => {
  const d = normalizeDate(input)
  if(isInvalidDate(d)) return '-'
  const hours = d.getHours().toString().padStart(2, '0')
  const minutes = d.getMinutes().toString().padStart(2, '0')
  return `${hours}:${minutes}`
}

export const formatDateTime = (input: Date | string): string => {
  const d = normalizeDate(input)
  if(isInvalidDate(d)) return '-'
  return `${formatDate(d)} ${formatTime(d)}`
}

export const isInvalidDate = (d: Date | string): boolean => {
  const date = normalizeDate(d)
  return Number.isNaN(date.getTime())
}

export const normalizeDate = (d: Date | string): Date => {
  return typeof d === 'string' ? new Date(d) : d
}