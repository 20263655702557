import { isEmptyArray, isNullish } from './type-check'
import { ParsedUrlQueryInput } from 'querystring'

export function normarizeArrayParam(param: string | string[]): string[]{
  if(Array.isArray(param)){
    return param
  } else {
    return param ? [param] : []
  }
}

export function normarizeStringParam(param?: string | string[]): string {
  if(isNullish(param)){
    return ''
  }
  if(Array.isArray(param)){
    return param[0] ?? ''
  } else {
    return param
  }
}

export function normarizeIntParam(param?: string | string[]): number | undefined {
  if(isNullish(param)) return
  const value = Number(normarizeStringParam(param))
  if(!Number.isNaN(value) && Number.isInteger(value) || value === Infinity){
    return value
  }
}

export function normarizeQueryStringObject(obj: ParsedUrlQueryInput): ParsedUrlQueryInput{
  return Object.fromEntries(Object.entries(obj).filter(([key, value]) => !isEmptyArray(value) && !isNullish(value)))
}