import { useState, useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { LatLng } from '../components/organisms/near-job-map';

type UseUserPositionOptions = {
  force?: boolean
}
const defaultOptions: UseUserPositionOptions = {
  force: false
}
export const useUserPosition = (options: UseUserPositionOptions = defaultOptions): {
  position?: LatLng,
  loaded: boolean,
  rejected: boolean,
  error?: GeolocationPositionError
} => {
  const [position, setPosition] = useState<GeolocationPosition>()
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState<GeolocationPositionError>()
  const [rejected, setRejected] = useLocalStorage('rejecteGetCurrentPosition', false)
  useEffect(() => {
    if(!rejected){
      navigator.geolocation.getCurrentPosition(position => {
        setPosition(position)
        setLoaded(true)
      }, err => {
        setError(err)
        setLoaded(true)
        setRejected(true)
      });
    } else {
      setLoaded(true)
    }
  }, []);
  return {
    position: position ? {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    } : undefined,
    rejected: rejected ?? false,
    loaded,
    error
  };
};
