import styled from 'styled-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { parse } from 'querystring';
import { useLocation, useHistory } from 'react-router-dom';
import { useVerifyEmailMutation } from '../../generated/graphql';
import { normarizeStringParam } from '../../utils/normarize';
import { useErrorModal } from '../../hooks/use-error-modal';
import { SigninOuter, SigninText, SignupLink } from '../organisms/signin';
import { H1 } from '../atoms/h1';

const Outer = styled.div``

export const VerifyEmailPage: FunctionComponent = () => {
  const location = useLocation()
  const history = useHistory()
  const qs = parse(location.search.replace(/^\?/, ''))
  const token = normarizeStringParam(qs.token)
  const [verifyEmail] = useVerifyEmailMutation()
  const [completed, setCompleted] = useState(false)
  const {open} = useErrorModal()

  
  useEffect(() => {
    (async () => {
      if(!token) return
      const res = await verifyEmail({variables: {
        token
      }})
      if(res.errors){
        open(res.errors)
      }
      if(res.data?.verifyEmail){
        setCompleted(true)
      }
    })()
  }, [])

  if(!token) return <>Invalid token</>

  return <Outer>
    {
      completed &&
      <SigninOuter>
        <H1>メールアドレスの認証が完了しました</H1>
        <SigninText>
          <SignupLink to="/signin">こちら</SignupLink>からログインしてください
        </SigninText>
      </SigninOuter>
    }
  </Outer>
}
