import React from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useNewsPostQuery } from '../../generated/graphql';
import { NewsPostListItem } from '../atoms/news-post-list-item';
import { NewsPost } from '../molecules/news-post';

const Outer = styled.div`

`

type NewsPageProps = {
  newsPostId: string
}

export const NewsPostPage: FunctionComponent<NewsPageProps> = ({newsPostId}) => {
  const newsPostQuery = useNewsPostQuery({
    variables: {
      newsPostId: newsPostId
    }
  })
  if(!newsPostQuery.data) return null
  return <Outer>
    <NewsPost newsPost={newsPostQuery.data.newsPost} />
  </Outer>
}