import React, { useState } from 'react'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useAddJobMessageMutation, useJobMessagesQuery } from '../../generated/graphql'
import { useErrorModal } from '../../hooks/use-error-modal'
import { CommentInputBox } from './comment-input-box'
import { CommentList } from './comment-list'

const Outer = styled.div`

`

const InputContainer = styled.div`
  margin-bottom: 0.5rem;
`

const ListContainer = styled.div`
  max-height: 20rem;
  overflow-y: scroll;
`

type JobMessagesProps = {

  jobId: string
}

export const JobMessages: FunctionComponent<JobMessagesProps> = ({jobId}) => {
  const {data, fetchMore, refetch} = useJobMessagesQuery({
    variables: {
      jobId,
    }
  })
  const [addJobMessageMutation] = useAddJobMessageMutation()
  const [value, setValue] = useState('')
  const {open: openErrorModal} = useErrorModal()
  return <Outer>
    <InputContainer>
      <CommentInputBox value={value} onChange={value => setValue(value)} onSend={async value => {
        try {
          await addJobMessageMutation({
            variables: {
              jobMessageInput: {
                jobId,
                text: value
              }
            }
          })
          await refetch()
          setValue('')
        } catch(err) {
          openErrorModal(err)
        }
      }} />
    </InputContainer>
    <ListContainer>
      <CommentList comments={data?.jobMessages.items ?? []} />
    </ListContainer>
  </Outer>
}