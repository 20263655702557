import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { useJobQuery, useAddJobCommentMutation, useJobApplicationsQuery, useAcceptJobApplicationMutation, useProfileQuery, useJobMessagesQuery, useAddJobMessageMutation } from '../../generated/graphql';
import { ErrorBlock } from '../molecules/error-block';
import { Job } from '../molecules/job';
/// <reference path="<relevant path>/node_modules/@types/googlemaps/index.d.ts" />

const JobPageOuter = styled.div``


export const JobPage: FunctionComponent<{jobId: string}> = ({jobId}) => {
  const profileQuery = useProfileQuery()
  const jobQuery = useJobQuery({variables: {jobId}})
  const [addJobComment] = useAddJobCommentMutation({
    errorPolicy: 'all'
  })
  const [addJobMessage] = useAddJobMessageMutation({
    errorPolicy: 'all'
  })
  const job = jobQuery.data?.job
  const jobApplicationsQuery = useJobApplicationsQuery({skip: jobQuery.loading || !job?.own, variables: {
    jobId
  }})
  const [comment, setComment] = useState('')
  const location = {lat: job?.lat ?? 0, lng: job?.lng ?? 0}

  const [acceptJobApplication] = useAcceptJobApplicationMutation()
  const jobMessagesQuery = useJobMessagesQuery({
    skip: jobQuery.loading || !(jobQuery.data?.job?.own || jobQuery.data?.job?.assigned),
    variables: {
      jobId: job?.id!
    }
  })
  const [message, setMessage] = useState('')

  if(jobQuery.loading) return <>Loading</>
  if(!job){
    return <ErrorBlock name="404" message="Not found" />
  }

  return <JobPageOuter>
    <Job job={job} />
  </JobPageOuter>
}