import React from 'react'
import styled from 'styled-components'
import { InquiryForm } from '../organisms/contact-form'


const Title = styled.div`
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 2rem;
  text-align: center;
`
const Outer = styled.div`
  margin: 0 auto;
  margin-top: 2rem;
  max-width: 40rem;
  --accent-color: #088;
`
export const ContactPage = () => {
  return <Outer>
    <Title>お問い合わせ</Title>
    <InquiryForm />
  </Outer>
}