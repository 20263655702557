import * as React from "react";

function SvgDownArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31.801 30.92"
      {...props}
    >
      <path
        fill="currentcolor"
        d="M22.401 15.935V0h-13v15.935H0L15.901 30.92l15.9-14.985h-9.4z"
      />
    </svg>
  );
}

export default SvgDownArrow;
