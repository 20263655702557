import * as React from "react";

function SvgPaginatorLeftArray(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.727 18.145"
      {...props}
    >
      <path
        fill="currentcolor"
        d="M10.727 0H6.162L0 9.073l6.162 9.072h4.565L4.565 9.073 10.727 0z"
      />
    </svg>
  );
}

export default SvgPaginatorLeftArray;
