import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  & + & {
    margin-top: 3rem;
    padding-top: 3rem;
    border-top: 1px solid #c8c8c8;
  }
`;
