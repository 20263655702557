import React from 'react'
import { ReactNode } from 'react'
import { MessageModal } from '../components/molecules/message-modal'
import { useModal } from './use-modal'

export const useMessageModal = () => {
  const {open} = useModal()
  return {
    open: async (messages: ReactNode | ReactNode[]) => {
      return open(({resolve}) => <MessageModal messages={Array.isArray(messages) ? messages : [messages]} onClose={() => resolve(undefined)} />)
    }
  }
}
