import * as React from "react";

function SvgGear(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      {...props}
    >
      <path
        d="M11 16.829A5.83 5.83 0 1116.83 11 5.836 5.836 0 0111 16.829zm0-10.938A5.109 5.109 0 1016.109 11 5.115 5.115 0 0011 5.891z"
        fill="currentcolor"
      />
      <path
        d="M11.628 22h-1.256a1.641 1.641 0 01-1.64-1.64v-1.076a8.53 8.53 0 01-1.987-.823l-.76.761a1.64 1.64 0 01-2.319 0l-.888-.888a1.639 1.639 0 010-2.318l.76-.761a8.53 8.53 0 01-.822-1.987H1.639A1.641 1.641 0 010 11.628v-1.256a1.641 1.641 0 011.64-1.64h1.076a8.534 8.534 0 01.823-1.987l-.762-.76a1.641 1.641 0 010-2.319l.89-.888a1.64 1.64 0 012.317 0l.761.76a8.522 8.522 0 011.987-.822V1.64A1.641 1.641 0 0110.373 0h1.256a1.641 1.641 0 011.64 1.64v1.076a8.525 8.525 0 011.987.823l.76-.761a1.641 1.641 0 012.319 0l.889.888a1.641 1.641 0 010 2.318l-.762.761a8.526 8.526 0 01.823 1.987h1.077A1.641 1.641 0 0122 10.372v1.256a1.641 1.641 0 01-1.64 1.64h-1.076a8.53 8.53 0 01-.823 1.987l.761.76a1.641 1.641 0 010 2.319l-.888.889a1.64 1.64 0 01-2.318 0l-.761-.761a8.527 8.527 0 01-1.987.822v1.077A1.641 1.641 0 0111.628 22zm-4.94-4.352a.357.357 0 01.189.054 7.805 7.805 0 002.299.952.36.36 0 01.277.35v1.356a.92.92 0 00.919.92h1.256a.92.92 0 00.92-.92v-1.356a.36.36 0 01.276-.35 7.805 7.805 0 002.3-.952.36.36 0 01.464.074l.937.938a.92.92 0 001.3 0l.889-.89a.92.92 0 000-1.3l-.938-.937a.36.36 0 01-.074-.464 7.812 7.812 0 00.952-2.299.36.36 0 01.35-.277h1.357a.92.92 0 00.919-.919v-1.256a.92.92 0 00-.919-.92h-1.357a.36.36 0 01-.35-.276 7.81 7.81 0 00-.951-2.298.36.36 0 01.052-.444l.959-.959a.92.92 0 000-1.3l-.889-.888a.92.92 0 00-1.3 0l-.959.959a.361.361 0 01-.443.052 7.809 7.809 0 00-2.299-.952.36.36 0 01-.277-.35V1.639a.92.92 0 00-.919-.919h-1.256a.92.92 0 00-.92.919v1.357a.36.36 0 01-.276.35 7.813 7.813 0 00-2.299.952.361.361 0 01-.443-.053l-.959-.958a.92.92 0 00-1.3 0l-.889.888a.92.92 0 000 1.3l.96.959a.36.36 0 01.051.444 7.811 7.811 0 00-.951 2.298.36.36 0 01-.35.277H1.639a.92.92 0 00-.919.919v1.257a.92.92 0 00.92.919h1.356a.36.36 0 01.35.276 7.812 7.812 0 00.952 2.3l.015.028a.36.36 0 01-.088.435l-.939.938a.919.919 0 000 1.3l.89.889a.92.92 0 001.299 0l.943-.943a.36.36 0 01.27-.123z"
        fill="currentcolor"
      />
    </svg>
  );
}

export default SvgGear;
