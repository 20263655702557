import { FunctionComponent } from 'react';
import { SmallMagnifier } from '../../generated/svg';
import React from 'react';
import styled from 'styled-components';

const Outer = styled.div`
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.25);
  background-color: var(--base-color);
  border: 1px solid #afafaf;
  border-radius: 0.25rem;
`

const Label = styled.div`
  font-size: 1rem;
  display: flex;
  color: var(--accent-color, #888);
  font-weight: 700;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-bottom: 1px solid #c8c8c8;
  margin: 0.5rem;
`

const LabelIconOuter = styled.div`
  width: 1rem;
  margin-right: 0.25rem;
  color: var(--accent-color, #888);
`

const ConditionContainer = styled.div`

`

const SearchButton = styled.div`
`

const SearchButtonIconOuter = styled.div`
`

type SearchConditionProps = {
  // onSearch: () => void
}

export const SearchCondition: FunctionComponent<SearchConditionProps> = ({children}) => {
  return <Outer>
    <Label>
      <LabelIconOuter>
        <SmallMagnifier />
      </LabelIconOuter>
      検索条件
    </Label>
    <ConditionContainer>
      {children}
    </ConditionContainer>
    {/* <SearchButton>
      <SearchButtonIconOuter>
        <WhiteMagnifier />
      </SearchButtonIconOuter>
      絞り込む
    </SearchButton> */}
  </Outer>
}

