import React, { ReactNode } from 'react';
import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useUserStatus } from '../../hooks/use-user-status';
import { isNullish } from '../../utils/type-check';
import { ProfileCard } from '../molecules/profile-card';

const Outer = styled.div`

`
const HeaderOuter = styled.div`
  background-color: var(--accent-color);
`
const Header = styled.div`
  color: #fff;
  padding: 3rem 2rem;
  font-family: var(--head-font-family);
  font-weight: var(--head-font-weight);
  font-size: 1.75rem;
  margin: 0 auto;
  max-width: 80rem;
  @media (max-width: 40rem){
    padding-bottom: 4rem;
  }
`
const ContentContainer = styled.div`
  display: flex;
  justify-content: start;
  max-width: 80rem;
  margin: 0 auto;
  @media (max-width: 40rem) {
    flex-direction: column;
  }
`
const ContentOuter = styled.div`
  background-color: #e2e2e2;
`

const LeftContainer = styled.div`
  flex: 0 0 15rem;
  padding: 1rem;
  @media (max-width: 40rem){
    order: 1;
  }
`

const RightContainer = styled.div`
  flex: 1 1 0;
  background-color: white;
  @media (max-width: 40rem){
    order: 0;
  }
`

type AccountPageProps = {
  headerText: ReactNode,
}
export const AccountPage: FunctionComponent<AccountPageProps> = ({children, headerText}) => {
  const userStatus = useUserStatus()
  const user = userStatus.profile

  if(isNullish(user)){
    return <>403</>
  }
  return <Outer>
    <HeaderOuter>
      <Header>
        {headerText}
      </Header>
    </HeaderOuter>
    <ContentOuter>
      <ContentContainer>
        <LeftContainer>
          <ProfileCard user={user} />
        </LeftContainer>
        <RightContainer>
          {children}
        </RightContainer>
      </ContentContainer>
    </ContentOuter>
  </Outer>
}