import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { H1 } from '../atoms/h1';
import { TextInputBlock } from '../molecules/text-Input-block';
import { useSigninMutation } from '../../generated/graphql';
import { Button } from '../atoms/button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import { useUserStatus } from '../../hooks/use-user-status';
import { useErrorModal } from '../../hooks/use-error-modal';

export const SigninOuter = styled.div`
  padding: 2rem;
  max-width: 30rem;
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  margin: 4rem auto;
  --accent-color: #005938;
`

export const LoginButtonOuter = styled.div`
  margin-top: 2rem;
`

const SignupText = styled.div`
  text-align: center;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #ccc;
`
export const SignupLink = styled(Link)`
  text-decoration: underline;
  color: var(--accent-color);
  margin: 0 0.25rem;
`

export const SigninText = styled.div`
  text-align: center;
  font-size: 0.8em;
  margin-top: 1rem;
  line-height: 1.5;
`




type SigninProps = {
  onComplete?: () => void
}

export const Signin: FunctionComponent<SigninProps> = ({onComplete}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [signinMutation] = useSigninMutation()
  const client = useApolloClient()
  const [errors, setErrors] = useState<Error[]>([])
  const userStatus = useUserStatus()
  const {open: openErrorModal} = useErrorModal()

  if(userStatus.signedIn){
    if(typeof onComplete === 'function') onComplete()
    return null
  }

  return <SigninOuter>
    <H1>ログイン</H1>
    <TextInputBlock type="text" value={email} onChange={e => setEmail(e.currentTarget.value)}>Email</TextInputBlock>
    <TextInputBlock type="password" value={password} onChange={e => setPassword(e.currentTarget.value)}>Password</TextInputBlock>
    <SigninText>
      <SignupLink to="/password-reset">パスワードをお忘れの方は</SignupLink>
    </SigninText>
    <LoginButtonOuter>
      <Button onClick={async () => {
        try{
          const signinResult = await signinMutation({
            variables: {
              email,
              password
            }
          })
          localStorage.setItem('token', signinResult.data?.signin.token!)
          console.log(signinResult)
          client.clearStore().then(() => {
            console.log('clear ok')
            client.reFetchObservableQueries().finally(() => {
              console.log('refetch ok')
              console.log('onComplete', onComplete)
              if(typeof onComplete === 'function') onComplete()
            })
          })
        } catch(err) {
          await openErrorModal(err)
        }
      }}>
        ログイン
      </Button>
    </LoginButtonOuter>
    <SignupText>
      新規の方は<SignupLink to="/signup">新規登録</SignupLink>へ
    </SignupText>
  </SigninOuter>
}